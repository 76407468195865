export default function apiErrorParser(error){
    console.log(error)
    if(error && error.response && error.response.data && error.response.data.error)
        return error.response.data.error

    if(!error.response){
        return "Erreur interne"
    }

    if(error.response.status === 500)
        return "Erreur API interne"

    if(error.response.status === 401)
        return "Non autorisé à faire cette action"

    return "Erreur"
}
