<template>
  <nav class="navbar navbar-expand-lg pe-2 shadow">
    <a
      v-if="showSideBar"
      id="test"
      class="sidebar-toggler text-gray-500 lead text-center minimized-side-width"
      href="#"
      @click="switchSideBar"
    >
      <font-awesome-icon
        v-if="!sidebar.isExtended"
        icon="fa-solid fa-align-left"
      />

      <font-awesome-icon
        v-else
        icon="fa-solid fa-xmark"
      />
    </a>

    <router-link
      class="navbar-brand ms-2 hoverable"
      :to="{name: 'home'}"
    >
      <img
        :src="navbarLogo"
        alt="Icon de la navbar de AMSOM&Moi"
        style="max-height: calc(var(--top-nav-height) * 0.8)"
      >
    </router-link>

    <router-link
      v-if="currentRoute !== 'login'"
      class="navbar-brand fw-bold text-uppercase text-base"
      :to="{name: 'home'}"
    >
      <span class="d-none d-brand-partial">Amsom&Moi</span>
    </router-link>

    <ul
      v-if="currentRoute !== 'login'"
      class="ms-auto d-flex align-items-center list-unstyled mb-0"
    >
      <li class="nav-item">
        <router-link
          v-if="!isLogged"
          :to="{name: 'login'}"
          class="nav-link active"
        >
          Se connecter
        </router-link>
      </li>

      <li class="nav-item dropdown ms-auto">
        <a
          v-if="isLogged"
          id="userInfo"
          class="hoverable dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="badge bg-primary">
            <font-awesome-icon
              icon="fa-solid fa-user"
              class="fa fa-2xl"
            />
          </div>
        </a>

        <div
          class="dropdown-menu dropdown-menu-end dropdown-menu-animated pb-0 m-0"
          aria-labelledby="userInfo"
        >
          <div class="position-relative mb-2">
            <div class="dropdown-header text-primary">
              <h6 class="text-uppercase font-weight-bold">
                Utilisateur
              </h6>

              <small>{{ username }}</small>

              <br>

              <small v-if="currentContrat">Contrat L<span class="user-select-all">{{
                currentContrat
              }}</span></small>

              <br>
            </div>

            <div v-if="currentContrat && contrats.length>1">
              <div class="dropdown-divider " />

              <div class="dropdown-header d-flex align-items-center text-primary">
                <div class="pt-1">
                  <h6>Changer de contrat</h6>

                  <select
                    v-model="currentContrat"
                  >
                    <option
                      v-for="option in contratsOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="position-absolute bottom-0 end-0 text-end">
              <span class="text-xs px-1 mx-2 rounded">V.{{
                appVersion
              }}</span>
            </div>

            <template v-if="isLogged">
              <div class="dropdown-divider" />

              <router-link
                :to="{name: 'resetPassword'}"
                class="dropdown-item"
              >
                Changer mon mot de passe
              </router-link>
            </template>

            <div class="dropdown-divider" />

            <span
              class="dropdown-item hoverable"
              @click="logout"
            >Déconnexion</span>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import packageInfo from '../../package.json'
import {mapGetters, mapState} from "vuex";
import navbarLogo from "@/assets/logo.svg"
export default {
  name: 'NavbarBlock',

  props: {
    showSideBar: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      appName: packageInfo.name,
      appVersion: packageInfo.version,
      navbarLogo: navbarLogo
    }
  },

  computed: {
    ...mapState(["sidebar"]),

    ...mapGetters({
      isLogged: 'user/isLogged',
      idContrat: 'user/getSelectedContrat',
      contrats: 'user/getContrats',
      username: 'user/getUsername'
    }),

    currentRoute() {
      return this.$route.name
    },

    currentContrat: {
      get() {
        return this.idContrat;
      },

      set(contrat) {
        this.$store.commit('user/setSelectedContrat', contrat);
      }
    },

    contratsOptions() {
      return this.contrats.map(c => {
        return {value: c, text: 'L' + c};
      })
    }
  },

  methods: {
    logout() {
      this.$store.dispatch('user/logout').then(() => {
        this.$router.push({name: 'login'})
      })
    },

    switchSideBar() {
      this.$store.commit("sidebar/setIsExtended", !this.sidebar.isExtended)
    },
  }}
</script>
