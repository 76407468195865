<template>
  <div class="stepper d-flex flex-column ml-2">
    <div
      v-for="(step, idx) in steps"
      :key="step.id"
      class="d-flex mb-1"
    >
      <div class="d-flex flex-column align-items-center">
        <div
          style="width: 3.25rem;min-height: 3.25rem; height: 3.25rem; line-height: 3.25rem; font-size: 1.25rem;"
          class="d-inline-block rounded-circle text-white text-center mb-1"
          :class="'bg-'+(step.iconColor??'primary')"
        >
          <font-awesome-icon
            :icon="step.icon??['fa-solid', 'fa-user']"
          />
        </div>

        <div
          v-if="idx<steps.length-1"
          class="line h-100"
        />
      </div>

      <div class="ms-3">
        <h5
          class="m-0"
          :class="'text-' + step.titleColor ?? 'muted'"
        >
          {{ step.title }}
        </h5>

        <small :class="'text-'+step.subtitleColor??'muted'">{{ step.subtitle }}</small>

        <p
          class="pb-3"
          :class="'text-'+step.descriptionColor ??'secondary'"
        >
          {{ step.description }}
          <a
            v-if="step.actionBtn"
            role="link"
            class=" d-block text-danger hoverable"
            @click="clickAction(step.id)"
          >
            <span class="text-decoration-underline">{{ step.actionBtn.text }}</span>

            <font-awesome-icon
              v-if="step.actionBtn.icon"
              :icon="step.actionBtn.icon"
              class="me-1"
            />
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


export default {
  name: 'AmsomVerticalStepper',

  props:{
    /**
         * @type {Array}
         * @example [
                {
                    title:'Titre 1',
                    titleColor:'primary',
                    subtitle:'Sous-titre',
                    subtitleColor:'muted',
                    description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, voluptatum.',
                    descriptionColor:'secondary',
                    icon:'fa-user',
                    iconColor:'primary',
                    actionBtn: 
                    {
                        icon: ['far', 'calendar-xmark'],
                        color: 'danger',
                        text: 'Exemple de bouton',
                    }
                },...]
         */
    steps:{
      type:Array,

      default:()=>[
        {
          id:1,
          title:'Titre 1',
          titleColor:'primary',
          subtitle:'Sous-titre',
          subtitleColor:'muted',
          description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, voluptatum.',
          descriptionColor:'secondary',
          icon: ['fa-solid', 'fa-user'],
          iconColor:'primary',
        },
        {
          id:2,
          title:'Titre 2',
          titleColor:'primary',
          subtitle:'Sous-titre 2',
          subtitleColor:'amsom-green',
          description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, voluptatum.',
          descriptionColor:'danger',
          icon: ['fa-solid', 'fa-user-plus'],
          iconColor:'secondary',
        },
        {
          id:3,
          title:'Titre 3',
          titleColor:'amsom-green',
          subtitle:'Sous-titre 3',
          subtitleColor:'muted',
          description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, voluptatum.',
          descriptionColor:'secondary',
          icon: ['fa-solid', 'fa-check'],
          iconColor:'amsom-green',
          actionBtn: 
            {
              icon: ['far', 'calendar-xmark'],
              color: 'danger',
              text: 'Exemple de bouton',
            }
        },
      ]
    }
  },

  emits: ['clickOnItem'],

  methods: {
    clickAction(id){
      this.$emit('clickOnItem', id);
    }
  }
}
</script>

<style scoped>
.stepper {
  .line {
    width: 0.1rem;
    background-color: var(--bs-gray-400) !important;
  }
}

</style>