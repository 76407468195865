import apiErrorParser from "@/js/apiErrorParser"
import axiosOverlay from "@/services/axiosOverlay"
import { sortArray } from '@/js/array.js'

const getDefaultState = () => {
    return {
        loading: 0,
        listNotification: null,
        pageVisited: false
    }
}

const state = getDefaultState()

const getters = {}
const mutations = {
    setLoading: (state, val) => {
        if (val === '+')
            state.loading++

        if (val === '-')
            state.loading--
    },
    setListNotification: (state, listNotification) => {
        state.listNotification = sortArray(listNotification, 'dateMessage')
    },
    setPageVisited: (state, pageVisited) => {
        state.pageVisited = pageVisited
    },
    resetState: (state) => {
        Object.assign(state, getDefaultState())
    }
}
const actions = {
    loadListNotification: ({ state, commit }, payload) => {
        // On charge la liste des notifications en fonction du contrat contratId
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/notifications?idContrat=' + payload
            const config = {
                url: url,
                method: 'GET'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setListNotification', response.data);

                    commit('setLoading', '-');
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    commit('setLoading', '-');
                    reject(apiErrorParser(error))
                })
        })
    },

    readNotification: ({ state, commit }, data) => {
        // Update des status lu/non lu des notifications
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/notification/read'
            const config = {
                url: url,
                data: data,
                method: 'PATCH'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setLoading', '-');
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    commit('setLoading', '-');
                    reject(apiErrorParser(error))
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
