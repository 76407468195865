<template>
  <template v-if="maintenanceMode">
    <maintenance-block :maintenance-mode="maintenanceMode" />
  </template>

  <template v-else>
    <invit-to-app-block v-if="$isPhoneFormat && !$isAppCordova" />
    
    <sidebar
      v-if="showSideBar"
      :menu-items="menuItems"
      class="side-block position-fixed top-0 start-0 shadow"
    />

    <div
      id="body-block"
      :class="[
        {
          'element-width-according-side-bar': showSideBar,
          'element-according-foot-bar': showFootBar
        }
      ]"
      @click="hideSideBar()"
    >
      <top-bar
        v-if="showNavBar"
        :side-bar-showed="showSideBar"
      />

      <router-view
        id="content_page"
        v-slot="{ Component }"
        :class="[
          {
            // 'element-according-top-bar' : showNavBar,
          }
        ]"
      >
        <transition
          name="slide-fade"
          mode="out-in"
        >
          <component :is="Component" />
        </transition>
      </router-view>
    </div>

    <foot-bar
      v-if="showFootBar"
      :menu-items="menuItems"
      class="foot-bar-block"
    />
  </template>
</template>

<script>
import NavbarBlock from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import { mapGetters, mapState } from "vuex";
import FootBar from "@/components/FootBar.vue";
import TopBar from "@/components/TopBar.vue";
import pageUtils from "@/js/pageUtils.js";
import firebase from "@/js/firebase.js";
import packageInfo from "../package.json";
import versionning from "@/js/versionning.js";
import MaintenanceBlock from "./components/MaintenanceBlock.vue";
import InvitToAppBlock from "./components/InvitToAppBlock.vue";
import moment from "moment-timezone";

export default {
  name: "App",
  components: { TopBar, FootBar, NavbarBlock, Sidebar, MaintenanceBlock, InvitToAppBlock },

  data() {
    return {
      isNotUpToDate: false,
      maintenanceMode: null,
      appVersion: packageInfo.version,
      hideStart: null,
      menuItems: [
        {
          id: "accueil",
          icon: "fa-solid fa-house",
          label: "Accueil",
          name: ["home", "profil", "cgu", "notification"],
        },
        {
          id: "compte",
          icon: "fa-solid fa-coins",
          label: "Compte",
          name: ["compte", "prelevement", "paiement"],
        },
        {
          id: "contrat",
          icon: "fa-solid fa-pen-to-square",
          label: "Contrat",
          name: ["contrat"],
        },
        {
          id: "assurance",
          icon: "fa-solid fa-file-shield",
          label: "Assurance",
          name: ["assurance", "assurer"],
        },
        {
          id: "sollicitation",
          icon: "fa-solid fa-person-circle-question",
          label: "Sollicitations",
          name: ["sollicitation", "sollicitationDetail"],
        },
        {
          id: "contacts",
          icon: "fa-solid fa-address-book",
          label: "Contacts",
          name: ["contacts"],
        },
        {
          id: "enquetes",
          icon: "fa-solid fa-file-circle-question",
          label: "Enquêtes",
          name: ["enqueteHome", "enqueteEntry"],
        },
      ],

      options: [
        { value: "fr", text: "Français" },
        { value: "en", text: "Anglais" },
        { value: "ja", text: "Japonais" },
        { value: "ar", text: "Arabe" },
        { value: "la", text: "Latin" },
      ],
    };
  },

  computed: {
    ...mapState(["utilities"]),

    ...mapGetters({
      userLogged: "user/isLogged",
      selectedContratId: "user/getSelectedContrat",
      codeIndividu: "user/codeIndividu",
    }),

    selectedLocale: {
      get() {
        return this.$i18next.language;
      },

      set(value) {
        this.$i18next.changeLanguage(value);
      },
    },

    platform() {
      return process.env.CORDOVA_PLATFORM;
    },

    currentRoute() {
      return this.$route.name;
    },

    showSideBar() {
      if (
        this.userLogged &&
        this.currentRoute !== "error" &&
        !this.$isPhoneFormat
      )
        return true;
      return false;
    },

    showFootBar() {
      if (
        this.userLogged &&
        this.currentRoute !== "error" &&
        this.currentRoute !== "register" &&
        this.$isPhoneFormat
      )
        return true;
      return false;
    },

    showNavBar() {
      if (
        this.userLogged &&
        ![
          "error",
          "login",
          "resetPassword",
          "register",
          "validateAccount",
          "updateLogin"
        ].includes(this.currentRoute)
      )
        return true;
      return false;
    },

    locale() {
      return window.localStorage.getItem("locale");
    },
  },

  watch: {
    currentRoute: function () {
      pageUtils.autoScroll();
    },

    selectedLocale: function (newValue) {
      document.getElementsByTagName("html")[0].lang = newValue;
    },

    "$store.state.user.token": function (newValue) {
      if (newValue) window.localStorage.setItem("authToken", newValue);
      else {
        window.localStorage.removeItem("authToken");
        //this.$router.push({ name: 'login'})
      }
    },

    selectedContratId: function (newValue, oldValue) {
      this.loadDataOfContrat();
      if (oldValue) this.$router.push({ name: "home" });
    },

    userLogged: function (newValue) {
      if (newValue) {
        if (this.codeIndividu) {
          // Chargement des contrats de l'utilisateur connecté
          this.$store.dispatch("user/loadContratsOfUser");
        }

        this.$store.dispatch("utilities/getContratTravail");
        this.$store.dispatch("utilities/getSituationSociopro");
        this.$store.dispatch("utilities/getSituationFamiliale");
        this.$store.dispatch("utilities/getLienParente");
      }
    },
  },

  mounted() {
    this.checkVersionAndEnvironnement();

    if (this.$isAppCordova) {
      let self = this;
      document.addEventListener("resume", function () {
        self.checkVersionAndEnvironnement();

        // if (self.hideStart) {
        //   // L'utilisateur est revenu sur l'application
        //   // Calculer le temps écoulé
        //   let elapsedTime = moment().unix() - self.hideStart;
        //   alert(elapsedTime)
        
        //   // 10 secondes
        //   if (elapsedTime > 10) { 
        //     // Rafraîchir l'application
        //     this.$forceUpdate();
        //   }
        // }
      });

      // document.addEventListener('pause', () => {
      //   self.hideStart = moment().unix();
      //   });

      //     document.addEventListener('visibilitychange', () => {
      // });


      firebase.firebaseSetup();
    }

    if (window.localStorage.getItem("authToken"))
      this.$store.commit(
        "user/setToken",
        window.localStorage.getItem("authToken")
      );

    if (window.localStorage.getItem("locale"))
      this.$store.commit(
        "user/setLocale",
        window.localStorage.getItem("locale")
      );
  },

  methods: {
    checkVersionAndEnvironnement() {
      if (process.env.VUE_APP_API_URL.includes(".recette.")) {
        alert(
          "Vous êtes actuellement sur l'api de recette. Les données affichées sont fictives et vos actions n'auront pas d'impact sur la base réél."
        );
      }

      this.$store.dispatch("utilities/loadVersionInfos").then(() => {
        let versionInfos = this.utilities.versionInfos;

        if(versionInfos.maintenanceMode === "true") {
          this.maintenanceMode = "maintenance";
        } else if (
          versionInfos.minVersion &&
          this.appVersion &&
          versionning.isVersionLower(this.appVersion, versionInfos.minVersion)
        ) {
          this.maintenanceMode = "update";
        } else {
          this.maintenanceMode = false;
        }

        // if(this.maintenanceMode){
        // this.$router.push({ name: "error" });
        // window.stop();
        // }
      })
        .catch(() => {
          this.maintenanceMode = false;
        })

      // this.$store.dispatch("utilities/loadVersionInfos").then(() => {
      //   let versionInfos = this.utilities.versionInfos;
      //   let frontEnvironnement = process.env.VUE_APP_ENV;
      //   if (
      //     this.appVersion !== versionInfos.version &&
      //     frontEnvironnement === "prod"
      //   ) {
      //     alert(
      //       "Une nouvelle version de l'application est disponible. Veuillez la télécharger pour continuer à utiliser l'application."
      //     );
      //     if(this.platform === 'android')
      //       this.goToPlayStore();
      //     else if (this.platform === 'ios')
      //       this.goToAppStore();

      //     this.isNotUpToDate = true;
      //   }

      //   // Permet de savoir si on tape sur base info ou prod
      //   if (versionInfos.recette === "true") {
      //     alert(
      //       "Vous êtes actuellement sur l'api de recette. Les données affichées sont fictives et vos actions n'auront pas d'impact sur la base réél."
      //     );
      //   }

      //   let isCorrectRecette =
      //     frontEnvironnement === "recette" &&
      //     versionInfos.environnement === "prod" &&
      //     versionInfos.recette === "true";

      //   if (
      //     frontEnvironnement !== versionInfos.environnement &&
      //     !isCorrectRecette
      //   ) {
      //     alert(
      //       "Votre environnement ne correspond pas à celui de l'api. Veuillez contactez contact@amsom-habitat.fr"
      //     );
      //   }
      // });
    },

    goToPlayStore() {
      if (window.cordova) {
        cordova.InAppBrowser.open(
          "https://play.google.com/store/apps/details?id=fr.amsomhabitat.amsometmoi",
          "_system"
        );
      } else {
        window.open(
          "https://play.google.com/store/apps/details?id=fr.amsomhabitat.amsometmoi",
          "_system"
        );
      }
    },

    goToWebSite() {
      if (window.cordova) {
        cordova.InAppBrowser.open("https://client.amsom-habitat.fr", "_system");
      } else {
        window.open("https://client.amsom-habitat.fr", "_system");
      }
    },

    goToAppStore() {
      if (window.cordova) {
        cordova.InAppBrowser.open(
          "https://apps.apple.com/fr/app/amsom-moi/id6449927674",
          "_system"
        );
      } else {
        window.open(
          "https://apps.apple.com/fr/app/amsom-moi/id6449927674",
          "_system"
        );
      }
    },

    loadDataOfContrat(redirect = false) {
      if (this.selectedContratId) {
        const calls = [];

        // Déclenche les calls qui seront appelés tout au long de la navigation dans l'application
        calls.push(
          this.$store.dispatch(
            "contrat/loadContratInfosById",
            this.selectedContratId
          )
        );
        calls.push(
          this.$store.dispatch(
            "contrat/loadIndividuInfosByContrat",
            this.selectedContratId
          )
        );
        calls.push(
          this.$store.dispatch(
            "compte/loadLigneByContratId",
            this.selectedContratId
          )
        );

        this.$store.commit("moduleContrat/resetState");
        this.$store.commit("contact/resetState");
        this.$store.commit("sollicitation/resetState");

        Promise.all(calls).then(() => {
          if (redirect) {
            this.$router.push({ name: "home" });
          }
        });
      }
    },

    hideSideBar() {
      this.$store.commit("sidebar/setIsExtended", false);
    },
  },
};
</script>

<style>
body {
  color: var(--bs-primary);
}

H1,
H2,
h3,
h4,
h5,
h6,
p {
  color: var(--bs-primary);
}

.btn-content {
  width: 100% !important;
  min-width: 100% !important;
}

/* Styles pour le contenu */
.content {
  transition: margin-left 0.5s;
  overflow: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
  overflow: hidden !important;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  overflow: hidden !important;
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
  overflow: hidden !important;
}

.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
  overflow: hidden !important;
}
</style>
