<template>
  <amsom-overlay
    :loading="registerPage.loading > 0"
    class="w-100"
  >
    <div class="card w-75 m-auto mt-5">
      <div v-if="registerPage.loading > 0">
        <p class="text-info">
          Demande de validation en cours ...
        </p>
      </div>

      <div
        v-else-if="error"
        class="text-center"
      >
        <h5 class="text-danger ">
          Ce lien n'est pas valide
        </h5>

        <small class="text-primary"> {{ error }}</small>
      </div>

      <div v-else-if="!registerPage.loading > 0">
        <h5 class="text-primary text-center">
          Votre compte a bien été activé, vous pouvez fermer cette page.
        </h5>
      </div>
    </div>
  </amsom-overlay>
</template>

<script>

import {mapState} from "vuex";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";

export default {
  name: "ValidateAccountPage",
  components: {AmsomOverlay},

  data() {
    return {
      individu: null,
      error: null,
    }
  },

  computed: {
    ...mapState(['registerPage'])
  },

  watch: {
    '$store.state.registerPage.loading': function (newvalue) {
      if(newvalue > 0)
        return

      setTimeout(() => {
        window.close()
      }, 5000)
    }
  },

  mounted() {
    if (!('key' in this.$route.query)) {
      this.error = 'Merci d\'activer votre compte via le lien envoyé sur votre adresse mail'
      return
    }

    this.individu = this.$route.query.key

    const self = this
    this.$store.dispatch('registerPage/validateAccount', {individu : this.individu})
      .catch(errorMsg => {
        self.error = errorMsg;
      })
  }
}
</script>
