import HomePage from '../views/Home'
import LoginPage from '../views/Login'
import RegisterPage from '../views/Register'
import {createRouter, createWebHistory} from "vue-router";
import ValidateAccount from "@/views/ValidateAccount";
import store from "@/store";
import EnqueteHomePage from "@/views/EnqueteHomePage";
import EnqueteEntryPage from "@/views/EnqueteEntryPage";
import ResetPasswordPage from "@/views/ResetPasswordPage";
import UpdateLoginPage from "@/views/UpdateLoginPage";
import ContactPage from "@/views/ContactPage.vue";
import ComptePage from "@/views/ComptePage.vue";
import ContratPage from "@/views/ContratPage.vue";
import AssurancePage from "@/views/AssurancePage.vue";
import AssurerPage from "@/views/AssurerPage.vue";
import SollicitationPage from "@/views/SollicitationPage.vue";
import ErrorPage from "@/views/ErrorPage";
import SollicitationDetailPage from "@/views/SollicitationDetailPage";
import PrelevementFeedPage from "@/views/PrelevementPage.vue";
import NotificationPage from "@/views/NotificationPage.vue";

const routes = [
    {path: '/', redirect: '/home'},
    {
        name: 'home',
        path: '/home',
        component: HomePage,
        meta: {
            mustBeAuthenticated: true
        },
    },
    {
        name: 'login',
        path: '/login',
        component: LoginPage,
        meta: {
            mustBeUnauthenticated: true
        }
    },
    {
        name: 'error',
        path: '/error',
        component: ErrorPage
    },
    // Register
    {
        name: 'validateAccount',
        path: '/validateAccount',
        component: ValidateAccount
    },
    {
        name: 'register',
        path: '/register',
        component: RegisterPage
    }
    ,
    {
        name: 'resetPassword',
        path: '/resetPassword',
        component: ResetPasswordPage
    },
    {
        name: 'updateLogin',
        path: '/updateLogin',
        component: UpdateLoginPage
    },
    // CGU
    {
        name: 'cgu',
        path: '/cgu',
        component: () => import('../views/CGU.vue'),
    },
    //Enquete
    {
        name: 'enqueteHome',
        path: '/enquete/home',
        component: EnqueteHomePage,
        meta: {
            mustBeAuthenticated: true
        },
    },
    {
        name: 'enqueteEntry',
        path: '/enquete/saisir',
        component: EnqueteEntryPage,
        beforeEnter: function (to, from, next) {
            if (store.state.enquete.enqueteContrat !== null && store.getters["user/isLogged"]) {
                next();
            } else
                next({name: 'enqueteHome'})
        }
    },
    //Mon profil
    {
        name: 'profil',
        path: '/profil',
        component: () => import('../views/ProfilPage.vue'),
        meta: {
            mustBeAuthenticated: true
        },
    },
    //Mes contact
    {
        name: 'contacts',
        path: '/contacts',
        component: ContactPage,
        meta: {
            mustBeAuthenticated: true
        },
    },
    //Mon compte
    {
        name: 'compte',
        path: '/compte',
        component: ComptePage,
        meta: {
            mustBeAuthenticated: true
        },
    },
    //Mon Prelevement
    {
        name: 'prelevement',
        path: '/compte/prelevement',
        component: PrelevementFeedPage,
        meta: {
            mustBeAuthenticated: true
        },
    },
    //Mon contrat
    {
        name: 'contrat',
        path: '/contrat',
        component: ContratPage,
        meta: {
            mustBeAuthenticated: true
        },
    },
    //Mon assurance
    {
        name: 'assurance',
        path: '/assurance',
        component: AssurancePage,
        meta: {
            mustBeAuthenticated: true
        },
    },
    {
        name: 'assurer',
        path: '/assurance/modifier',
        component: AssurerPage,
        meta: {
            mustBeAuthenticated: true
        },
    },
    //Mes sollicitations
    {
        name: 'sollicitation',
        path: '/sollicitation',
        component: SollicitationPage,
        meta: {
            mustBeAuthenticated: true
        },
    },
    {
        name: 'sollicitationDetail',
        path: '/sollicitation/:sollicitationId',
        component: SollicitationDetailPage,
        meta: {
            mustBeAuthenticated: true
        },
    },
    //Mes notifications
    {
        name: 'notification',
        path: '/notification',
        component: NotificationPage,
        meta: {
            mustBeAuthenticated: true
        },
    },
    // Payer mon loyer
    {
        name: 'paiement',
        path: '/paiement',
        component: () => import('../views/PaiementPage.vue'),
        meta: {
            mustBeAuthenticated: true
        },
    },
    // *
    {path: '/:pathMatch(.*)*', name: 'not-found', redirect: '/'}
];

const router = createRouter({
    history: createWebHistory(),
    mode: 'hash',
    routes,
})


router.beforeEach((to, from, next) => {
    if (to.meta.mustBeAuthenticated && !store.getters["user/isLogged"]) {
        window.localStorage.setItem("afterLoginRoute", JSON.stringify(to))
        next({name: 'login'})
    } else if (to.meta.mustBeUnauthenticated && store.getters["user/isLogged"]) {
        console.log("Vous devez être déconnecté pour accéder à cette page")
        next({name: 'home'})
    } else {
        next()
    }
})
export default router;

