<template>
  <div>
    <form
      class="text-start"
      @submit.prevent="validate()"
    >
      <div class="mb-3">
        <label
          for="input-group-identifiant"
          class="form-label"
        >{{ $t('loginPage.usernameLabel') }}</label>

        <input
          id="input-group-identifiant"
          v-model.trim="login.email"
          v-amsom-formater-lower-case
          type="email"
          autocomplete="email"
          class="form-control rounded-3"
          :placeholder="$t('loginPage.usernamePlaceholder')"
          required
        >
      </div>

      <div class="mb-3">
        <label
          for="input-password"
          class="form-label"
        >{{ $t('loginPage.passwordLabel') }}</label>

        <div class="position-relative">
          <input
            id="input-password"
            v-model="login.password"
            class="form-control rounded-3"
            rows="3"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="current-password"
            placeholder="******"
          >
          
          <div
            v-if="login.password"
            role="button"
            class="position-absolute end-0 top-50 translate-middle-y pe-2 text-primary"
          >
            <font-awesome-icon
              v-if="!showPassword"
              icon="fa-solid fa-eye"
              data-toggle="tooltip"
              data-placement="top"
              title="Afficher mot de passe en clair"
              @click="showPassword = !showPassword"
            />

            <font-awesome-icon
              v-else-if="showPassword"
              icon="fa-solid fa-eye-slash"
              data-toggle="tooltip"
              data-placement="top"
              title="Cacher mot de passe"
              @click="showPassword = !showPassword"
            />
          </div>
        </div>

        <small
          role="button"
          class="text-decoration-underline text-secondary ms-1"
          style="font-size: smaller;"
          @click="resetPassword"
        >
          {{ $t('loginPage.forgetPassword') }}
        </small>
      </div>

      <div
        v-if="error"
        id="login-error-message"
        class="text-center my-2 text-danger"
      >
        <span class="text-danger fs-6 fw-bold">
          {{ error }}
        </span>
      </div>

      <button 
        id="login-button"
        type="submit"
        class="btn btn-amsom-green rounded-5 mt-3 py-2 px-4 d-block mx-auto"
        @click="stayConnected = !stayConnected"
      >
        <span>{{ $t("loginPage.loginButton") }}</span>

        <font-awesome-icon
          icon="fa-solid fa-arrow-right"
          class="ms-2 my-auto"
        />
      </button>
    </form>
  </div>
</template>

<script>

export default {
  name: "LoginFormBlock",

  data() {
    return {
      login: {
        email: null,
        password: null,
      },

      stayConnected: false,

      error: null,

      showPassword: false,

      afterLoginRoute: null,
    };
  },

  mounted() {
    let afterLoginRoute = window.localStorage.getItem("afterLoginRoute");

    if (afterLoginRoute) {
      this.afterLoginRoute = JSON.parse(afterLoginRoute);
    }
  },

  methods: {
    async validate() {
      const self = this;

      let fcm = null
      //TODO obligé de faire ca car le premier call ne recupere pas le token a la fin (erreur de thread)
      if(this.$isAppCordova){
        fcm = await cordova.plugins.firebase.messaging.getToken()
      }

      let credentials = {
        email: this.login.email.toLowerCase(),
        password: this.login.password,
        fcm: fcm,
      };
      //todo
      // alert(window.localStorage.getItem('fcm') ?? null)

      this.$store
        .dispatch("user/login", credentials)
        .then(() => {
          self.error = null;
          window.localStorage.removeItem("afterLoginRoute");

          self.$router.push(
            this.afterLoginRoute ? this.afterLoginRoute : { name: "home" }
          );
        })
        .catch((error) => {
          console.log(error);
          self.error =
            (error.response ? error.response.status : null) === 401
              ? this.$t('loginPage.invalidCredentials')
              : "Erreur";
        });
    },

    resetPassword() {
      this.$router.push({ name: "resetPassword" });
    },
  },
};
</script>

<style scoped>
/* https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
</style>

