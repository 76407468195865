import moment from "moment-timezone";


export function unixIsBefore(unix, when = moment()){
    return moment.unix(unix).isBefore(when)
}

export function unixIsAfter(unix, when = moment()){
    return !unixIsBefore(unix, when)
}

export function afterEndOfWeek(unix){
    return moment.unix(unix).isAfter(moment().endOf('week'))
}
