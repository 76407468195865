import axios from "axios";
import store from '../store/index'
import router from "../router";
// import https from "https";

export default function axiosOverlay(config, mustBeAuthenticated = true, retry = true, allowRedirect = true) {
    // process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

    const token = store.state.user.token
    const instance = axios.create()

    if (mustBeAuthenticated) {
        instance.defaults.headers.common['Authorization'] = "Bearer " + store.state.user.token
    }
    instance.defaults.headers.common['Accept'] = "application/json"
    instance.defaults.headers.common['Access-Control-Allow-Origin'] = "*"

    // const agent = new https.Agent({
    //     rejectUnauthorized: false,
    // })
    //
    // instance.defaults.httpsAgent = agent

    return new Promise((resolve, reject) => {
        instance(config)
            .then(response => {
                resolve(response)
            })
            .catch(async error => {
                // log des erreurs en cas de mode dev
                if (process.env.VUE_APP_DEBUG_ENABLED === "true") console.log(error)

                //surcharge des codes erreur
                if (error.response && error.response.status === 449 && retry) {
                    store.commit('user/setToken', error.response.data.token)
                }

                //On vérifie que l'erreur soit pas du au fait que nous ayons nous meme deja update le token
                if (token !== store.state.user.token) {
                    resolve(axiosOverlay(config, mustBeAuthenticated, false))
                }

                if (error.response && [498, 401].includes(error.response.status) && mustBeAuthenticated) {
                    window.stop()
                    store.dispatch('user/logout');
                    if(allowRedirect) //cas de connection en boucle en attente de validation du mail
                        await router.push({name: 'login'});
                    else
                        reject();
                }

                if (error.response && error.response.status === 403) {
                    // toast.showToast('error', 'Code 403 : Vous n\'êtes pas autorisé à voir ces informations', 2000)
                    console.log('403')
                }

                if (error.response && [500, 404, 0].includes(error.response.status) && allowRedirect) {
                    console.log(error.response.status)
                    await router.push({name: 'error'});
                }

                reject(error)
            });
    });
}
