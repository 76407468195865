import axiosOverlay from "@/services/axiosOverlay";
import apiErrorParser from "@/js/apiErrorParser";
import { sortArray } from "@/js/array.js";

async function getImageFromDist(idImage) {
    // Liste des extensions possibles
    const extensions = ["jpg", "png", "gif", "jpeg"]; // Ajouter d'autres extensions au besoin

    // Parcourt les extensions et recherche le premier fichier existant
    for (const extension of extensions) {
        try {
            // Récupère l'image via l'URL
            const response = await fetch(
                process.env.VUE_APP_FILESERVER_URL + '/dist/slide/' + idImage + '.' + extension,
            );

            // Si la réponse est un succès, renvoie l'image
            if (response.status === 200) {
                let blob = await response.blob();
                return URL.createObjectURL(blob);
            }
        } catch (error) {
            // Si la réponse est une erreur, passe à la suivante
            continue;
        }
    }

    // // Parcourt les extensions et recherche le premier fichier existant
    // for (const extension of extensions) {
    //     try {
    //         const imagePath = require("/public/dist/slide/" +
    //             idImage +
    //             "." +
    //             extension);
    //         return imagePath;
    //     } catch (error) {
    //         // Si le fichier n'existe pas avec cette extension, passe à la suivante
    //         continue;
    //     }
    // }

    // Si aucun fichier n'est trouvé avec les extensions spécifiées, renvoyez une erreur ou une valeur par défaut
    return require("@/assets/images/communicationCiblee/default.jpg")
}

const getDefaultState = () => {
    return {
        slides: [],
        loading: 0,
    }
}

const state = getDefaultState()

const getters = {
}

const mutations = {
    setLoading: (state, val) => {
        state.loading += val;
    },
    async setSlides(state, slides) {
        for (let slide of slides) {
            slide.image = await getImageFromDist(slide?.idImage);

            // const imageUrl = process.env.VUE_APP_FILESERVER_URL + "/slide/" + slide?.idImage;

            // slide.image = await fetch(imageUrl)
            //     .then((response) => {
            //         if (response.ok) {
            //             return imageUrl; // Si l'image existe, retournez son URL
            //         } else {
            //             // Si l'image n'existe pas, retournez l'URL de l'image par défaut
            //             return require("@/assets/images/communicationCiblee/default.jpg"); // Assurez-vous d'ajuster ce chemin en fonction de l'emplacement de votre image par défaut
            //         }
            //     })
            //     .catch((error) => {
            //         // Gérez les erreurs ici, si nécessaire
            //         return require("@/assets/images/communicationCiblee/default.jpg"); // En cas d'erreur, retournez l'URL de l'image par défaut
            //     });
        }

        state.slides = sortArray(slides, 'idCommunication', true)
    },
    resetState: (state) => {
        Object.assign(state, getDefaultState())
    }
}

const actions = {
    loadSlides: ({ commit, state }, contratId) => {
        if (state.slides.length === 0) {
            commit('setLoading', 1);
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_URL + '/communication_ciblees?idContrat=' + contratId
                const config = {
                    url: url,
                    method: 'GET'
                }

                axiosOverlay(config)
                    .then(function (response) {
                        commit('setSlides', response.data)
                        resolve()
                    })
                    .catch(function (error) {
                        console.log(error)
                        reject(apiErrorParser(error))
                    })
                    .finally(() => {
                        commit('setLoading', -1);
                    })
            })
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
