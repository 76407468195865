<template>
  <div class="row justify-content-center g-0">
    <!--            step 1 -->
    <register-step1-block
      v-if="registerStep === 1"
      cols="12"
    />

    <!--            step 2 -->
    <register-step2-block
      v-if="registerStep === 2"
      cols="12"
    />

    <!--            step 3 -->
    <register-step3-block
      v-if="registerStep === 3"
      cols="12"
    />

    <!--            step 4 -->
    <register-step4-block
      v-if="registerStep === 4"
      cols="12"
    />

    <!--            step error -->
    <register-error-block
      v-if="registerStep === 0"
      cols="12"
    />
  </div>
</template>

<script>
import {mapState} from "vuex";
import RegisterStep1Block from "@/components/RegisterPage/Step1Block.vue";
import RegisterStep2Block from "@/components/RegisterPage/Step2Block.vue";
import RegisterStep3Block from "@/components/RegisterPage/Step3Block.vue";
import RegisterStep4Block from "@/components/RegisterPage/Step4Block.vue";
import RegisterErrorBlock from "@/components/RegisterPage/ErrorBlock.vue";

export default {
  name: "RegisterFormBlock",
  components: {RegisterStep4Block, RegisterStep3Block, RegisterStep1Block, RegisterStep2Block, RegisterErrorBlock},

  data() {
    return {
      registerForm: {
        numContrat: null,
        mailContrat: null,
        telContrat: null,
        confirmCodePhone: null,
        password: null,
        confirmPassword: null
      },
    }
  },

  computed: {
    ...mapState(['registerPage']),

    registerStep: {
      get() {
        return this.registerPage.registerStep
      },

      set(value) {
        this.$store.commit('registerPage/setRegisterStep', value)
      }
    },

    validationPassword() {
      return this.registerForm.password === this.registerForm.confirmPassword
    }
  },

  methods: {
    validateStep3() {
      if (this.validationPassword) {
        setTimeout(() => {
          window.location = "/"
        }, 5000)
        this.registerStep++;
      }
    },
  }
}
</script>
