<template>
  <div
    class="container-fluid py-2 px-4"
  >
    <div class="row gy-3">
      <div class="col-12 d-lg-none">
        <cb-widget style="min-height: 20vh;" />
      </div>

      <div class="col-12 col-lg-8">
        <lignes-compte-panel
          style="max-height: 80vh;"
          class="d-none d-lg-block"
        />

        <lignes-compte-panel
          style="max-height: 48vh;"
          class="d-block d-lg-none"
        />
        <!-- Désactivation suite à un atelier DAP -->
        <!-- <solde-positif-widget v-if="solde>0" /> -->
      </div>

      <div class="col-12 col-lg-4">
        <div class="row gy-3">
          <div class="col-12 d-none d-lg-block">
            <cb-widget style="min-height: 20vh;" />
          </div>

          <div class="col-12">
            <rib-widget
              id="rib-widget"
              style="min-height: 20vh;"
            />
          </div>

          <div class="col-12">
            <!--            <document-list -->
            <!--              style="max-height: 35vh;" -->
            <!--              titre="Mes derniers avis d'échéance" -->
            <!--              :length="-1" -->
            <!--              :categories="[ -->
            <!--                'Avis d\'échéance', -->
            <!--                'Avis d\'échéance complémentaires' -->
            <!--              ]" -->
            <!--            /> -->
            <div
              class="card"
            >
              <div class="card-body">
                <amsom-overlay :loading="ged.loading > 0">
                  <div class="d-flex justify-content-between mb-3">
                    <div class="d-flex align-items-center">
                      <span class="fs-5">Mes documents</span>
                    </div>

                    <div class="d-flex align-items-center">
                      <select
                        v-if="monthOptions && monthOptions.length > 0"
                        id="select-date-document"
                        v-model="selectedTerme"
                        class="form-select form-select-sm fs-7 rounded-3 py-0"
                      >
                        <option
                          v-for="option in monthOptions"
                          :key="option.value"
                          :value="option.value"
                          class="text-uppercase"
                        >
                          {{ option.texte }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div v-if="(ged.gedMensuel && ged.gedMensuel.length === 0) || !selectedTerme">
                    Aucun document
                  </div>

                  <div v-else>
                    <button
                      id="btn-download-avis-echeance"
                      class="btn w-100 bg-secondary rounded rounded-3 text-white px-2 py-3 my-1 d-flex align-items-center"
                      style="background: linear-gradient(90deg, #43425D 0%, #677F8E 100%);"
                      :disabled="!avisEcheance"
                      @click="dowloadFileGed(avisEcheance.idGed, 'Avis d\'échéance '+ getMonthYear(selectedTerme))"
                    >
                      <template v-if="avisEcheance">
                        <div class="w-75 text-start h-100">
                          <span>Télécharger </span>

                          <span class="fw-bold">mon avis d'échéance </span>

                          <span class="fw-bold">{{ getMonthYear(selectedTerme) }}</span>
                        </div>

                        <div class="w-25 text-center">
                          <font-awesome-icon
                            icon="fa-solid fa-cloud-arrow-down"
                            size="lg"
                          />
                        </div>
                      </template>

                      <template v-else>
                        <div class="text-start">
                          <span class="fw-bold d-block">Avis d'échéance  {{ getMonthYear(selectedTerme) }}</span>

                          <span>non disponible</span>
                        </div>
                      </template>
                    </button>

                    <template
                      v-for="avisEcheanceComp in avisEcheanceCompList"
                      :key="avisEcheanceComp.id"
                    >
                      <button
                        v-if="avisEcheanceComp"
                        :id="'btn-download-avis-echeance-comp-'+avisEcheanceComp.id"
                        class="btn w-100 bg-secondary rounded rounded-3 text-white px-2 py-3 my-1 d-flex align-items-center"
                        style="background: linear-gradient(90deg, #43425D 0%, #677F8E 100%);"
                        @click="dowloadFileGed(avisEcheanceComp.idGed, avisEcheanceComp.libelle)"
                      >
                        <div class="w-75 text-start h-100">
                          <span>Télécharger </span>

                          <span class="fw-bold text-lowercase">mon {{ avisEcheanceComp.libelle }}</span>
                        </div>

                        <div class="w-25 text-center">
                          <font-awesome-icon
                            icon="fa-solid fa-cloud-arrow-down"
                            size="lg"
                          />
                        </div>
                      </button>
                    </template>

                    <button
                      id="btn-download-quittance"
                      class="btn w-100 bg-secondary rounded rounded-3 text-white px-2 py-3 my-1 d-flex align-items-center"
                      style="background: linear-gradient(90deg, #677F8E 0%, #43425D 100%);"
                      :disabled="!quittance"
                      @click="generateQuittance(selectedTerme)"
                    >
                      <template v-if="quittance">
                        <div class="w-75 text-start  h-100">
                          <span>Télécharger </span>

                          <span class="fw-bold">ma quittance de Loyer </span>

                          <span class="fw-bold">{{ getMonthYear(selectedTerme) }}</span>
                        </div>

                        <div class="w-25 text-center">
                          <font-awesome-icon
                            icon="fa-solid fa-cloud-arrow-down"
                            size="lg"
                          />
                        </div>
                      </template>

                      <template v-else>
                        <div class="text-start">
                          <span class="fw-bold">
                            Quittance de loyer {{ getMonthYear(selectedTerme) }}
                          </span>

                          <span class="fw-bold" />

                          <span> en attente de paiement</span>
                        </div>
                      </template>
                    </button>
                  </div>
                </amsom-overlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CbWidget from '@/components/ComptePage/CbWidget.vue'
import DocumentList from '@/components/DocumentList.vue'
import RibWidget from '@/components/ComptePage/RibWidget.vue'
import LignesComptePanel from '@/components/ComptePage/LignesComptePanel.vue'
import {mapGetters, mapState} from "vuex";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {sortArray} from "@/js/array.js";
import moment from "moment-timezone";
import fileUtils from "@/js/fileUtils.js";

export default {
  name: 'ComptePage',

  components: {
    AmsomOverlay,
    FontAwesomeIcon,
    CbWidget,
    RibWidget,
    LignesComptePanel,
  },

  data() {
    return {
      selectedTerme: null
    }
  },

  computed: {
    ...mapGetters({
      selectedContratId: 'user/getSelectedContrat',
    }),

    ...mapState(['ged']),

    monthOptions() {
      // get all month/year option using props terme of array gedMensuel
      // terme is YYYYMM
      let res = null
      if (this.ged.gedMensuel) {
        res = this.ged.gedMensuel.map((item) => {
          return {
            texte: this.getMonthYear(item.terme),
            value: item.terme
          }
        })

        res = sortArray(res, 'value', false)
      }

      return res
    },

    documentCurrentTerme() {
      if (this.ged.gedMensuel && this.selectedTerme) {
        return this.ged.gedMensuel.find((item) => item.terme === this.selectedTerme)
      }

      return null
    },

    quittance() {
      if (this.documentCurrentTerme) {
        return this.documentCurrentTerme.documents.find((item) => item.type === 'quittance' && item.id !== null)
      }

      return null
    },

    avisEcheance() {
      if (this.documentCurrentTerme) {
        return this.documentCurrentTerme.documents.find((item) => item.type === 'avis_echeance' && item.id !== null)
      }

      return null
    },

    avisEcheanceCompList() {
      if (this.documentCurrentTerme) {
        return this.documentCurrentTerme.documents.filter((item) => item.type === 'avis_echeance_comp' && item.id !== null)
      }

      return null
    }
  },

  watch: {
    'selectedContratId': function () {
      this.loadDataOfContrat()
    },

    'monthOptions': function () {
      if (this.monthOptions && this.monthOptions.length > 0) {
        for(let month of this.monthOptions) {
          // if have the same month in the list of monthOptions and have at least one document of documents with id not null
          if (this.ged.gedMensuel.find((item) => item.terme === month.value && item.documents.find((doc) => doc.id !== null))) {
            this.selectedTerme = month.value
            break
          }
          this.selectedTerme = this.monthOptions[0].value
        }
      }
    }
  },

  mounted() {
    this.$store.commit('topbar/setBreadcrumb',
                       [
                         {icon: 'fa-solid fa-house', to: {name: 'home'}},
                         {text: 'Mon compte', active: true}
                       ]
    )

    this.loadDataOfContrat()
  },

  methods: {
    loadDataOfContrat(){
      if (this.selectedContratId) {
        this.$store.dispatch('contrat/loadPrelevementInfos', this.selectedContratId)
        this.$store.dispatch('ged/loadGedMensuelByContratId', this.selectedContratId)
      }
    },

    getMonthYear(terme) {
      let month = moment(terme, 'YYYYMM').format('MMMM YYYY')
      return month.charAt(0).toUpperCase() + month.slice(1)
    },

    dowloadFileGed(id, filename = 'Document') {
      this.$store.dispatch('ged/loadPdfById', id)
        .then((data) => {
          fileUtils.downloadFile(data, filename)

          this.isDownloading = false
        })
        .finally(() => this.isDownloading = false)
    },

    generateQuittance(terme) {
      this.$store.dispatch('ged/generateQuittance', {idContrat: this.selectedContratId, terme: terme})
        .then((data) => {
          fileUtils.downloadFile(data, 'Quittance de loyer ' + this.getMonthYear(terme))

          this.isDownloading = false
        })
        .finally(() => this.isDownloading = false)
    }
  }
}
</script>
