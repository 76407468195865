<template>
  <div class="card h-100">
    <img
      :src="bgImage"
      class="bg-white card-img widget-img"
      alt="Image de fond du widget"
    >

    <div
      class="widget-content text-primary d-flex flex-column h-100"
    >
      <slot
        name="title"
      />

      <div
        class="h-100 overflow-auto no-scroll-bar"
      >
        <slot
          name="content"
        />
      </div>
    
      <div class="mt-auto mb-0 text-end">
        <slot name="btn-action" />
      </div>
    </div>
  </div>
</template>

<script>

export default{
  name: 'WidgetBlock',

  props: {
    bgImage: {
      type: String,
      default: 'https://picsum.photos/200/300'
    }
  }
}
</script>