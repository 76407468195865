<template>
  <div
    id="component-assurance-description-panel"
    class="card"
  >
    <div class="card-body card-text">
      <h5>Pourquoi faut-il s'assurer ?</h5>

      <p>
        La loi du 6 juillet 1989, ainsi que le contrat de location que vous avez
        signé, vous imposent d’être assurés et de payer régulièrement votre
        cotisation d’assurance.
      </p>

      <p>
        Cela vous permet, en cas de problème, de faire fonctionner votre
        assurance pour la prise en charge des dégâts.
      </p>

      <template v-if="assurance === null">
        <h5 class="text-danger">
          Vous n'êtes pas assuré
        </h5>
      </template>

      <template v-else>
        <h5
          v-if="unixIsAfter(assurance?.dateDebut)"
          class="text-secondary"
        >
          {{ $t("assurance.comming") }}
          {{ unixToDateString(assurance?.dateDebut, "DD/MM") }}
        </h5>

        <h5
          v-else-if="!assurance.valide"
          class="text-danger"
        >
          {{ $t("assurance.expired") }}
        </h5>

        <h5 v-else>
          {{ $t("assurance.aJour") }}
        </h5>

        <p v-if="!assurance.valide">
          Votre assurance est expirée depuis le
          <strong>{{ unixToDateString(assurance.dateFin) }}</strong>, vous devez la renouveler et nous télétransmettre votre nouvelle
          attestation.
        </p>

        <p v-else>
          Votre assurance expire le
          <strong>{{ unixToDateString(assurance.dateFin) }}</strong>, pensez à la renouveler et nous télétransmettre votre nouvelle
          attestation avant cette date.
        </p>
      </template>

      <div
        class="card bg-secondary"
      >
        <div class="card-text py-4 px-3 text-gray-300">
          En l’absence d’assurance à jour sur votre contrat vous vous exposez à
          des sanctions pouvant aller jusqu’à l’expulsion.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { unixToDateString } from "../../js/text.js";
import { unixIsAfter } from "../../js/dateUtils.js";

export default {
  name: "AssuranceDescriptionPanel",

  props: {
    assurance: {
      type: Object,
      required: false,
      default: null,
    },
  },

  methods: {
    unixToDateString,
    unixIsAfter,
  },
};
</script>
