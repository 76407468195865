import axiosOverlay from "@/services/axiosOverlay";
import apiErrorParser from "@/js/apiErrorParser";
import store from "@/store";
import moment from "moment-timezone";

const getDefaultState = () => {
    return {
        loading: 0,

        enqueteIndividus: null,
        enqueteContrat: null,
        rejets: null,
    }
}

const state = getDefaultState()

const getters = {
    getSignataires: (state) => {
        if (!state.enqueteIndividus)
            return []

        return state.enqueteIndividus.filter((s) => s.type === "signataire");
    },

    getCosignataires: (state) => {
        if (!state.enqueteIndividus)
            return []

        return state.enqueteIndividus.filter((s) => s.type === "cosignataire");
    },

    getOccupants: (state) => {
        if (!state.enqueteIndividus)
            return []

        return state.enqueteIndividus.filter((s) => s.type === "occupant");
    },

    getAge: () => (individu, dateComparaison = null) => {
        if(!dateComparaison)
            dateComparaison = moment()

        let dateNaissance = individu?.dateNaissance;

        if (!dateNaissance)
            return 0

        return dateComparaison.diff(moment.unix(dateNaissance), 'years');
    },

    isMajeur: () => (individu, dateComparaison = null) => {
        return store.getters['enquete/getAge'](individu, dateComparaison) >= 18
    },

    getNomPrenom: () => (data) => {
        if(!data)
            return ""
        return (
            data.prenom +
            " " +
            (data.nom ?? data.nomNaissance)
        ).toUpperCase();
    },

    getTypeLocataire: () => (data) => {
        if (!data?.type) return "occupant";

        return data.type.toLowerCase();
    },

    getIndividuByIdIndividuEnquete: (state) => (id) => {
        if (!state.enqueteIndividus)
            return null

        return state.enqueteIndividus.find((s) => s.id === id);
    },

    getRejetsGrouped: (state) => {
        if (!state.rejets)
            return 

        let res = {}

        for (let rejet of state.rejets) {
            let id = rejet.idEnqueteIndividu ?? null
            if(!res[rejet.type])
                res[rejet.type] = id ? {} : []

            if(id){
                if(!res[rejet.type][id])
                    res[rejet.type][id] = []
                res[rejet.type][id].push(rejet)
            }
            else{
                res[rejet.type].push(rejet)
            }

        }
        return res
    }
}

const mutations = {
    setLoading: (state, val) => {
        if (val === '+')
            state.loading++

        if (val === '-')
            state.loading--
    },
    setRejets: (state, rejets) => {
        state.rejets = rejets
    },
    setEnqueteContrat: (state, enqueteContrat) => {
        state.enqueteContrat = enqueteContrat
    },
    setEnqueteIndividus: (state, enqueteIndividus) => {
        state.enqueteIndividus = enqueteIndividus
    },
    addEnqueteIndividus: (state, newIndividu) => {
        state.enqueteIndividus.push(newIndividu)
    },
    removeLastEnqueteIndividu: (state) => {
        //remove last individu in the list
        state.enqueteIndividus.splice(state.enqueteIndividus.length - 1, 1)
    },
    updateIndividu: (state, enqueteIndividu) => {
        if (!enqueteIndividu[0])
            return

        let newIndividu = enqueteIndividu[0]

        //replace old individu by the new one using the id field and add it if not exist
        let index = state.enqueteIndividus.findIndex(individu => individu.id === newIndividu.id)
        if (index !== -1)
            state.enqueteIndividus.splice(index, 1, newIndividu)
        else {
            //replace the last individu by the new one if the last individu is empty
            let lastIndividu = state.enqueteIndividus[state.enqueteIndividus.length - 1]
            if (lastIndividu && !lastIndividu.id)
                state.enqueteIndividus.splice(state.enqueteIndividus.length - 1, 1, newIndividu)
            else
                state.enqueteIndividus.push(newIndividu)
        }
    },
    deleteIndividu: (state, id) => {
        state.enqueteIndividus = state.enqueteIndividus.filter(individu => individu.id !== id)
    },
    resetState: (state) => {
        // Merge rather than replace so we don't lose observers
        // https://github.com/vuejs/vuex/issues/1118
        Object.assign(state, getDefaultState())
    }
}

const actions = {
    loadEnqueteContrat: ({ commit, state }, idContrat) => {
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/enquete_contrats?idContrat=' + idContrat
            const config = {
                url: url,
                method: 'GET'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    if (response.data.length)
                        commit('setEnqueteContrat', response.data[0]);
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    loadEnqueteIndividus: ({ commit, state }, data) => {
        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/enquete_individus?idContrat=' + data.idContrat + '&idEnquete=' + data.idEnquete
            const config = {
                url: url,
                method: 'GET'
            }

            axiosOverlay(config)
                .then(function (response) {
                    commit('setEnqueteIndividus', response.data)
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    reLoadEnqueteIndividus: ({ commit, state }, data) => {
        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/enquete_individus?id=' + data.id + '&idEnquete=' + data.idEnquete
            const config = {
                url: url,
                method: 'GET'
            }

            axiosOverlay(config)
                .then(function (response) {
                    commit('updateIndividu', response.data)
                    resolve()
                })
                .catch(function (error) {
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    loadRejetJusitficatif: ({ commit, state }, id) => {
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/rejet_justificatifs?idEnqueteContrat=' + id
            const config = {
                url: url,
                method: 'GET'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setRejets', response.data);
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    submitFiscalite: ({ commit }, data) => {
        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/enquete_fiscalite_submit'
            const config = {
                url: url,
                data: data,
                method: 'POST'
            }

            axiosOverlay(config)
                .then(function () {
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    signerEnquete: ({ commit }, data) => {
        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/enquete_signer'
            const config = {
                url: url,
                data: data,
                method: 'POST'
            }

            axiosOverlay(config)
                .then(function () {
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject((error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    initEnquete: ({ commit }, data) => {
        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/enquete_init'
            const config = {
                url: url,
                data: data,
                method: 'POST'
            }

            axiosOverlay(config)
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    submitIndividu: ({ commit }, data) => {
        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/enquete_individu_submit'
            const config = {
                url: url,
                data: data,
                method: 'POST'
            }

            axiosOverlay(config)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    deleteIndividu: ({ commit }, data) => {
        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/enquete_individu_delete'
            const config = {
                url: url,
                data: data,
                method: 'DELETE'
            }

            axiosOverlay(config)
                .then(function (response) {
                    commit('deleteIndividu', data.idEnqueteIndividu)
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
