import axiosOverlay from "@/services/axiosOverlay";
import apiErrorParser from "@/js/apiErrorParser";
import {sortArray} from "@/js/array.js";

const getDefaultState = () => {
    return {
        contactList: [],
        loading: 0,
    }
}

const state = getDefaultState()

const getters = {
    listLoading(state){
        return state.loading >0;
    },
    getContactList(state){
        return state.contactList;
    }
}

const mutations = {
    setLoading: (state, val) => {
        state.loading += val;
    },
    setContactList(state, list){
        state.contactList = sortArray(list, 'priorite', false)
    },
    resetState: (state) => {
        Object.assign(state, getDefaultState())
    }
}

const actions = {
    getContactsByModule: ({commit, state}, moduleId) => {
        if (state.contactList.length === 0)
        {
            commit('setLoading', 1);
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_URL + '/contacts?idModule=' + moduleId
                const config = {
                    url: url,
                    method: 'GET'
                }

                axiosOverlay(config, false)
                    .then(function (response) {
                        commit('setContactList', response.data)
                        resolve()
                    })
                    .catch(function (error) {
                        console.log(error)
                        reject(apiErrorParser(error))
                    })
                    .finally(() => {
                        commit('setLoading', -1);
                    })
            })
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
