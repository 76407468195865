<template>
  <div
    id="footbar"
    class="bg-body"
  >
    <ul class="nav nav-pills px-1 flex-nowrap overflow-auto justify-content-between justify-content-md-around no-scroll-bar">
      <li
        v-for="item in menuItems"
        :id="'nav-foot-' + item.name"
        :key="item.name"
      >
        <router-link
          :to="{name: item.name[0]}"
          class="nav-link px-0 w-100 text-center"
          :class="[{ 'active': item.name.includes(currentRoute) }]"
        >
          <font-awesome-icon
            class="fa mx-1 text-center"
            style="width: 25px"
            :icon="item.icon"
          />

          <span
            class="mx-2 d-block tiny-text"
          >{{ item.label }} </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>

import pageUtils from "@/js/pageUtils.js";

export default {
  name: 'FootBar',
  props: ['menuItems'],

  data() {
    return {
      activeItem: 'accueil'
    }
  },

  computed: {
    currentRoute() {
      return this.$route.name ?? ''
    }
  },

  watch: {
    'currentRoute': function (newValue){
      let route = this.menuItems.find((item) => newValue.includes(item.name))
      if(route)
        pageUtils.autoScroll('nav-foot-' + route.id)
    }
  },

  mounted() {
    let route = this.menuItems.find((item) => this.currentRoute.includes(item.name))
    if(route)
      pageUtils.autoScroll('nav-foot-' + route.id)
  },

  methods: {
  }}
</script>
