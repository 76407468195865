<template>
  <!-- Situation familiale -->
  <div
    id="input-group-situation-familiale"
    role="group"
    class="col-12 col-md-6"
  >
    <label
      class="form-label d-block"
      for="select-situation-familiale"
    >
      Situation familiale <span class="text-danger">*</span>
    </label>

    <select
      v-if="situationFamilialeOptions"
      id="select-situation-familiale"
      v-model="individu.situationFamiliale"
      class="form-select"
      name="situation-familiale"
      required
    >
      <option
        v-for="situation in situationFamilialeOptions"
        :key="situation.id"
        :value="situation.id"
      >
        {{ situation.libelle }}
      </option>
    </select>
  </div>

  <!-- Lien de parenté -->
  <div
    id="input-group-situation-familiale"
    role="group"
    class="col-12 col-md-6"
  >
    <template v-if="getTypeLocataire(individu) !== 'signataire'">
      <label
        class="form-label d-block"
        for="select-lien-signataire-principal"
      >
        Lien de parenté avec le(s) signataire(s)
        <span class="text-danger">*</span>
      </label>

      <select
        id="select-lien-signataire-principal"
        v-model="individu.lienParente"
        class="form-select"
        name="lien-signataire-principal"
        required
      >
        <option
          v-for="lien in lienParenteOptions"
          :key="lien.id"
          :value="lien.id"
        >
          {{ lien.libelle }}
        </option>
      </select>
    </template>
  </div>

  <!-- Erreur -->
  <div
    v-if="isConflictLienParenteSituation"
    class="col-12 bg-danger rounded-3 p-2 shadow text-light text-center"
  >
    Le lien de parenté et la situation familiale semblent incompatibles
  </div>

  <!-- Pièce justificative de mariage -->
  <div
    v-if="needPjLienParente || individu.pjLienParente"
    class="col-12"
  >
    <amsom-overlay :loading="loadingPjLienParente">
      <upload-file
        v-model="individu.pjLienParente"
        name="lien-parente"
        pj-name="Justificatif de changement de situation *"
        sub-text="Copie de la page de votre livret de famille faisant état de votre mariage."
        :file-name="lienParenteFileName"
        title="Pièce(s) justificative(s)"
        class="fw-bold"
        required
        :ged-file="gedPjLienParente"
        @delete-file="delete individu.pjLienParente"
        @file-loading="updateFileLoading"
      />
    </amsom-overlay>
  </div>
</template>

<script>
import UploadFile from "@/components/UploadFile.vue";
import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";

export default {
  name: "LocataireSituationFamilialeBlock",

  components: {
    UploadFile,
    AmsomOverlay,
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },

    needPjLienParente: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:modelValue", "conflictLienParenteSituation", "fileLoading"],

  data() {
    return {
      individu: this.modelValue,
      loadingPjLienParente: false,
      gedPjLienParente: null,
    };
  },

  computed: {
    ...mapState(["utilities"]),

    ...mapGetters({
      getNomPrenom: "contrat/getNomPrenom",
      getTypeLocataire: "contrat/getTypeLocataire",
    }),

    isConflictLienParenteSituation() {
      let situationAssociatedToLien =
        this.utilities.associationUnionLienSituation[this.individu.lienParente];
      let situation = this.individu.situationFamiliale;

      let res = false;
      if (
        situationAssociatedToLien &&
        !situationAssociatedToLien.includes(situation)
      ) {
        res = true;
      }

      return res
    },

    lienParenteOptions() {
      if (!this.utilities.lienParente) return null;

      if (this.getTypeLocataire(this.individu) === "occupant") {
        return this.utilities.lienParente.filter((lien) => lien.isForOccupant);
      }

      if (this.getTypeLocataire(this.individu) === "cosignataire") {
        return this.utilities.lienParente.filter((lien) => lien.isForCoSig);
      }

      return this.utilities.lienParente;
    },

    situationFamilialeOptions() {
      return this.utilities.situationFamiliale;
    },

    lienParenteFileName() {
      return (
        this.getNomPrenom(this.individu) +
        " - Changement de situation " +
        moment().format("YYYY-MM-DD")
      );
    },
  },

  watch: {
    individu: {
      handler(value, oldValue) {
        this.$emit("update:modelValue", value);
      },

      deep: true,
    },

    needPjLienParente: function (newValue, oldValue) {
      if (oldValue && !newValue) delete this.individu.pjLienParente;
    },

    loadingPjLienParente(value) {
      this.$emit("fileLoading", value);
    },

    // modelValue: {
    //   handler(value, oldValue) {
    //     this.individu = value;
    //   },

    //   deep: true,
    // },
    "individu.lienParente": function (newValue) {
      const lienParente = this.utilities?.lienParente?.find(
        (l) => l.id === newValue
      );
      if (lienParente && lienParente.isUnion && this.getTypeLocataire(this.individu) === "occupant") {
        this.individu.aCharge = true;
      }

      if(newValue !== "E" && this.getTypeLocataire(this.individu) === "occupant") {
        this.individu.dtgard = false;
        this.individu.dtvis = false;
      }
    },

    isConflictLienParenteSituation: function (newValue) {
      this.$emit("conflictLienParenteSituation", newValue);
    },
  },

  mounted() {
    if(this.isConflictLienParenteSituation)
      this.$emit("conflictLienParenteSituation", this.isConflictLienParenteSituation);

    if (this.individu.justificatifs) {
      let pj = this.individu.justificatifs.find(
        (pj) => pj.typeFichier === "changement_situation"
      );
      if (pj) {
        this.loadingPjLienParente = true;
        this.$store.dispatch("ged/loadPdfById", pj.id).then((data) => {
          this.gedPjLienParente = new window.File_native([data], pj.libelle, {
            type: data.type,
          });
          this.loadingPjLienParente = false;
        });
      }
    }
  },

  methods: {
    updateFileLoading(value) {
      this.$emit("fileLoading", value);
    },
  },
};
</script>
