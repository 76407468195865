import apiErrorParser from "@/js/apiErrorParser"
import axiosOverlay from "@/services/axiosOverlay"
import moment from "moment-timezone"
import {sortArray} from "@/js/array.js";

const getDefaultState = () => {
    return {
        loading: 0,
        lignesCompte: null,
        encaissements: undefined,
    }
}

const state = getDefaultState()

const getters = {
    /**
     * Retourne le solde du compte à la date du jour
     * @returns {number}
     * */
    getSolde(state){
        if(state.lignesCompte === null) return null

        return state.lignesCompte?.filter(item=>item.dateLigne<=moment().unix())[0]?.solde ?? 0
    },
    getEncaissements(state){
        return state.encaissements
    }

}
const mutations = {
    setLoading: (state, val) => {
        if (val === '+')
            state.loading++

        if (val === '-')
            state.loading--
    },
    setLignesCompte: (state, lignesCompte) => {
        lignesCompte = sortArray(sortArray(lignesCompte, 'idContrat', false), 'id', false)

        lignesCompte = lignesCompte.map(ligne => {
            if (typeof(ligne.credit) === 'string') ligne.credit = parseFloat(ligne.credit.replace(',', '.'))
            if (typeof(ligne.debit) === 'string') ligne.debit = parseFloat(ligne.debit.replace(',', '.'))
            if (typeof(ligne.solde) === 'string') ligne.solde = parseFloat(ligne.solde.replace(',', '.'))
            ligne.montant = ligne.credit ? ligne.credit : ligne.debit*-1
            return ligne
        })
        state.lignesCompte = lignesCompte
    },
    setEncaissements: (state, encaissements) => {
        encaissements = sortArray(encaissements, 'id', false)

        encaissements = encaissements.map(encaissement => {
            if (typeof(encaissement.montant) === 'string') encaissement.montant = parseFloat(encaissement.montant.replace(',', '.'))
            return encaissement
        })
        state.encaissements = encaissements
    },
    unSetEncaissements: (state) => {
        state.encaissements = undefined
    },
    resetState: (state) => {
        Object.assign(state, getDefaultState())
    }
}
const actions = {
    /**
     * Charge les lignes de compte du contrat contratId
     * @param contratId : id du contrat
     * @returns {Promise<unknown>} : Promise résolue si les lignes de compte sont chargées
     */
    loadLigneByContratId: ({state, commit}, contratId) => {
        // si les lignes du contrat contratId sont déjà chargées, on ne recharge pas
        if (state.lignesCompte?.filter(ligne => ligne.idContrat === contratId).length>0) return Promise.resolve()
        // sinon on charge les lignes de compte du contrat contratId
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/ligne_comptes?idContrat=' + contratId
            const config = {
                url: url,
                method: 'GET'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setLignesCompte', response.data);
                    commit('setLoading', '-');
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    commit('setLoading', '-');
                    reject(apiErrorParser(error))
                })
        })
    },

    /**
     * Charge les encaissements en cours du contrat contratId
     * @param contratId : id du contrat
     * @returns {Promise<unknown>} : Promise résolue si les encaissements sont chargés
     */
    loadEncaissementsByContratId: ({state, commit}, contratId) => {
        // si les encaissements sont déjà chargés, on ne recharge pas
        if (state.encaissements && state.encaissements.filter(encaissement => encaissement.idContrat === contratId).length>0) return Promise.resolve()
        // sinon on charge les lignes de compte du contrat contratId
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/encaissements?idContrat=' + contratId
            const config = {
                url: url,
                method: 'GET'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setEncaissements', response.data);
                    commit('setLoading', '-');
                    resolve()
                })
                .catch(function (error) {
                    commit('setLoading', '-');
                    reject(apiErrorParser(error))
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
