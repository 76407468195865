<template>
  <div
    v-for="individu in availableIndividus"
    :id="'input-group-revenus-annuel-' + individu.id"
    :key="individu.id"
    class="col-12"
    role="group"
  >
    <label
      :for="'input-revenus-annuel-' + individu.id"
      class="form-label d-block"
    >
      Revenus annuels de
      {{ getNomPrenom(individu) }}
      <span
        v-if="isRevenusRequired(individu)"
        class="text-danger"
      >*</span>

      <font-awesome-icon
        icon="fa-solid fa-circle-question"
        class="px-1 hoverable"
        @click="showInfoRA = true"
      />
    </label>

    <div class="position-relative">
      <input
        :id="'input-revenus-annuel-' + individu.id"
        v-model="fiscalite.revenusIndividus[individu.id]"
        class="form-control"
        type="number"
        pattern="\d+"
        inputmode="numeric"
        min="0"
        max="999999"
        :placeholder="!isRevenusRequired(individu) ? '' : 'Renseignement obligatoire'"
        :required="isRevenusRequired(individu)"
        @keypress="disableDot"
      >

      <div
        class="position-absolute end-0 top-0 h-100 d-flex align-items-center px-2 text-dark"
      >
        <span>€</span>
      </div>
    </div>
  </div>

  <div class="col-12">
    <amsom-overlay :loading="loadingPjRfr"> 
      <upload-file
        v-model="fiscalite.pjRfr"
        :ged-file="gedPjRfr"
        name="rfr"
        pj-name="Avis d'imposition"
        :sub-text="
          'Copies des avis d\'imposition ' +
            previousYear +
            ' où apparaissent les membres de votre foyer.'
        "
        :file-name="rfrFileName"
        title="Pièce(s) justificative(s)"
        class="fw-bold"
        required
        @delete-file="delete fiscalite.pjRfr"
        @file-loading="updateFileLoading"
      />
    </amsom-overlay>
  </div>

  <div
    id="input-group-rfr"
    class="col-12"
    role="group"
  >
    <label
      for="input-rfr"
      class="form-label d-block"
    >
      Revenu Fiscal de Référence
      <font-awesome-icon
        icon="fa-solid fa-circle-question"
        class="px-1 hoverable"
        @click="showInfoRFR = true"
      />
    </label>

    <div class="position-relative">
      <input
        id="input-rfr"
        v-model="rfr"
        class="form-control"
        name="fiscalite-rfr"
        type="number"
        pattern="\d+"
        inputmode="numeric"
        min="0"
        max="999999"
        required
        @keypress="disableDot"
      >

      <div
        class="position-absolute end-0 top-0 h-100 d-flex align-items-center px-2 text-dark"
      >
        <span>€</span>
      </div>
    </div>
  </div>

  <amsom-modal
    v-if="showInfoRFR"
    id="modal-infos-RFR"
    close-option
    @close="showInfoRFR = false"
  >
    <h4>Où trouver mon revenu fiscal de référence?</h4>

    <p>
      Vous trouverez votre revenu fiscal de référence sur la première page de
      votre avis d'imposition <strong>{{ previousYear }}</strong>.
    </p>

    <img
      :src="helperRfr"
      alt="Aide sur l'avis d'imposition"
      class="img-fluid rounded"
    >

    <p>
      Si vous avez plusieurs avis d'imposition, vous devez
      <strong>additionner les montants</strong> de vos revenus fiscaux de
      référence avant de les saisir.
    </p>
  </amsom-modal>

  <amsom-modal
    v-if="showInfoRA"
    id="modal-infos-RA"
    close-option
    @close="showInfoRA = false"
  >
    <h4>Où trouver mon revenu annuel imposable?</h4>

    <p>
      Vous trouverez votre revenu annuel imposable à la ligne "Salaires,
      pensions, rentes nets" de votre avis d'imposition
      <strong>{{ previousYear }}</strong>.
    </p>

    <img
      :src="helperRa"
      alt="Aide sur l'avis d'imposition"
      class="img-fluid rounded"
    >
  </amsom-modal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import helperRa from "@/assets/images/helper_ra.png";
import moment from "moment-timezone";
import UploadFile from "@/components/UploadFile.vue";
import helperRfr from "@/assets/images/helper_rfr.jpg";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";
import AmsomModal from "@/components/UI/AmsomModal.vue";

export default {
  name: "FiscaliteFormBlock",
  components: { UploadFile, AmsomOverlay, AmsomModal },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },

    availableIndividus: {
      type: Array,
      required: true,
    },
  },

  emits: ["update:modelValue", "fileLoading"],

  data() {
    return {
      showInputFile: false,
      showInfoRA: false,
      showInfoRFR: false,
      helperRa: helperRa,
      helperRfr: helperRfr,

      fiscalite: this.modelValue,
      rfrSaisie: null,


      loadingPjRfr: false,
      gedPjRfr: null,
    };
  },

  computed: {
    ...mapState(["enquete"]),

    ...mapGetters({
      getNomPrenom: "enquete/getNomPrenom",
      isMajeur: "enquete/isMajeur",
    }),

    enqueteContrat() {
      return this.enquete.enqueteContrat;
    },

    rfrFileName() {
      return (
        this.enqueteContrat.idContrat +
        " - Avis d'imposition " +
        moment.unix(this.enqueteContrat.dateEffet)
          .subtract(1, "years")
          .year()
      );
    },

    previousYear() {
      if (!this.enqueteContrat.dateEffet) {
        return null;
      }
      return moment
        .unix(this.enqueteContrat.dateEffet)
        .subtract(1, "years")
        .format("YYYY");
    },

    rfrCalcule() {
      let rfr = 0;
      let individus = this.fiscalite.revenusIndividus

      // individus = individus.filter((individu) => individu.aCharge !== false || individu.lienParente !== 'E' )

      for (const [key, value] of Object.entries(
        individus
      )) {
        rfr += value;
      }

      return rfr;
    },

    rfr: {
      get() {
        return this.fiscalite.rfr;
      },

      set(value) {
        this.rfrSaisie = value;
        this.fiscalite.rfr = value;
      },
    },
  },

  watch: {
    fiscalite: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },

      deep: true,
    },

    rfrCalcule(value) {
      if (!this.rfrSaisie) {
        this.fiscalite.rfr = value;
      }
    },

    loadingPjRfr(value) {
      this.$emit("fileLoading", value);
    },
  },

  mounted() {
    if (this.enqueteContrat.justificatifs) {
      let pj = this.enqueteContrat.justificatifs.find(
        (pj) => pj.typeFichier === "avis_imposition"
      );
      if (pj) {
        this.loadingPjRfr = true;
        this.$store.dispatch("ged/loadPdfById", pj.id).then((data) => {
          this.gedPjRfr = new window.File_native([data], pj.libelle, { type: data.type });
          this.loadingPjRfr = false;
        });
      }
    }
  },

  methods: {
    disableDot(e) {
      if ([".", ",", "e", 'E'].includes(e.key)) {
        e.preventDefault();
      }
    },

    updateFileLoading(value) {
      this.$emit("fileLoading", value);
    },

    isRevenusRequired(individu) {
      let date
      if(!this.enqueteContrat.dateEffet){
        date = moment();
      }else{
        let year = moment.unix(this.enqueteContrat.dateEffet).subtract('2', 'years').year();
        date =  moment('31/12/' + year, 'DD/MM/YYYY')
      }

      return this.isMajeur(individu, date);
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
