<template>
  <div
    class="row gy-3 text-center"
  >
    <div
      class="col-12"
    >
      <div class="card h-100">
        <div class="card-body card-text text-primary">
          <span
            class="fs-4 fw-bold"
          >Avant de répondre à l'enquête, veuillez vous munir des documents suivants :</span>
        </div>
      </div>
    </div>

    <div
      class="col-12 col-md-6"
    >
      <div class="card h-100">
        <div class="card-body card-text text-primary">
          <font-awesome-icon
            icon="fa-regular fa-file-lines"
            class="mb-3"
            style="font-size: 5em"
          />

          <h5 class="mb-3">
            Copie de votre avis d'imposition ou de non imposition
          </h5>

          <p class="m-0 p-0 text-start">
            - La copie de votre avis d'imposition ou non imposition <strong>{{ previousYear }}</strong>

            sur vos revenus <strong>{{ previousYear-1 }}</strong>
          </p>

          <p class="text-start">
            - Les copies des avis d'imposition ou non imposition <strong>{{ previousYear }}</strong>

            sur les revenus <strong>{{ previousYear-1 }}</strong> de toutes les personnes majeures occupant le logement et qui ne figurent pas sur votre avis
          </p>
        </div>
      </div>
    </div>

    <div
      class="col-12 col-md-6"
    >
      <div class="card h-100">
        <div class="card-body card-text text-primary">
          <font-awesome-icon
            icon="fa-solid fa-shield"
            class="mb-3"
            style="font-size: 5em"
          />

          <h5 class="mb-3">
            Copie de la carte d'invalidité ou CMI
          </h5>

          <span>Carte invalidité ou Carte Mobilité Inclusion portant la mention "invalidité" de chaque personne occupant le logement</span>
        </div>
      </div>
    </div>

    <div
      class="col-12 col-md-6"
    >
      <div class="card h-100">
        <div class="card-body card-text text-primary">
          <font-awesome-icon
            icon="fa-solid fa-users"
            class="mb-3"
            style="font-size: 5em"
          />

          <h5 class="mb-3">
            Changement de situation ?
          </h5>

          <span>En cas de changement récent de la situation de votre foyer. Fournir la copie des pièces justificatives telles que l'acte de mariage, l'acte de naissance, l'acte de décès...</span>
        </div>
      </div>
    </div>

    <div
      v-if="enqueteContrat?.type==='SLS'"
      class="col-12 col-md-6"
    >
      <div class="card h-100">
        <div class="card-body card-text text-primary">
          <font-awesome-icon
            icon="fa-solid fa-percent"
            class="mb-3"
            style="font-size: 5em"
          />

          <h5 class="mb-3">
            Vos revenus ont baissé d'au moins 10 % ?
          </h5>

          <span>Si vos revenus des 12 derniers mois ont baissé d'au moins 10% par rapport à l'année d'avant, vous devez fournir également toutes pièces justificatives pour les 12 derniers mois, telles que le bulletin de salaires, l'attestation du Pôle Emploi ou le justificatif de pension de retraite...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import moment from "moment-timezone";
import { unixToDateString } from "@/js/text";

export default {
  name: "PreparationBlock",

  data() {
    return {
      showMentions:false
    }
  },

  computed: {
    ...mapState(['enquete']),

    enqueteContrat(){
      return this.enquete.enqueteContrat;
    },

    previousYear(){
      if(!this.enqueteContrat.dateEffet){
        return null;
      }
      return moment.unix(this.enqueteContrat.dateEffet).subtract(1, 'years').format('YYYY');
    }
  },

  methods: {
    unixToDateString
  }
}
</script>
