<template>
  <div class="card mb-2">
    <div class="card-body">
      <div class="card-text d-flex justify-content-between align-items-center">
        <h5 class="m-0 p-0">
          {{ $t('assuranceFeedPanel.cardTitle') }}
        </h5>

        <div class="d-flex justify-content-between">
          <div
            v-for="n in maxSteps"
            :key="n"
            class="rounded-5 me-1"
            :class="[(n <= currentStep ? 'bg-primary' : 'bg-gray-300')]"
            :style="[(n === currentStep ? 'width:25px' : 'width:10px'), 'height:10px', 'transition:0.3s']"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-2">
    <div class="card-body">
      <Transition
        name="slide-fade"
        mode="out-in"
      >
        <amsom-overlay
          :loading="loading"
          class="w-100"
        >
          <div
            v-if="currentStep === 1"
            class="card-text"
          >
            <p class="fs-5 fw-bold p-0 m-0">
              {{ currentStep }}. {{ $t('assuranceFeedPanel.step1') }}
            </p>

            <p class="text-muted">
              {{ $t('common.allFieldsRequired') }}
            </p>

            <form @submit.prevent="currentStep++">
              <label
                class="form-label d-block"
                for="assureurName"
              >
                {{ $t('assuranceFeedPanel.assureurName') }}
              </label>

              <autocomplete-assureur
                id="assureurName"
                v-model="newAssurance"
                required
                class="mb-3"
              />

              <label 
                for="police-number-input" 
                class="form-label d-block"
              >
                {{ $t('assuranceFeedPanel.policeNumber') }}
              </label>

              <input 
                id="police-number-input" 
                v-model.trim="newAssurance.police"
                class="form-control mb-3" 
                type="text" 
                :placeholder="$t('assuranceFeedPanel.policeNumberPlaceHolder')"
                required 
                autocomplete="off" 
                aria-required="true"
              >

              <label
                for="date-debut-input"
                class="me-2 pb-2"
              >{{ $t('assurance.validite') }}</label>

              <div class="d-flex justify-content-start align-items-center">
                <label 
                  for="date-debut-input"
                  class="mx-1"
                >
                  {{ $t('assuranceFeedPanel.from') }}
                </label>

                <input
                  id="date-debut-input"
                  v-model="dateDebAssurance"
                  :max="maxStartDate"
                  type="date"
                  locale="fr"
                  class="form-control"
                  required
                  placeholder="JJ/MM/AAAA"
                >

                <label
                  for="date-fin-input"
                  class="mx-1"
                > {{ $t('assuranceFeedPanel.to') }} </label>

                <input
                  id="date-fin-input"
                  v-model="dateFinAssurance"
                  class="form-control"
                  :min="minEndDate"
                  :max="maxEndDate"
                  locale="fr"
                  type="date"
                  required
                  placeholder="JJ/MM/AAAA"
                >
              </div>

              <upload-file
                v-model="newAssurance.pj"
                class="mt-3"
                name="uploadAssurancePj"
                :pj-name="$t('assuranceFeedPanel.attachement')"
                :sub-text="$t('assuranceFeedPanel.attachementDescription')"
                :file-name="fileName"
                :title="$t('common.pj')"
                required
                @delete-file="delete newAssurance.pj"
                @file-loading="updateFileLoading"
              />

              <div class="text-end mt-4">
                <button
                  id="go-to-verification-btn"
                  class="btn btn-amsom-green mt-2 rounded-5"
                  role="button"
                  type="submit"
                  :disabled="fileLoading"
                >
                  {{ $t('common.nextStep') }}
                  <font-awesome-icon
                    :icon="['fa-solid', 'fa-arrow-right']"
                    class="ms-1"
                  />
                </button>
              </div>
            </form>
          </div>

          <div
            v-else-if="currentStep === 2"
            id="cp-assurance-feed-panel-step-2"
            class="card-text"
          >
            <p class="fs-5 fw-bold p-0 m-0">
              {{ currentStep }}. {{ $t('assuranceFeedPanel.step2') }}
            </p>

            <div>
              <div class="text-secondary d-flex mb-2">
                <strong class="text-primary me-1">{{ $t('assurance.assureur') }} : </strong>
                {{ newAssurance.assureurLibelle }}
              </div>

              <div class="text-secondary d-flex mb-2">
                <strong class="text-primary me-1">{{ $t('assurance.police') }} : </strong>
                {{ newAssurance.police }}
              </div>

              <div class="text-secondary d-flex mb-2">
                <strong class="text-primary me-1">{{ $t('assurance.validite') }} : </strong>

                <span> {{ unixToDateString(newAssurance.dateDebut) }} au {{
                  unixToDateString(newAssurance.dateFin)
                }}</span>
              </div>

              <h5 class="text-decoration-underline mt-4">
                {{ $t('common.pj') }}
              </h5>

              <div
                class="hoverable flex-grow-1"
                @click="openFile(newAssurance.pj.file)"
              >
                <font-awesome-icon icon="fa-solid fa-solid fa-paperclip" />
                {{ newAssurance.pj.name }}
              </div>
            </div>

            <div class="d-flex justify-content-between mt-4">
              <button
                id="cp-assurance-corriger-btn"
                class="btn btn-secondary rounded-5"
                type="button"
                role="button"
                @click="currentStep--"
              >
                <font-awesome-icon
                  icon="fa-solid fa-arrow-left"
                  class="me-2"
                />
                {{ $t('common.correct') }}
              </button>
                  
              <button
                id="confirmation-btn"
                class="btn btn-amsom-green rounded-5"
                type="button"
                role="button"
                :disabled="assurance.loading > 0"
                @click="updateAssurance"
              >
                {{ $t('common.validate') }}
                <font-awesome-icon
                  :icon="['fa-solid', 'fa-arrow-right']"
                  class="ms-1"
                />
              </button>
            </div>
          </div>

          <div 
            v-else
            class="card-text d-flex flex-column"
          >
            <p class="fs-5 fw-bold p-0 m-0">
              {{ currentStep }}.  {{ $t('assuranceFeedPanel.step3') }}
            </p>

            <div class="text-center mt-3">
              <p class="fs-5 text-amsom-green">
                <font-awesome-icon
                  icon="fa-solid fa-check"
                  class="me-2"
                />
                {{ $t('assuranceFeedPanel.sendInfo') }}
              </p>

              <p class="text-muted">
                {{ $t('assuranceFeedPanel.userRedirectInfo') }}
              </p>
            
              <span
                class="spinner-border text-primary"
                role="status"
              />
            </div>
          </div>
        </amsom-overlay>
      </Transition>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import moment from 'moment';
import UploadFile from "@/components/UploadFile.vue";
import AutocompleteAssureur from './AutocompleteAssureur.vue';
import {toast} from "vue3-toastify";
import {unixToDateString, unixToDateTimeString} from "../../js/text.js";
import fileUtils from "@/js/fileUtils.js";
import {unixIsAfter} from "@/js/dateUtils.js";
import AmsomOverlay from '../UI/AmsomOverlay.vue';

export default {
  name: 'AssuranceFeedPanel',
  components: {AutocompleteAssureur, UploadFile, AmsomOverlay},

  data() {
    return {
      currentStep: 1,
      maxSteps: 3,
      newAssurance: {
        assureurId: null,
        police: null,
        dateDebut: null,
        dateFin: null,
        pj: null,
      },

      fileLoading: false
    }
  },

  computed: {
    ...mapState(['assurance', "contrat"]),
    ...mapGetters({'selectedContratId': 'user/getSelectedContrat'}),

    loading() {
      return this.assurance?.loading > 0 || this.contrat?.loading > 0 || this.assurance.assurance === undefined;
    },

    fileName() {
      return 'Assurance - ' + unixToDateTimeString(this.newAssurance.dateDebut, 'MMM YYYY') + ' à ' + unixToDateTimeString(this.newAssurance.dateFin, "MMM YYYY");
    },

    currentContratInfos() {
      return this.contrat.currentContratInfos
    },

    minEndDate() {
      if (this.loading)
        return null

      if (!this.currentContratInfos)
        return null

      const now = moment().unix();
      const dateEntree = this.currentContratInfos.dateEntree;
      const debutAssurance = this.newAssurance.dateDebut;

      if ( now >  debutAssurance  && now > dateEntree){
        return moment().format('YYYY-MM-DD')
      }

      if ( debutAssurance > dateEntree)
        return moment.unix().format('YYYY-MM-DD')

      if (debutAssurance)
        return moment.unix(debutAssurance).format('YYYY-MM-DD')

      return moment.unix(dateEntree).format('YYYY-MM-DD')
    },

    maxEndDate() {
      return moment().add(3, 'y').format('YYYY-MM-DD')
    },

    maxStartDate() {
      return moment().add(2, 'y').format('YYYY-MM-DD')
    },

    dateFinAssurance: {
      get() {
        let date = this.newAssurance?.dateFin;
        return date ? moment.unix(date).format('YYYY-MM-DD') : null
      },

      set(value) {
        let data = value ? moment(value, 'YYYY-MM-DD').unix().toString() : null
        this.newAssurance.dateFin = data
      }
    },

    dateDebAssurance: {
      get() {
        let date = this.newAssurance?.dateDebut;
        return date ? moment.unix(date).format('YYYY-MM-DD') : null
      },

      set(value) {
        let data = value ? moment(value, 'YYYY-MM-DD').unix().toString() : null
        this.newAssurance.dateDebut = data
      }
    }
  },

  watch: {
    "selectedContratId": function () {
      this.loadDataOfContrat()
    },
  },

  mounted() {
    this.loadDataOfContrat()
  },

  methods: {
    unixToDateString,

    updateFileLoading(value) {
      this.fileLoading = value
    },

    loadDataOfContrat() {
      if (this.selectedContratId) {
        this.$store.dispatch('assurance/getAssuranceByContratId', {'idContrat': this.selectedContratId}).then(() => {
          // Si l'assurance existe déjà, on la copie dans le formulaire
          if (this.assurance.assurance) {
            // Copie de l'assurance du contrat et formatage des dates au format YYYY-MM-DD
            this.newAssurance = {...this.assurance.assurance};
            let endCalculatedAssurance = moment.unix(this.newAssurance.dateFin).add(1, 'y').unix();
            if(unixIsAfter(endCalculatedAssurance)){
              this.newAssurance.dateDebut = moment.unix(this.newAssurance.dateDebut).add(1, 'y').unix();
              this.newAssurance.dateFin = endCalculatedAssurance;
            }
            else{
              this.newAssurance.dateDebut = moment().unix();
              this.newAssurance.dateFin = moment().add(1, 'y').unix();
            }
          }
        });
      }
    },

    openFile(file) {
      if (!file) {
        alert('Aucun fichier')
        return
      }

      fileUtils.openFileBase64(file)
    },

    updateAssurance() {
      let idContrat = this.selectedContratId
      // On met à jour l'assurance du contrat via l'API
      this.$store.dispatch('assurance/updateAssurance',
                           {
                             idContrat: idContrat,
                             data: this.newAssurance
                           }
      ).then(() => {
        this.currentStep++
        // Enfin on redirige l'utilisateur vers la page de consultation de l'assurance
        setTimeout(() => {
          this.$router.push({name: 'assurance'});
        }, 2000)
        // Puis on recharge les datas d'assurance
        // this.$store.dispatch('assurance/getAssuranceByContratId', {idContrat: idContrat, force: true})
        //   .then(() => {
        //     this.currentStep++
        //     // Enfin on redirige l'utilisateur vers la page de consultation de l'assurance
        //     setTimeout(() => {
        //       this.$router.push({name: 'assurance'});
        //     }, 2000)
        //   });
      })
        .catch((error) => {
          toast.error(error)
          console.log(error)
        });
    }
  }
}
</script>
