<template>
  <div
    id="cp-assurance-info-panel"
    class="card"
  >
    <div class="card-body card-text">
      <h5>{{ $t("assurance.titre") }}</h5>

      <p v-if="assurance === undefined">
        <amsom-skeleton :config="[8, 10, 6]" />
      </p>

      <div v-else-if="assurance === null">
        <strong class="text-danger text-center d-block mb-2">{{
          $t("assurance.notAssured")
        }}</strong>
      </div>

      <div v-else>
        <strong
          v-if="!assurance?.valide"
          class="text-danger d-block mb-2"
        >{{ $t("assurance.expired") }}</strong>

        <strong
          v-else-if="unixIsAfter(assurance?.dateDebut)"
          class="text-secondary"
        >{{ $t("assurance.comming") }}
          {{ unixToDateString(assurance?.dateDebut, "DD/MM") }}</strong>

        <div class="text-secondary d-flex mb-2">
          <strong class="text-primary me-1">{{ $t("assurance.assureur") }} :
          </strong>
          {{ assurance.assureurLibelle }}
        </div>

        <div class="text-secondary d-flex mb-2">
          <strong class="text-primary me-1">{{ $t("assurance.police") }} n° :
          </strong>
          {{ assurance.police }}
        </div>

        <div class="text-secondary d-flex mb-2">
          <strong class="text-primary me-1">{{ $t("assurance.validite") }} :
          </strong>

          <span :class="{ 'text-danger': !assurance.valide }">
            {{ unixToDateString(assurance.dateDebut) }} au
            {{ unixToDateString(assurance.dateFin) }}</span>
        </div>
      </div>

      <div
        v-if="assurance?.enCours"
        id="confirmation-update-message"
        class="bg-warning-light py-3 w-100 text-center rounded"
      >
        {{ $t("assurance.validationInProgress", {
          dateDebut: unixToDateString(assurance.enCours.dateDebut),
          dateFin: unixToDateString(assurance.enCours.dateFin)
        }) }}
      </div>

      <div
        v-else
        class="text-end"
      >
        <router-link
          id="btn-start-update-assurance"
          class="btn btn-amsom-green mt-3 rounded-5"
          :to="{ name: 'assurer' }"
        >
          {{ $t("common.update") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { unixToDateString } from "../../js/text.js";
import AmsomSkeleton from "../UI/AmsomSkeleton.vue";
import { unixIsAfter } from "../../js/dateUtils.js";
export default {
  name: "AssuranceInfoPanel",
  components: { AmsomSkeleton },

  props: {
    assurance: {
      type: Object,
      required: false,
      default: undefined,
    },
  },

  methods: {
    unixToDateString,
    unixIsAfter,
  },
};
</script>
