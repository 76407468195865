import apiErrorParser from "@/js/apiErrorParser"
import axiosOverlay from "@/services/axiosOverlay"
import moment from "moment-timezone";
import store from "@/store/index.js";
import { sortArray } from "@/js/array.js";

function sortByDateNaisse(individus, asc = true) {
    return sortArray(individus, 'dateNaissance', asc)
}
const getDefaultState = () => {
    return {
        currentContratInfos: null,
        individusCurrentContrat: null,
        prelevementInfos: null,
        loading: 0
    }
}

const state = getDefaultState()
const getters = {
    isLocataireAlreadyExited: (state, getters) => (locataire) => {
        if (!locataire)
            return false

        let date = getters['getLocataireDateSortie'](locataire)
        if (!date) {
            return false
        }

        return date < moment().unix()
    },
    getLocataireDateSortie: (state) => (locataire) => {
        if (locataire.fin)
            return locataire.fin

        return state.currentContratInfos?.dateSortie
    },
    getLocataireById: (state) => (id) => {
        if (!state.individusCurrentContrat)
            return null

        return state.individusCurrentContrat.find(locataire => locataire.id === id);
    },
    getLocataireByIdIndividu: (state) => (id) => {
        if (!state.individusCurrentContrat)
            return null

        return state.individusCurrentContrat.find(locataire => locataire.idIndividu === id);
    },
    getSignatairePrincipal: (state, getters) => {
        let locataires = getters.getAvailableLocataires

        if (!locataires)
            return null

        return locataires.find(locataire => locataire.type === 'signataire');
    },

    getAvailableLocataires: (state) => {
        if (!state.currentContratInfos)
            return null

        let locataires = state.individusCurrentContrat?.filter((locataire) => {
            let dateComparatif = state.currentContratInfos.dateSortie ? moment.unix(state.currentContratInfos.dateSortie) : moment()

            return !locataire.fin || (locataire.fin > dateComparatif.subtract(1, 'year').unix())
        })

        // Trier les locataires selon le type et, en cas d'égalité de type, selon la propriété "debut"
        locataires.sort((a, b) => {
            // Tri par type : signataire, cosignataire, occupant
            const typeOrder = {
                signataire: 1,
                cosignataire: 2,
                occupant: 3
            }


            if (typeOrder[a.type] !== typeOrder[b.type]) {
                return typeOrder[a.type] - typeOrder[b.type];
            } else {
                // Si les types sont identiques, tri par la propriété "debut"
                if(a.debut !== b.debut) {
                    return a.debut - b.debut;
                }
                return a.idIndividu - b.idIndividu;
            }
        });

        return locataires;
    },

    getAge: () => (data) => {
        let dateNaissance = data?.dateNaissance;

        if (!dateNaissance)
            return 0

        return moment().diff(moment.unix(dateNaissance), 'years');
    },

    isMajeur: () => (data) => {
        return store.getters['contrat/getAge'](data) >= 18
    },

    getNomPrenom: () => (data) => {
        return (
            data.prenom +
            " " +
            (data.nom ?? data.nomNaissance)
        ).toUpperCase();
    },

    getTypeLocataire: () => (data) => {
        if (!data?.type) return "occupant";

        return data.type.toLowerCase();
    },
}
const mutations = {
    setLoading: (state, val) => {
        if (val === '+')
            state.loading++

        if (val === '-')
            state.loading--
    },
    setCurrentContratInfos: (state, contrat) => {
        if (contrat.dateSortie && contrat.dateSortie !== null) {
            // création d'un booléen pour savoir si le contrat est terminé en fonction de la dateSortie
            contrat.hasExpired = moment().unix() > contrat.dateSortie
        }
        state.currentContratInfos = contrat
    },
    setPrelevementInfos: (state, prelevementInfos) => {
        state.prelevementInfos = prelevementInfos
    },
    setIndividusCurrentContrat: (state, individus) => {
        let signataire = sortByDateNaisse(individus.filter((individu) => individu.type === 'signataire'))
        let cosignataire = sortByDateNaisse(individus.filter((individu) => individu.type === 'cosignataire'))
        let occupant = sortByDateNaisse(individus.filter((individu) => individu.type === 'occupant'))
        let autres = sortByDateNaisse(individus.filter((individu) => individu.type !== 'signataire' && individu.type !== 'cosignataire' && individu.type !== 'occupant'))

        state.individusCurrentContrat = signataire.concat(cosignataire).concat(occupant).concat(autres)
    },
    resetState: (state) => {
        Object.assign(state, getDefaultState())
    }
}
const actions = {
    loadContratInfosById: ({ commit }, id) => {
        return new Promise((resolve, reject) => {
            commit('setLoading', '+');
            let url = process.env.VUE_APP_API_URL + '/contrat_infos/' + id
            const config = {
                url: url,
                method: 'GET'
            }

            axiosOverlay(config)
                .then(function (response) {
                    commit('setCurrentContratInfos', response.data)
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    loadIndividuInfosByContrat: ({ commit }, idContrat) => {
        return new Promise((resolve, reject) => {
            commit('setLoading', '+');
            let url = process.env.VUE_APP_API_URL + '/locataire_individu_infos?idContrat=' + idContrat
            const config = {
                url: url,
                method: 'GET'
            }

            axiosOverlay(config)
                .then(function (response) {
                    commit('setIndividusCurrentContrat', response.data)
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    loadPrelevementInfos: ({ commit }, idContrat) => {
        commit('setLoading', '+');

        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/contrat_prelevements?id=' + idContrat
            const config = {
                url: url,
                method: 'GET'
            }

            axiosOverlay(config)
                .then(function (response) {
                    commit('setPrelevementInfos', response.data)
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },

    updateRib: ({ state, commit }, data) => {
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/update_rib'
            const config = {
                url: url,
                data: data,
                method: 'POST'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setLoading', '-');
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    commit('setLoading', '-');
                    reject(apiErrorParser(error))
                })
        })
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
