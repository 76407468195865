<template>
  <div 
    class="container-fluid py-2 px-4"
  >
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="row">
          <div
            id="home-widget-assurance"
            class="col-12 col-md-6 mb-3"
          >
            <assurance-widget style="min-height: 20vh;" />
          </div>

          <div class="col-12 col-md-6 mb-3">
            <cb-widget style="min-height: 20vh;" />
          </div>

          <div class="col-12 mb-3">
            <lignes-compte-panel
              style="max-height: 55vh;"
              :show-top-bar="false"
              :show-pagination="false"
              :show-details="false"
            />
          </div>
        </div>
      </div>

      <div class="col-12 order-first order-lg-last col-lg-4">
        <div class="row">
          <div class="col-12 mb-3">
            <sollicitation-list style="max-height: 30vh;" />
          </div>

          <div class="col-12 order-first order-lg-last">
            <communication-ciblee-widget />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommunicationCibleeWidget from '@/components/HomePage/CommunicationCibleeWidget.vue'
import SollicitationList from '@/components/SollicitationPage/SollicitationList.vue'
import AssuranceWidget from '@/components/AssurancePage/AssuranceWidget.vue'
import CbWidget from '@/components/ComptePage/CbWidget.vue'
import LignesComptePanel from '@/components/ComptePage/LignesComptePanel.vue'


export default {
  name: 'HomePage',
  components: { SollicitationList, AssuranceWidget, CbWidget, LignesComptePanel, CommunicationCibleeWidget },

  mounted() {
    this.$store.commit('topbar/setBreadcrumb', null)
  }
}
</script>
