import { jsPDF } from "jspdf";
import mime from "mime";

const resizeImage = function (settings) {
    let file = settings.file;
    let maxWidth = settings?.maxWidth ?? 1080;
    let maxHeigth = settings?.maxHeigth ?? 1920;
    let reader = new window.FileReader_native();
    let image = new window.Image();
    let canvas = document.createElement('canvas');

    let resize = function () {
        let width = image.width;
        let height = image.height;

        while (width > maxWidth || height > maxHeigth) {
            if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
            }
            if (height > maxHeigth) {
                width *= maxHeigth / height;
                height = maxHeigth;
            }
        }

        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        return canvas.toDataURL('image/jpeg');
    };

    return new Promise(function (ok, no) {
        if (!file.type.match(/image.*/)) {
            no(new Error("Not an image"));
            alert("Le fichier n'est pas pris en charge, veuillez mettre une image");
            return;
        }
        reader.onload = function (readerEvent) {
            image.onload = function () {
                return ok(resize());
            };
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
};

const isImage = async function (file) {
    //Verification de l'extension
    const acceptImage = [
        // "image/bmp",
        // "image/gif",
        "image/jpeg",
        "image/png"
    ]
    if (!acceptImage.includes(file['type']))
        return false

    //Verification de la signature
    const signatures = [
        [0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A], // PNG
        [0xFF, 0xD8, 0xFF], // JPEG
    ];
    const maxSignatureLength = Math.max(
        ...signatures.map(signature => signature.length),
    );
    const arrayBuffer = await file.slice(0, maxSignatureLength).arrayBuffer();
    const uint8Array = new window.Uint8Array(arrayBuffer);

    return signatures.some(
        signature => signature.every((byte, index) => byte === uint8Array[index]),
    );
}

const isPdf = async function (file) {
    //Verification de l'extension
    if (!file['type'].includes('pdf'))
        return false


    //Verification de la signature du PDF
    const signature = [0x25, 0x50, 0x44, 0x46, 0x2D] // PDF
    const arrayBuffer = await file.slice(0, signature.length).arrayBuffer();
    const uint8Array = new window.Uint8Array(arrayBuffer);
    if (!signature.every((byte, index) => byte === uint8Array[index]))
        return false

    //Verification de la signature du HEADER
    return new Promise(function (resolve, reject) {
        let fileReader = new window.FileReader_native();

        fileReader.onload = function (e) {
            let data = e.target.result.substr(0, 8);
            let regexPDF = new RegExp("%PDF-1.[0-7]");
            if (data.match(regexPDF)) {
                return resolve(true)
            } else {
                return resolve(false)
            }
        };

        fileReader.readAsText(file);
    })
}

const handleFiles = async function (files) {
    let data = []

    for (let file of files) {
        let res = await handleFile(file)

        data.push(res)
    }

    return data
}

const handleFile = async function (file, defaultExtention = null) {
    let extension = defaultExtention ?? file.name.slice(file.name.lastIndexOf('.'))
    let finalFile
    if (await isImage(file)) {
        const config = {
            file: file,
            maxWidth: 1000,
            maxHeigth: 1900,
        };
        finalFile = await resizeImage(config)
    } else {
        finalFile = await inputFileToBase64(file);
    }
    finalFile = finalFile.split(',')[1]

    let data = {
        "file": finalFile,
        "extension": extension,
        "type": file['type']
    }

    return data
}

const imageToPDF = function (imageDataURL) {
    const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px"
    });

    pdf.addImage(imageDataURL, 0, 0);

    return new window.File_native([pdf.output("blob")], "myDoc.pdf", {
        type: "application/pdf",
    })
}

const inputFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new window.FileReader_native();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

const base64ToBlob = function (base64, type = 'application/pdf') {
    // Convert base64 to blob
    let byteString = atob(base64);
    let arrayBuffer = new window.ArrayBuffer(byteString.length);
    let int8Array = new window.Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
    }
    let blob = new window.Blob([int8Array], { type: type });
    return blob;
}

const localFileToBlob = (filePath) => {
    return fetch(filePath)
        .then((r) => r.blob())
        .then((blobFile) => {
            return blobFile;
        });
}

const downloadFile = function (data, title) {
    // Download a file thanks to url and title passed in parameter with crodova if exist
    if (window.cordova && cordova.platformId !== "browser") {
        cordovaDownload(title, data, data.type);
    }
    else {
        let url = URL.createObjectURL(data);

        let a = document.createElement('a');
        a.href = url;
        a.download = title;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}

function cordovaDownload (filename, data, mimeType) {
    var blob = new window.Blob([data], {
        type: mimeType
    });
    let extension = mime.getExtension(mimeType);

    if (window.cordova && cordova.platformId !== "browser") {
        document.addEventListener("deviceready", function () {
            var storageLocation = "";

            switch (device.platform) {
                case "Android":
                    storageLocation = cordova.file.externalDataDirectory;
                    break;

                case "iOS":
                    storageLocation = cordova.file.documentsDirectory;
                    break;

                default:
                    storageLocation = cordova.file.externalDataDirectory;
                    break;
            }

            var folderPath = storageLocation;

            window.resolveLocalFileSystemURL(
                folderPath,
                function (dir) {
                    dir.getFile(
                        filename + '.' + extension,
                        {
                            create: true
                        },
                        function (file) {
                            file.createWriter(
                                function (fileWriter) {
                                    fileWriter.write(blob);

                                    fileWriter.onwriteend = function () {
                                        cordova.plugins.fileOpener2.open(file.nativeURL, mimeType, {
                                            error: function error(err) {
                                                console.error(err);
                                                alert("Unable to download");
                                            },
                                            success: function success() {
                                                console.log("success with opening the file");
                                            }
                                        });
                                    };

                                    fileWriter.onerror = function (err) {
                                        alert("Unable to download");
                                        console.error(err);
                                    };
                                },
                                function (err) {
                                    // failed
                                    alert("Unable to download");
                                    console.error(err);
                                }
                            );
                        },
                        function (err) {
                            alert("Unable to download");
                            console.error(err);
                        }
                    );
                },
                function (err) {
                    alert("Unable to download");
                    console.error(err);
                }
            );
        });
    } else {
        saveAs(blob, filename);
    }
}

const openFileBase64 = function (base64, type = 'application/pdf') {
    // Open a file thanks to base64 and type passed in parameter
    let blob = base64ToBlob(base64, type);
    openFileBlob(blob);
}

const openFileBlob = function (blob) {
    // Open a file thanks to blob passed in parameter with cordova if exist
    let url = URL.createObjectURL(blob);
    if (window.cordova && cordova.platformId !== "browser") {
        cordovaDownload("Fichier", blob, blob.type);
    } else {
        window.open(url, '_blank');
    }
}
export default {
    inputFileToBase64,
    resizeImage,
    handleFiles,
    handleFile,
    imageToPDF,
    isImage,
    isPdf,
    downloadFile,
    localFileToBlob,
    openFileBase64,
    openFileBlob,
    base64ToBlob
}
