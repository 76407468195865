import {jwtDecode} from "jwt-decode";
import axiosOverlay from "@/services/axiosOverlay";
import apiErrorParser from "@/js/apiErrorParser";
import store from "@/store/index.js";
import {toast} from "vue3-toastify";
import {sortArray} from "@/js/array.js";

const role_hierarchy = {
    "ROLE_ADMIN": ["ROLE_USER"]
}

const getDefaultState = () => {
    return {
        loading: 0,
        token: '',
        locale: '',
        contrats:null
    }
}

const state = getDefaultState()

const getters = {
    isLogged: (state) => {
        if (state.token) {
            const payload = jwtDecode(state.token)
            if (payload.exp >= Math.floor(Date.now() / 1000)) // Token non expiré
                return true
            else
                console.log("Token expiré")
        }

        return false;
    },
    decodedPayloadToken: (state) => {
        if (!state.token)
            return null

        return jwtDecode(state.token);
    },
    roles: (state, getters) => {
        state;

        const payload = getters.decodedPayloadToken

        if (!payload)
            return null

        return (payload.roles.global ?? []).concat(payload.roles.bob ?? [])
    },
    codeIndividu: (state, getters) => {
        state;

        const payload = getters.decodedPayloadToken

        if (!payload?.individu)
            return null

        return payload.individu
    },
    isGranted: (getters) => (askedRole) => {
        if(!getters.isLogged)
            return false

        if (!getters.roles)
            return false

        if (getters.roles.includes(askedRole))
            return true

        let res = false
        getters.roles.forEach(role => {
            if ((role_hierarchy[role] && role_hierarchy[role].includes(askedRole))) {
                res = true
            }
        })

        return res
    },
    getUsername:(state, getters)=>{
        return getters.decodedPayloadToken?.username;
    },
    getContrats:(state)=>{
        return  (state.contrats !== null) ?  state.contrats.map(c=>c.idContrat):null;
    },
    getSelectedContrat:(state)=>{
        return (state.contrats !== null) ? state.contrats.find(c=> c.selected===true).idContrat:null;
    },
    getLocataireInfo: (state, getters) => {
        if (!getters.codeIndividu)
            return null

        return store.getters['contrat/getLocataireByIdIndividu'](getters.codeIndividu);
    },
}

const mutations = {
    setLoading: (state, val) => {
        if(val === '+')
            state.loading++

        if(val === '-')
            state.loading--
    },
    setToken: (state, token) => {
        state.token = token
    },
    setLocale: (state, locale) => {
        state.locale = locale
    },
    setContrat:(state, contratPayload) =>{

        if (contratPayload){
            // Ajout d'une propriété "selected"
            contratPayload.map(ct=>{
                ct.selected=false;
            });

            // Gestion des préférences utilisateur
            let preferredContrat = localStorage.getItem('preferredContrat');
            let idx = -1;
            if (localStorage.getItem('preferredContrat'))
                idx = contratPayload.findIndex(contrat=>contrat.idContrat.toString() === preferredContrat.toString());

            if (idx !== -1)// Si il existe un preferredContrat ds le localstore
                // Activation de ce contrat
                contratPayload[idx].selected = true;
            else
                // Activation du premier contrat (contrat le plus récent en 1er)
                contratPayload.sort((a,b)=>a.idContrat<b.idContrat?1:-1)[0].selected=true;

            state.contrats = contratPayload;
        }
        else
            state.contrats = contratPayload;
    },
    setSelectedContrat:(state, contrat)=>{
        localStorage.setItem('preferredContrat', contrat);
        if(state.contrats){
            state.contrats = state.contrats.map(ct=>{
                ct.selected = (ct.idContrat === contrat);
                return ct;
            });
        }
    },

    resetState: (state) => {
        // Merge rather than replace so we don't lose observers
        // https://github.com/vuejs/vuex/issues/1118
        Object.assign(state, getDefaultState())
    }
}

const actions = {
    login: ({commit}, data) => {
        commit('setLoading', '+');

        return new Promise((resolve, reject) => {
            const config = {
                url: process.env.VUE_APP_API_URL + '/login',
                data: data,
                method: 'POST'
            }

            axiosOverlay(config, false)
                .then(function (response) {
                    const token = response.data.token
                    if (!token)
                        reject("Pas de token pour cet utilisateur")

                    commit('setToken', token);

                    store.commit('enquete/resetState')

                    // Chargement des contrats de l'utilisateur connecté
                    store.dispatch('user/loadContratsOfUser')

                    //todo
                    // if (!roles.length)
                    //     reject("L'utilisateur n'a pas accès à l'outil")

                    resolve(token)
                })
                .catch(function (error) {
                    console.log(error)

                    reject(error)
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    tryLogin: ({commit}, data) => {
        commit('setLoading', '+');

        return new Promise((resolve, reject) => {
            const config = {
                url: process.env.VUE_APP_API_URL + '/login',
                data: data,
                method: 'POST'
            }

            axiosOverlay(config, false, true, false)
                .then(function (response) {
                    const token = response.data.token
                    if (!token)
                        reject("Pas de token pour cet utilisateur")

                    commit('setToken', token);

                    //todo
                    // if (!roles.length)
                    //     reject("L'utilisateur n'a pas accès à l'outil")

                    resolve(token)
                })
                .catch(function (error) {
                    reject(error)
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    logout: ({commit}) => {
        commit('setToken', null);
        commit('setContrat', null);
        store.commit('enquete/resetState')
        store.commit('user/resetState')
        store.commit('compte/resetState')
        store.commit('ged/resetState')
        store.commit('contrat/resetState')
        store.commit('assurance/resetState')
        store.commit('compte/resetState')
        store.commit('moduleContrat/resetState')
        store.commit('sollicitation/resetState')


        //todo
        // let secureStorage = new cordova.plugins.SecureStorage(
        //     function() {
        //         secureStorage.remove('credentials', function(err, success) {
        //             if (err) {
        //                 console.log("Erreur lors de la suppression des données : " + err);
        //             } else {
        //                 console.log("Données supprimées avec succès");
        //             }
        //         });
        //     },
        //     function(error) {
        //     },
        //     "amsom_et_moi"
        // );

        toast.info('Vous avez été déconnecté')
    },
    /**
     * Récupération des contrats de l'utilisateur
     */
    loadContratsOfUser: ({commit, getters})=>{
        return new Promise((resolve, reject) => {
            if (!getters.getContrats) // Si les contrats ne sont pas chargés
            {
                commit('setLoading', '+');
                let url = process.env.VUE_APP_API_URL + '/contrat_signataires?idIndividu='+ getters.codeIndividu
                const config = {
                    url: url,
                    method: 'GET'
                }

                axiosOverlay(config)
                    .then(function (response) {
                        commit('setContrat', sortArray(response.data, 'idContrat'));
                        resolve()
                    })
                    .catch(function (error) {
                        console.log(apiErrorParser(error))
                        reject(apiErrorParser(error))
                    })
                    .finally(() => {
                        commit('setLoading', '-');
                    })
            }
            else
                resolve();
        })
    },

    resetPasswordAsking: ({commit}, data) => {
        commit('setLoading', '+');

        return new Promise((resolve, reject) => {
            const config = {
                url: process.env.VUE_APP_API_URL + '/reset_password_request',
                data: data,
                method: 'POST'
            }

            axiosOverlay(config, false)
                .then(function () {
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },

    resetPasswordConfirm: ({commit}, data) => {
        commit('setLoading', '+');

        return new Promise((resolve, reject) => {
            const config = {
                url: process.env.VUE_APP_API_URL + '/reset_password_confirm',
                data: data,
                method: 'POST'
            }

            axiosOverlay(config, false)
                .then(function () {
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    deleteAccount: ({commit}, data) => {
        commit('setLoading', '+');

        return new Promise((resolve, reject) => {
            const config = {
                url: process.env.VUE_APP_API_URL + '/delete_account',
                data: data,
                method: 'DELETE'
            }

            axiosOverlay(config)
                .then(function () {
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    updateLoginRequest: ({commit}, data) => {
        commit('setLoading', '+');

        return new Promise((resolve, reject) => {
            const config = {
                url: process.env.VUE_APP_API_URL + '/update_login_request',
                data: data,
                method: 'POST'
            }

            axiosOverlay(config)
                .then(function () {
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    updateLoginConfirm: ({commit}, data) => {
        commit('setLoading', '+');

        return new Promise((resolve, reject) => {
            const config = {
                url: process.env.VUE_APP_API_URL + '/update_login_confirm',
                data: data,
                method: 'POST'
            }

            axiosOverlay(config, false)
                .then(function () {
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
