<template>
  <tr class="mt-3">
    <td>
      <slot name="libelle">
        {{ name ? name + ':' : '' }}
      </slot>
    </td>

    <td>
      <span
        v-if="waitWhile"
        class="spinner-border text-primary"
        role="status"
      />

      <span
        v-else
        :id="id"
      >
        <span
          class="text-danger"
          :class="[{'text-decoration-line-through': !previousValueIsEmpty}]"
        >
          <slot name="oldValue">
            <template v-if="!forceHideOld && (oldValue !== undefined && oldValue !== newValue)">{{ previousValueIsEmpty ? 'Non renseigné' : oldValue }}</template>
          </slot>
        </span>

        <template v-if="($slots.oldValue || (oldValue !== undefined && oldValue !== newValue)) && newValue !== wordToHide"> &rarr; </template>

        <slot
          v-if="newValue !== wordToHide"
          name="newValue"
        >
          {{ newValue }}
        </slot>
      </span>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ModifiedField',

  props: {
    name: {
      type: String,
      required: false,
      default: ''
    },

    waitWhile: {
      type: Boolean,
      default: false
    },

    oldValue: {
      type: String,
      required: false,
      default: undefined
    },

    newValue: {
      type: String,
      required: false,
      default: undefined
    },

    forceHideOld: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  data() {
    return {
      wordToHide: '|deleted|'
    }
  },

  computed: {
    id() {
      if (!this.name)
        return 'noId'
      return this.name.replace(' ', '-').toLowerCase() + '-field'
    },

    previousValueIsEmpty(){
      return (this.oldValue === '' || this.oldValue === null)
    }
  },
}
</script>
