<template>
  <amsom-overlay :loading="enquete.loading > 0 || loading">
    <div
      class="card"
      :class="[bandeauData.bgColor]"
    >
      <div class="card-text p-3">
        <div class="row justify-content-center justify-content-md-start">
          <div class="col-12 col-md-2 text-center my-auto">
            <font-awesome-icon
              :icon="bandeauData.icon"
              :class="[bandeauData.iconColor]"
              class="fa-2x d-inline-block d-md-none"
            />

            <font-awesome-icon
              :icon="bandeauData.icon"
              :class="[bandeauData.iconColor]"
              class="fa-7x d-none d-md-inline-block"
            />
          </div>

          <div class="col-12 col-md-10 flex-grow-1 text-center text-md-start my-auto text-primary">
            <template
              v-if="!enqueteContrat || !enqueteContrat.etat || isTooEarly"
            >
              <h2 class="my-auto">
                Aucune enquête n'est en cours
              </h2>

              <span class="d-block">Vous serez notifié lorsqu'une nouvelle enquête sera
                disponible</span>
            </template>

            <template
              v-else-if="['V', 'E', 'D', 'S'].includes(enqueteContrat.etat)"
            >
              <h2 class="d-block d-md-none">
                {{ titleEnqueteTel }}
              </h2>

              <h2 class="d-none d-md-block">
                {{ enqueteContrat.nom }}
              </h2>

              <span
                v-if="enqueteContrat.etat === 'E'"
                class="d-block"
              >
                Merci d'avoir répondu à cette enquête, un email récapitulatif a
                été envoyé sur votre adresse mail. Votre dossier va être pris en
                charge par nos équipes pour traitement</span>

              <span
                v-if="enqueteContrat.etat === 'V'"
                class="d-block"
              >
                Merci d'avoir répondu à cette enquête, nos équipes ont bien
                traité et validé votre dossier
              </span>
            </template>

            <template v-else-if="enqueteContrat.etat === 'I'">
              <h2>Votre enquête a été rejetée</h2>

              <span class="d-block">Des informations que vous avez remplies sont erronées</span>
              <!--
                <button
                type="button"
                class="mt-2 d-inline-block btn btn-secondary"
                @click="showCorrection = true"
                >
                <span class="fw-bold">Corriger mes réponses</span>
                <font-awesome-icon
                icon="fa-regular fa-circle-right"
                class="ms-3 my-auto"
                />
                </button> 
              -->
              <div
                v-if="!isExpired && !isTooEarly"
                class="text-center text-md-end"
              >
                <router-link
                  v-if="!isExpired && !isTooEarly"
                  :to="{ name: 'enqueteEntry' }"
                  class="btn btn-danger rounded-5 mt-3"
                >
                  <span class="fw-bold me-2">Corriger mes réponses</span>

                  <font-awesome-icon
                    icon="fa-solid fa-arrow-right"
                  />
                </router-link>
              </div>
            </template>

            <template v-else-if="enqueteContrat.etat === 'N'">
              <h2 class="d-block d-md-none">
                {{ titleEnqueteTel }}
              </h2>

              <h2 class="d-none d-md-block">
                {{ enqueteContrat.nom }}
              </h2>

              <span class="d-block">Cette enquête est obligatoire, vous devez y répondre avant le
                <span
                  class="fs-5 fw-bold text-danger"
                  style="white-space: nowrap;"
                >{{
                  unixToDateString(enqueteContrat.fin, "DD MMMM YYYY")
                }}</span>
                au plus tard</span>

              <div
                v-if="!isExpired && !isTooEarly"
                class="text-center text-md-end"
              >
                <router-link
                  :to="{ name: 'enqueteEntry' }"
                  class="btn btn-danger text-white rounded-5 mt-3"
                >
                  <span class="fw-bold me-2">Je réponds à l'enquête</span>

                  <font-awesome-icon
                    icon="fa-solid fa-arrow-right"
                  />
                </router-link>
              </div>

              <span
                v-else-if="isExpired"
                class="fw-bold fs-5 text-danger"
              >
                Il est trop tard pour saisir cette enquête
              </span>

              <span
                v-else-if="isTooEarly"
                class="fw-bold fs-5 text-danger"
              >
                Il est trop tôt pour saisir cette enquête
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!--
      <amsom-modal
      v-if="showCorrection"
      @close="showCorrection = false"
      >
      <h4>Corriger mes informations</h4>
      <block-correction-pj v-if="showCorrection" />
      </amsom-modal> 
    -->
  </amsom-overlay>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment-timezone";
import { unixToDateString } from "@/js/text";
import AmsomOverlay from "../UI/AmsomOverlay.vue";

export default {
  name: "BandeauBlock",

  components: {
    AmsomOverlay,
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      // showCorrection: false,
    };
  },

  computed: {
    ...mapState(["enquete"]),

    enqueteContrat() {
      return this.enquete.enqueteContrat;
    },

    etatEnquete() {
      return this.enqueteContrat?.etat;
    },

    isTooEarly() {
      return (
        this.enqueteContrat && this.enqueteContrat.debut && this.enqueteContrat.debut > moment().unix()
      );
    },

    isExpired() {
      return (
        this.enqueteContrat &&
        this.enqueteContrat.fin3 &&
        this.enqueteContrat.fin3 < moment().unix()
      );
    },

    titleEnqueteTel() {
      if (this.enqueteContrat.type === "SLS")
        return process.env.VUE_APP_ENQUETE_TITLE_SLS_SMARTPHONE;

      if (this.enqueteContrat.type === "OPS")
        return process.env.VUE_APP_ENQUETE_TITLE_OPS_SMARTPHONE;

      return "";
    },

    bandeauData() {
      if (this.enqueteContrat?.etat === "N") {
        return {
          bgColor: "bg-primary-light",
          icon: "fa-solid fa-circle-info",
          iconColor: "text-primary",
        };
      }

      if (this.enqueteContrat?.etat === "I") {
        return {
          bgColor: "bg-danger-light",
          icon: "fa-solid fa-triangle-exclamation",
          iconColor: "text-danger",
        };
      }

      if (["V", "E", "D", "S"].includes(this.enqueteContrat?.etat)) {
        return {
          bgColor: "bg-amsom-green-light",
          icon: "fa-regular fa-circle-check",
          iconColor: "text-amsom-green",
        };
      }

      // if(!this.enqueteContrat || !this.enqueteContrat.etat || this.isTooEarly)
      return {
        bgColor: "bg-gray",
        icon: "fa-regular fa-circle-check",
        iconColor: "text-primary",
      };
    },
  },

  methods: {
    unixToDateString,
  },
};
</script>
