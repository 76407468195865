<template>
  <div>
    <h3 class="text-center">
      {{ nom }}
    </h3>

    <p>
      Identité du responsable de traitement : OPH DE LA SOMME
    </p>

    <p>
      Conformément à l’article L 442-5 du code de l’habitat et de la construction, l’organisme d’habitations à loyer modéré, OPH DE LA SOMME, traite les données à caractère personnel recueillies à l’occasion de l'enquête {{ definition }} ({{ type }}) en vue de créer des outils d’analyse de l’occupation sociale de leur parc contribuant au système de qualification de l’offre mentionné à l’article L. 441-2-8, à l’élaboration et à la mise en œuvre des orientations en matière d’attributions de logements mentionnées à l’article L. 441-1-5, à l’élaboration des conventions d’utilité sociale prévues à l’article L. 445-1 et du programme local de l’habitat mentionné à l’article L. 302-1, ainsi qu’à l’identification des ménages en situation de précarité énergétique pour l’application de l’article L. 221-1-1 du code de l’énergie.
    </p>

    <p>
      L’OPH DE LA SOMME est autorisé à transmettre les données recueillies rendues anonymes au représentant de l’Etat dans le département et dans la région, à la région, au département, aux établissements publics de coopération intercommunale mentionnés au vingtième alinéa de l’article L. 441-1, aux communes ainsi qu’à l’Union sociale pour l’habitat regroupant les fédérations d’organismes d’habitations à loyer modéré, aux dites fédérations et aux associations régionales d’organismes d’habitations à loyer modéré, à la fédération des entreprises publiques locales, à la société mentionnée à l’article L. 313-1g9, au groupement d’intérêt public mentionné à l’article L. 441-2-1, ainsi qu’aux agences d’urbanisme dès lors que ces agences interviennent dans le cadre d’une étude définie en relation avec une collectivité territoriale ou un groupement de collectivités territoriales. 
    </p>

    <p>
      La loi vous fait obligation de répondre dans le délai d'un mois à compter de l’envoi de notre courrier. A défaut de réponse dans ce délai, vous êtes redevable à l'OPH DE LA SOMME {{ txtPenalite }}, sauf s'il est établi que des difficultés particulières ne vous ont pas permis de répondre.
    </p>

    <p v-if="type=='SLS'">
      Les formulaires d’enquêtes et les documents justifiant la mise en oeuvre du SLS sont conservés pendant quatre ans (articles L. 441-10 et 11 et R. 441-28 du CCH).
    </p>

    <p v-else>
      Les formulaires d’enquêtes sont conservés jusqu’au renouvellement de l’enquête, soit 2 ans pour l’enquête OPS.
    </p>

    <p>    
      Conformément à la Loi Informatique et Libertés du 6 Janvier 1978 modifiée et au Règlement Général Européen sur la Protection des Données Personnelles (UE) n°2016/679 du 27 Avril 2016, vous bénéficiez d'un droit d'accès, de rectification, d'effacement et de portabilité de vos données, ainsi qu'un droit de limitation du traitement et d'opposition au traitement pour motif légitime, dans le cadre permis par le Règlement Général Européen. Vous pouvez exercer ces droits en adressant une demande par écrit avec copie de votre pièce d'identité, par mail à dpo@amsom-habitat.fr, ou à l'adresse suivante :
    </p>

    <p>    
      AMSOM Habitat <br>

      Délégué à la Protection des Données<br>

      1 Rue du Général Frère<br>
      80000 Amiens
    </p>

    <p>    
      En cas de litige, vous disposez également du droit d’introduire une réclamation auprès de la CNIL en vous rendant sur le site www.cnil.fr.
    </p>
  </div>
</template>
  
  <script>
export default {
  name: 'MentionLegaleSLS',
  props: ['type', 'nom'],

  computed: {
    definition(){
      return this.type =='SLS'? 'Supplément de Loyer de Solidarité':'Occupation Sociale';
    },

    txtPenalite(){
      return this.type =='SLS'? 
        'de frais de dossier de 25 euros, et d’un supplément de loyer maximum avec effet rétroactif au 1er janvier de l’année de l’enquête': 
        'd\'une pénalité de 7,62 euros, majorée de 7,62 euros par mois entier de retard';
    },
  },

}
  </script>
  