const isVersionLower = function (version1, version2) {
    // Convertir les versions en tableaux
    const version1Array = version1.split(".");
    const version2Array = version2.split(".");

    // Comparer les versions numériquement
    for (let i = 0; i < version1Array.length; i++) {
        if (version1Array[i] < version2Array[i]) {
            return true;
        } else if (version1Array[i] > version2Array[i]) {
            return false;
        }
    }

    // Les versions sont égales
    return false;
}

export default { isVersionLower }