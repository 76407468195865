<template>
  <div
    id="releve-compte"
    class="card no-scroll-bar overflow-auto"
    @scroll="onScroll"
  >
    <div class="card-body position-relative">
      <div
        class="py-2 sticky-top bg-body d-flex justify-content-between align-items-center"
        style="z-index: 2"
      >
        <h5 class="m-0">
          Mon relevé de compte
        </h5>

        <span
          v-if="!(loading || compte.lignesCompte === null)"
          id="releve-compte-value"
          class="fs-5"
          :class="[solde < 0 ? 'text-danger' : 'text-amsom-green']"
        >
          {{ toCurrencyEUR(solde) }}
        </span>
      </div>

      <div
        v-if="loading || compte.lignesCompte === null"
      >
        <amsom-skeleton :config="[6, 12, 6]" />
      </div>

      <div
        v-else
        id="list-releve-compte"
      >
        <div
          v-if="showTopBar"
          class="input-group my-3 border rounded-3"
        >
          <span
            id="searchbar-addon1"
            class="input-group-text text-gray-500 border-0 bg-gray-200"
          >
            <font-awesome-icon icon="fa-solid fa-search" />
          </span>

          <input
            id="searchbar"
            v-model="searchValue"
            type="search"
            class="form-control bg-gray-200 border-0"
            placeholder="Recherche par mot clé ..."
            aria-label="Rechercher un motif de sollicitation"
            aria-describedby="searchbar-addon1"
            autocomplete="off"
          >
        </div>

        <div
          v-for="itemAtDate in itemsGroupedByDate"
          :key="itemAtDate.date"
          class="position-relative mt-1"
        >
          <span
            class="text-primary text-uppercase border-bottom sticky-top w-100 d-inline-block bg-body"
            style="top: 2.65em; z-index: 1"
          >{{ itemAtDate.date }}</span>

          <div class="bg-body-tertiary rounded-3 pt-2 pb-1">
            <div
              v-for="item in itemAtDate.items"
              :key="item.id"
              role="button"
              class="pb-1  position-relative"
              :class="{ 'focusDetail': item.showDetails }"
              @click="explainLine(item.id)"
            >
              <div
                class="ps-2 d-flex align-items-center"
              >
                <font-awesome-layers class="fa-lg me-3 ms-1 flex-shrink-1">
                  <font-awesome-icon
                    icon="fa-solid fa-circle"
                    transform="grow-16 down-1"
                    class="text-primary"
                  />

                  <font-awesome-icon
                    :icon="['fas', item.icon]"
                    transform="left-2"
                    class="text-light"
                    fixed-width
                  />
                </font-awesome-layers>

                <small class="text-secondary flex-grow-1">{{ item.libelle }}</small>

                <div class="me-1 py-1 my-1">
                  <span
                    v-if="item.montant"
                    :class="[item.montant > 0 ? 'text-amsom-green' : 'text-danger']"
                  >{{ toCurrencyEUR(item.montant) }}</span>
                </div>
              </div>

              <div
                v-if="lineToExplain === item.id"
                class="solde-explanation mx-4 my-2 p-2 text-primary bg-body rounded-3 shadow-sm"
              >
                Mon solde est passé de
                <strong :class="[(item.solde - item.montant) >= 0 ? 'text-amsom-green' : 'text-danger']">{{ toCurrencyEUR(item.solde - item.montant) }} </strong>
                à
                <strong :class="[(item.solde) >= 0 ? 'text-amsom-green' : 'text-danger']">{{ toCurrencyEUR(item.solde ) }} </strong>
                avec cette opération
              </div>
            
              <font-awesome-icon
                v-if="item.montant"
                :icon="['fas', item.id === lineToExplain ? 'angle-up' : 'ellipsis']"
                transform="shrink-4"
                class="position-absolute bottom-0 end-0 me-1 text-muted"
              />
            </div>
          </div>

          <div class="pb-1" />
        </div>

        <a
          v-if="!showOlders && itemsCompte.length> itemsGroupedByDate.length"
          class="mt-4 text-primary text-decoration-underline d-block "
          role="button"
          @click="showOlders = true"
        >
          Voir plus d'opérations
        </a>

        <div
          v-if="linesToDisplay<itemsCompte.length && showOlders"
          class="text-center"
        >
          <span
            class="spinner-border text-primary"
            role="status"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {toCurrencyEUR, unixToDateString, normalize} from "@/js/text.js";
import { unixIsBefore } from "@/js/dateUtils.js";
import AmsomSkeleton from "@/components/UI/AmsomSkeleton.vue";

export default {
  name: "LignesComptePanel",

  components:{
    AmsomSkeleton
  },

  props: {
    showTopBar: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
      searchValue: '',
      linesToDisplay: 20,
      lineToExplain : null,
      showOlders: false,
    }
  },

  computed: {
    ...mapState(['compte']),

    ...mapGetters({
      'solde': 'compte/getSolde',
    }),

    loading(){
      return this.compte.loading>0
    },

    itemsCompte(){
      if (this.compte.lignesCompte === null)
        return []
      if (this.searchValue.length < 2)
        return this.compte.lignesCompte

      // On filtre les éléments recherchés si la barre de recherche est utilisée
      let search = normalize(this.searchValue)
      return this.compte.lignesCompte?.filter(ligne =>
        normalize(ligne.libelle)?.includes(search) ||
        normalize(ligne.montant)?.includes(search) ||
        normalize(ligne.solde)?.includes(search) ||
        normalize(unixToDateString(ligne.dateLigne, 'DD/MM/YYYY'))?.includes(search) ||
        normalize(unixToDateString(ligne.dateLigne, 'dddd DD MMMM YYYY'))?.includes(search)
      ) ?? []
    },

    itemsToDisplay() {
      let items = this.itemsCompte
      return items.slice(0, this.linesToDisplay)

    },

    itemsGroupedByDate(){
      // Regroupe les items par date dans une collection au format [{date: '2021-01-01', items: [{...}, {...}]}, {...
      let items = this.itemsToDisplay?.reduce((acc, item)=>{
        let date = unixToDateString(item.dateLigne, 'dddd DD MMMM YYYY')
        let index = acc.findIndex((item)=>item.date===date)
        if(index===-1){
          acc.push({date, items: [item]})
        }else{
          acc[index].items.push(item)
        }
        return acc
      }, [])
      if (this.showOlders){
        return items
      }
      // On n'affiche que 5 jours
      return items.slice(0, 5)
    },
  },

  methods: {
    unixToDateString,
    toCurrencyEUR,
    unixIsBefore,

    onScroll(e){
      let el = e.target
      if(el.scrollTop + el.clientHeight +50 >= el.scrollHeight){
        this.linesToDisplay += 10
      }
    },

    explainLine(id){
      if(this.lineToExplain === id){
        this.lineToExplain = null
      }else{
        this.lineToExplain = id
      }
    }
  }}
</script>

<style scoped>
#searchbar:hover, #searchbar:focus {
    background-color: var(--secondary) !important;
    font-size: 1.25em;
    transition: 0.15s ease-in-out;
}
.solde-explanation{
  font-size: 0.8em;
}

.focusDetail{
  font-size: 1.1em;
}
</style>
