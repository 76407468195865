<template>
  <div class="container-fluid py-2">
    <div class="row g-4">
      <div class=" col-12 col-lg-8">
        <div
          v-if="currentSollicitation"
          id="en-tete"
          class="card card-body mb-2 p-2 p-sm-4"
        >
          <font-awesome-icon
            role="button"
            :icon="['fas', 'arrow-left']"
            size="2x"
            class="me-auto text-start pb-3"
            @click="$router.push({ name: 'sollicitation' })"
          />
          <!-- En-tête -->
          <div class="row justify-content-between mx-0 mx-md-1 mx-lg-3 gy-4">
            <div class="col-12 text-center">
              <amsom-skeleton
                v-if="loading"
                :config="[5, 3]"
                centered 
              />

              <div v-else>
                <h5 class="m-0 p-0">
                  {{ $t('sollicitation.sollicitation') }}
                  <strong class="text-amsom-green">{{
                    currentSollicitation.numero
                  }}</strong>
                </h5>
          
                <div class="d-inline-flex align-items-center">
                  <font-awesome-icon
                    :icon="['fas', currentSollicitation.icone]"
                    class="text-light bg-primary rounded-circle p-2 me-1"
                    style="width: 0.9em; height: 0.9em;"
                  />
                  {{ currentSollicitation.motif }}
                </div>
              </div>

              <hr class="w-75 mx-auto mb-2">
            </div>

            <!-- Suivis -->
            <amsom-skeleton
              v-if="loading"
              :config="[3, 3, 12]"
            />

            <div
              v-else-if="currentSuivis.length === 0"
              class="text-center text-muted my-3"
            >
              {{ $t('sollicitation.suivi.noSuivi') }}
            </div>

            <amsom-vertical-stepper
              v-else
              :steps="suivisFormatted"
              @click-on-item="cancelMeeting"
            />

            <form
              v-if="showSuiviForm"
              id="suivi-form"
              @submit.prevent="createSuivi"
            >
              <div class="col-12">
                <label
                  for="input-description-sollicitation"
                  class="mb-1 form-label fw-bold text-primary"
                >
                  {{ $t('sollicitation.message') }}
                </label>

                <textarea
                  id="input-description-sollicitation"
                  v-model="msg"
                  class="form-control rounded-3"
                  :placeholder="$t('sollicitation.messagePlaceHolder') + ' ...'"
                  rows="4"
                  :disabled="loading"
                  maxlength="2000"
                  required
                />

                <small class="text-muted text-start ms-2">
                  {{ (msg?.length ?? 0) }} /2000
                </small>
              </div>

              <div
                v-if="!loading"
                class="col-12"
              >
                <upload-file
                  ref="inputFile"
                  v-model="pj"
                  class="text-start"
                  name="sollicitation"
                  :pj-name="$t('sollicitation.attachement')"
                  :label="$t('sollicitation.attachementDescription')"
                  file-name="fichier-locataire"
                  :title="$t('sollicitation.attachementUploaded')"
                  @delete-file="onDeleteFile"
                  @file-loading="updateFileLoading"
                />
              </div>

              <div
                v-if="!loading"
                class="col-12 text-end align-self-center"
              >
                <button
                  id="submit-suivi-btn"
                  class="btn btn-amsom-green mt-4 rounded-5"
                  type="submit"
                >
                  {{ $t('common.validate') }}
                  <font-awesome-icon
                    :icon="['fa-solid', 'fa-arrow-right']"
                    class="ms-1"
                  />
                </button>
              
                <!-- Désactivation suite à une décision de l'atelier DAP -->
                <!--
                  <b-button
                  v-if="false && currentSollicitation.canBeRelance"
                  id="relance-suivi-btn"
                  type="button"
                  variant="primary"
                  class="ms-2"
                  :disabled="loading || loadingFile"
                  @click="relance"
                  >
                  Relancer le responsable
                  </b-button> 
                -->
              </div>
            </form>

            <div
              v-else
              class="text-center my-3"
            >
              <span class="text-muted">Sollicitation clôturée
                {{
                  currentSollicitation.lastMessage
                    ? "depuis " + currentSollicitation.lastMessage
                    : ""
                }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!loading"
        class="col-12 col-lg-4"
      >
        <document-list
          :titre="$t('sollicitation.attachement')"
          :categories="['Sollicitation']"
          :sollicitation-searched="currentSollicitation?.id"
          style="max-height: 40vh"
          class="mb-2"
          :length="-1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { unixToDateTimeString, unixToDateString } from "@/js/text.js";
import { afterEndOfWeek, unixIsBefore } from "@/js/dateUtils.js";
import pageUtils from "@/js/pageUtils.js";
import UploadFile from "@/components/UploadFile.vue";
import DocumentList from "@/components/DocumentList.vue";
import { toast } from "vue3-toastify";
import  AmsomSkeleton  from "@/components/UI/AmsomSkeleton.vue";
import AmsomVerticalStepper from "@/components/UI/AmsomVerticalStepper.vue";

export default {
  name: "SollicitationPage",
  components: { DocumentList, UploadFile, AmsomSkeleton, AmsomVerticalStepper, AmsomVerticalStepper },

  data() {
    return {
      msg: null,
      defaultMsgPjAdd: "Veuillez trouver ci-joint un document justificatif",
      pj: null,

      loadingFile: false,
    };
  },

  computed: {
    ...mapState(["sollicitation"]),
    ...mapGetters({ selectedContratId: "user/getSelectedContrat" }),

    currentSollicitation() {
      return this.$store.getters["sollicitation/getSollicitationById"](
        this.$route.params.sollicitationId
      );
    },

    currentSuivis() {
      return this.$store.getters["sollicitation/getSuivisBySollicitationId"](
        this.$route.params.sollicitationId
      );
    },

    suivisFormatted(){
      return this.currentSuivis.map(suivi => {
        // RDV
        if (suivi.dateRdvDebut){
          let date = 'le '+this.unixToDateString(suivi.dateRdvDebut)
          if (suivi.dateRdvDebut !== suivi.dateRdvFin){
            date = date + ' entre '+this.unixToTimeString(suivi.dateRdvDebut)+' et '+this.unixToTimeString(suivi.dateRdvFin)
          }
          else if(
            this.unixToTimeString(suivi.dateRdvDebut) !==
            this.unixToTimeString(-3600)
          ){
            // Comparaison permet de vérifier que l'heure est différente de 0h00 et donc que l'heure est renseignée
            date = date + ' à '+this.unixToTimeString(suivi.dateRdvDebut)
          }

          return {
            id:suivi.id,
            title: suivi.auteur,
            titleColor: 'primary',
            subtitle: date,
            subtitleColor: 'muted',
            description: suivi.description + (afterEndOfWeek(suivi.dateRdvDebut) || this.unixIsBefore(suivi.dateRdvDebut) ? '' : ' (' +this.$t('sollicitation.suivi.cancelRdvImpossible')+')'),
            descriptionColor: 'secondary',
            icon: this.unixIsBefore(suivi.dateRdvDebut) ? ['far','calendar-check']: ['far', 'calendar'],
            iconColor: 'amsom-green',
            actionBtn: afterEndOfWeek(suivi.dateRdvDebut) ? 
              {
                color: 'danger',
                text: this.$t('sollicitation.suivi.cancelRdv'),
              } 
              : null
          }

        }
        return {
          id: suivi.id,
          title: suivi.auteur,
          titleColor: 'primary',
          subtitle: 'Le ' + this.unixToDateTimeString(suivi.dateSuivi),
          subtitleColor: 'muted',
          description: suivi.dateRdvDebut ? `Le ${this.unixToDateString(suivi.dateRdvDebut)} entre ${this.unixToTimeString(suivi.dateRdvDebut)} et ${this.unixToTimeString(suivi.dateRdvFin)}` : suivi.description,
          descriptionColor: 'secondary',
          icon: (suivi.auteur === 'Locataire') ? 'fa-user' : 'fa-building',
          iconColor: (suivi.auteur === 'Locataire') ? 'secondary' : 'primary',
        }
      })
    },

    loading() {
      return this.sollicitation.loading > 0;
    },

    showSuiviForm() {
      return this.currentSollicitation?.actif;
    },
  },

  watch: {
    selectedContratId: function () {
      this.loadDataOfSollicitation();
    },

    pj: function (newValue) {
      if (!this.msg && newValue) {
        this.msg = this.defaultMsgPjAdd;
      }
    },
  },

  created() {
    this.loadDataOfSollicitation();
  },

  mounted() {
    this.$store.commit("topbar/setBreadcrumb", [
      { icon: "fa-solid fa-house", to: { name: "home" } },
      { text: "Sollicitations", to: { name: "sollicitation" } },
      { text: "Suivre", active: true },
    ]);
  },

  methods: {
    unixIsBefore,
    unixToDateTimeString,
    unixToDateString,
    afterEndOfWeek,

    unixToTimeString(unix) {
      return unixToDateString(unix, "HH:mm");
    },

    updateFileLoading(value) {
      this.loadingFile = value;
    },

    onDeleteFile() {
      this.pj = null;
      if (this.msg === this.defaultMsgPjAdd) this.msg = null;
    },

    // Antoine : A conserver pour une éventuelle réactivation
    // relance() {
    //   this.$store
    //     .dispatch(
    //       "sollicitation/relanceSollicitation",
    //       this.currentSollicitation.id
    //     )
    //     .then(() => {
    //       this.$store.dispatch(
    //         "sollicitation/getSuivisBySollicitationId",
    //         this.currentSollicitation.id
    //       );
    //       if (this.showSuiviForm) {
    //         pageUtils.autoScroll("suivi-form");
    //       }
    //     })
    //     .catch((error) => {
    //       toast.error(error);
    //     });
    // },

    loadDataOfSollicitation() {
      if (this.selectedContratId) {
        this.$store
          .dispatch(
            "sollicitation/getSollicitationById",
            this.$route.params.sollicitationId
          )
          .then(() => {
            this.$store.dispatch(
              "sollicitation/getSuivisBySollicitationId",
              this.currentSollicitation.id
            );
          });
      }
    },

    createSuivi() {
      this.$store
        .dispatch("sollicitation/createSuivi", {
          idSollicitation: this.currentSollicitation.id,
          msg: this.msg,
          pj: this.pj,
        })
        .then(() => {
          this.msg = null;
          this.$store.dispatch(
            "ged/loadGedSollicitationByContratId",
            this.selectedContratId
          );
          if (this.showSuiviForm) {
            pageUtils.autoScroll("suivi-form");
          }

          this.$refs.inputFile.deletePj();
        })
        .catch((error) => {
          toast.error(error);
        });
    },

    cancelMeeting(idSuivi) {
      if (confirm(this.$t('sollicitation.suivi.cancelRdvConfirmation'))){
        const suivi = this.currentSuivis.find((suivi) => suivi.id === idSuivi);
        this.$store
          .dispatch("sollicitation/cancelMeeting", suivi)
          .then(() => {
            this.$store.dispatch(
              "sollicitation/getSuivisBySollicitationId",
              this.currentSollicitation.id
            );
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    },
  },
};
</script>
