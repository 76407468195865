<template>
  <div class="text-center">
    <img
      :src="amsomEtMoiLogo"
      alt="Image représentant le logo de l'application AMSOM&Moi"
      style="max-height: 100px"
      class="m-2 d-inline-block"
    >
  </div>

  <template v-if="maintenanceMode === 'update'">
    <template v-if="platform === 'ios' || platform === 'android'">
      <h1 class="text-amsom-green text-center">
        Une maintenance pouvant prendre jusqu'à 48h est en cours, d'ici là une
        mise à jour sera disponible sur
        {{ platform === "ios" ? "l'AppStore" : "le PlayStore" }}
      </h1>

      <h6 class="text-center text-primary">
        En attendant, vous pouvez accéder au site
        <a
          href="#"
          class="text-center text-secondary text-decoration-underline"
          @click.prevent="goToWebSite"
        >client.amsom-habitat.fr</a>
      </h6>

      <span
        v-if="platform === 'ios'"
        @click="goToAppStore"
      >Mettre à jour</span>

      <span
        v-else-if="platform === 'android'"
        @click="goToPlayStore"
      >Mettre à jour</span>
    </template>

    <template v-else>
      <h1 class="text-info text-center">
        Vous n'êtes pas sur la dernière version du site AMSOM&MOI, nous avons
        apporté des nouveauté à celui-ci
      </h1>

      <h6 class="text-center text-primary">
        Rafraichissez votre page et si le problème persiste, tentez de vider
        votre cache
      </h6>
    </template>
  </template>

  <template v-else>
    <h1 class="text-amsom-green text-center mt-3">
      Votre espace client est en cours de maintenance et sera bientôt disponible.
    </h1>
  </template>

  <div class="text-center mt-5">
    <button
      class="btn btn-secondary"
      @click="refreshPage"
    >
      Réésayer
    </button>
  </div>
</template>

<script>
import amsomEtMoiLogo from "@/assets/logo.svg"
export default {
  name: "MaintenanceBlock",

  props: {
    maintenanceMode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      amsomEtMoiLogo: amsomEtMoiLogo,
    };
  },

  computed: {
    platform() {
      return process.env.CORDOVA_PLATFORM;
    },
  },

  methods: {
    goToPlayStore() {
      if (window.cordova) {
        cordova.InAppBrowser.open(
          "https://play.google.com/store/apps/details?id=fr.amsomhabitat.amsometmoi",
          "_system"
        );
      } else {
        window.open(
          "https://play.google.com/store/apps/details?id=fr.amsomhabitat.amsometmoi",
          "_system"
        );
      }
    },

    goToWebSite() {
      if (window.cordova) {
        cordova.InAppBrowser.open("https://client.amsom-habitat.fr", "_system");
      } else {
        window.open("https://client.amsom-habitat.fr", "_system");
      }
    },

    goToAppStore() {
      if (window.cordova) {
        cordova.InAppBrowser.open(
          "https://apps.apple.com/fr/app/amsom-moi/id6449927674",
          "_system"
        );
      } else {
        window.open(
          "https://apps.apple.com/fr/app/amsom-moi/id6449927674",
          "_system"
        );
      }
    },

    refreshPage() {
      window.location.reload();
    },
  }
};
</script>
