<template>
  <widget-block :bg-image="bgImage">
    <template #title>
      <h5>
        Mes paiements
      </h5>
    </template>

    <template #content>
      <span
        class="card-text text-primary"
      >
        Payez sans vous déplacer et en toute simplicité !
      </span>
    </template>

    <template #btn-action>
      <router-link
        class="btn btn-amsom-green rounded-5 mt-3 mb-2 me-1"
        :to="{name : 'paiement'}"
      >
        Payer en ligne
        <font-awesome-icon
          icon="fa-solid fa-arrow-right"
          class="ms-1"
        />        
      </router-link>
    </template>
  </widget-block>
</template>

<script>
import WidgetBlock from '../WidgetBlock.vue';
import bgImage from '@/assets/images/homePage/cb-widget.jpg';
export default{
  name: 'CbWidget',

  components: {
    WidgetBlock,
  },

  data() {
    return {
      bgImage: bgImage,
    }
  },
}
</script>
