import store from "@/store";
import axiosOverlay from "@/services/axiosOverlay.js";
import apiErrorParser from "@/js/apiErrorParser.js";

const getDefaultState = () => {
    return {
        mode: 'view',
        editedLocataire: null,
        selectedLocataireId: null,
        loading: 0
    }
}

const state = getDefaultState()

const getters = {
    getCurrentLocataire: (state) => {
        if (state.selectedLocataireId === null)
            return null
        return store.getters["contrat/getLocataireById"](state.selectedLocataireId)
    }
}

const mutations = {
    setLoading: (state, val) => {
        if (val === '+')
            state.loading++

        if (val === '-')
            state.loading--
    },
    setMode: (state, val) => {
        state.mode = val
    },
    setEditedLocataire: (state, val) => {
        state.editedLocataire = val
    },
    setSelectedLocataireId: (state, val) => {
        state.selectedLocataireId = val
    }
}

const actions = {
    updateIndividuContrat: ({commit, dispatch}, data) => {
        return new Promise((resolve, reject) => {
            commit('setLoading', '+');
            let url = process.env.VUE_APP_API_URL + '/contrat/update_individu'
            const config = {
                url: url,
                method: 'POST',
                data: data
            }

            axiosOverlay(config)
                .then(function (response) {
                    let res =  response.data.data
                    commit('setEditedLocataire', res)
                    commit('setSelectedLocataireId',res.idIndividuContrat)
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    confirmModificationIndividuContrat: ({commit}, data) => {
        return new Promise((resolve, reject) => {
            commit('setLoading', '+');
            let url = process.env.VUE_APP_API_URL + '/contrat/validate_modification'
            const config = {
                url: url,
                method: 'POST',
                data: data
            }

            axiosOverlay(config)
                .then(function (response) {
                    commit('setEditedLocataire', null)
                    resolve()
                })
                .catch(function (error) {
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    cancelModificationIndividuContrat: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            commit('setLoading', '+');
            let url = process.env.VUE_APP_API_URL + '/contrat/cancel_modification'
            const config = {
                url: url,
                method: 'POST',
                data: payload.data
            }

            axiosOverlay(config)
                .then(function (response) {
                    if(payload?.deleteEditedLocataire)
                        commit('setEditedLocataire', null)

                    resolve()
                })
                .catch(function (error) {
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    exitIndividuContrat: ({commit}, data) => {
        return new Promise((resolve, reject) => {
            commit('setLoading', '+');
            let url = process.env.VUE_APP_API_URL + '/contrat/exit_individu'
            const config = {
                url: url,
                method: 'POST',
                data: data
            }

            axiosOverlay(config)
                .then(function (response) {
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    loadModificationsIndividuContrat: ({commit}, individu) => {
        return new Promise((resolve, reject) => {
            commit('setLoading', '+');
            let url = process.env.VUE_APP_API_URL + '/locataire_individu_modifications?'
            if (!individu.idIndividu){
                url += 'idContrat='+ individu.idContrat
                url += individu.nom ?'&nom='+ individu.nom :''
                url += individu.nomNaissance ?'&nomNaissance='+ individu.nomNaissance :''
                url += individu.prenom ?'&prenom='+ individu.prenom :''
                url += individu.dateNaissance ?'&dateNaissance='+ individu.dateNaissance :''
            }
            else{
                url += 'idIndividu=' + individu.idIndividu
            }
            const config = {
                url: url,
                method: 'GET'
            }

            axiosOverlay(config)
                .then(function (response) {
                    let modifications = response.data.find((modification) => modification.etat === 'N' || modification.etat === 'I') ?? null
                    commit('setEditedLocataire', modifications)
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
