<template>
  <div
    class="card"
    :class="[{'border border-danger': currentRejets?.length > 0}]"
  >
    <div class="position-absolute start-0 top-0  translate-middle-y text-center w-100">
      <span
        v-if="currentRejets?.length > 0"
                   
        class="fs-7 text-danger bg-danger-light rounded rounded-pill px-2 py-1 border border-1 border-danger"
      >
        {{ currentRejets?.length }} Erreur{{ currentRejets?.length > 1 ? 's' : '' }} détectée{{ currentRejets?.length > 1 ? 's' : '' }}
      </span>
    </div>

    <div class="card-body card-text text-primary">
      <div class="d-flex justify-content-between align-items-top mb-2">
        <h5 class="m-0 p-0">
          <font-awesome-icon icon="fa-solid fa-users" /> Fiscalité du foyer
        </h5>

        <div class="m-0 p-0">
          <a
            target="_blank"
            href="https://www.impots.gouv.fr/accueil"
            class="d-flex flex-column align-items-center"
          >
            <img
              :src="marianne"
              width="50"
              alt="Marianne"
              class="img-fluid rounded"
            >

            <p class="p-0 m-0">
              <span class="d-none d-lg-inline">Mon avis sur </span>impots.gouv
            </p>
          </a>
        </div>
      </div>

      <div class="row gy-3">
        <div class="col-12">
          Veuillez renseigner le montant des revenus annuels imposables des
          membres de votre foyer et joindre les avis d'imposition ou de non
          imposition pour l'année <strong>{{ previousYear }}</strong>
          sur les
          revenus <strong>{{ previousYear - 1 }}</strong> du foyer.
        </div>

        <fiscalite-form-block
          v-model="fiscalite"
          :available-individus="availableIndividus"
          @file-loading="updateFileLoading"
        />

        <div class="col-12">
          <p>
            Le revenu fiscal de référence est calculé sur la base des revenus
            annuels saisis pour chacun des membres du foyer.
            <strong>Merci de vérifier que cette valeur correspond à la somme des
              revenus fiscaux de référence qui apparaissent sur vos avis
              d'imposition</strong>.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import marianne from "@/assets/images/marianne.png";
import FiscaliteFormBlock from "@/components/EnqueteEntryPage/DataBlocks/FiscaliteFormBlock.vue";
import moment from "moment-timezone";

export default {
  name: "FiscaliteRevenusBlock",
  components: { FiscaliteFormBlock },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },

    availableIndividus: {
      type: Array,
      required: true,
    },
  },

  emits: ["update:modelValue", "fileLoading"],

  data() {
    return {
      marianne: marianne,

      fiscalite: this.modelValue,
    };
  },

  computed: {
    ...mapState(["enquete"]),

    ...mapGetters({
      getRejetsGrouped: "enquete/getRejetsGrouped",
    }),

    currentRejets() {
      if(!this.getRejetsGrouped || !this.getRejetsGrouped['contrat']) return null
      
      return this.getRejetsGrouped['contrat'].filter(rejet => rejet.libelleJustificatif === "Avis d'imposition")
    },

    enqueteContrat() {
      return this.enquete.enqueteContrat;
    },

    previousYear() {
      if (!this.enqueteContrat.dateEffet) {
        return null;
      }
      return moment
        .unix(this.enqueteContrat.dateEffet)
        .subtract(1, "years")
        .format("YYYY");
    }
  },

  watch: {
    fiscalite: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },

      deep: true,
    },
  },

  methods: {
    updateFileLoading(value) {
      this.$emit("fileLoading", value);
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
