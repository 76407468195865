const state = {
    breadcrumb: false
}

const getters = {
}

const mutations = {
    setBreadcrumb: (state, breadcrumb) => {
        state.breadcrumb = breadcrumb
    }
}

const actions = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
