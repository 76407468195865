import apiErrorParser from "@/js/apiErrorParser"
import axiosOverlay from "@/services/axiosOverlay"

const getDefaultState = () => {
    return {
        loading: 0,
        modules: [],
    }
}

const state = getDefaultState()

const getters = {
    getMainModule: (state) => {
        if (state.modules.length === 0) return null
        if (state.modules.length === 1) return state.modules[0]
        return state.modules[0] // A voir si il n'existe pas une manière plus appropriée pour récupérer le module principal
    },
}
const mutations = {
    setLoading: (state, val) => {
        if (val === '+')
            state.loading++

        if (val === '-')
            state.loading--
    },
    setModules: (state, modules) => {
        // on filtre les modules actifs
        // state.modules = modules.filter(module => module.dateDebut < moment().unix() && (!module.dateFin || module.dateFin > moment().unix()))
        // state.modules = modules.filter(module => module.dateDebut < moment().unix())
        // on filtre les modules inactifs
        // state.modulesInactifs = modules.filter(module => !state.modules.includes(module))
        state.modules = modules
    },
    resetState: (state) => {
        Object.assign(state, getDefaultState())
    }
}
const actions = {
    getModuleByContratId: ({state, commit}, contratId) => {
        // si le module du contrat contratId est déjà chargée, on ne recharge pas
        if (state.module) return Promise.resolve()
        // sinon on charge l'module du contrat contratId
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/module_contrats?idContrat='+contratId
            const config = {
                url: url,
                method: 'GET'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setModules', response.data);
                    commit('setLoading', '-');
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    commit('setLoading', '-');
                    reject(apiErrorParser(error))
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
