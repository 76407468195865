<template>
  <amsom-overlay :loading="fileLoading">
    <div class="position-relative">
      <div
        v-if="!modelValue?.file"
        :id="'input-group-pj-' + name"
        role="group"
      >
        <label
          :id="'label-pj-' + name"
          :for="'input-pj-' + name"
          role="button"
          class="m-0 px-1 py-2 d-block text-center text-secondary rounded-4 border-2 pj-label"
          style="border-color: var(--bs-gray-400);"
          :class="{ 'border border-solid shadow': fileDragged }"
          @drop.prevent.stop="addPj"
          @drag.prevent.stop=""
          @dragstart.prevent.stop="fileDragged = true"
          @dragend.prevent.stop="fileDragged = false"
          @dragover.prevent.stop="fileDragged = true"
          @dragenter.prevent.stop="fileDragged = true"
          @dragleave.prevent.stop="fileDragged = false"
        >
          <div class>
            <font-awesome-icon
              :icon="['fas', 'cloud-arrow-up']"
              size="3x"
              :beat-fade="fileDragged"
            />
          </div>

          <div class=" flex-grow-1">
            <div class="fs-6 fw-bold">
              {{ label ?? pjName }}
            </div>

            <small
              class="text-primary form-text fst-italic"
              style="font-size: smaller;"
            >
              {{ subText }}
            </small>

            <hr class="mx-auto w-50 m-0 mb-1">

            <small
              class=" d-block text-muted"
              style="font-size: 0.8em;"
            >
              <u>Glissez</u>

              vos fichiers ici ou <u>cliquez</u> pour les sélectionner
            </small>
          </div>

          <input
            :id="'input-pj-' + name"
            class="form-control"
            type="file"
            accept="image/png, image/jpeg, application/pdf"
            multiple
            :required="required"
            :name="'pj' + name"
            @change="addPj"
          >
        </label>
      </div>

      <div
        v-else
        class="w-100"
      >
        <h5
          v-if="title"
          class="text-decoration-underline"
        >
          {{ title }}
        </h5>

        <div class="p-2 d-flex align-items-center">
          <div
            class="hoverable flex-grow-1"
            @click="openFile(modelValue.file)"
          >
            <font-awesome-icon
              v-if="rejectStatus"
              icon="fa-solid fa-exclamation-triangle"
              class="text-danger me-1"
            />

            <font-awesome-icon
              v-if="readStatus === 'read'"
              icon="fa-solid fa-eye"
              class="text-amsom-green me-1"
            />

            <font-awesome-icon
              v-if="readStatus === 'unread'"
              icon="fa-solid fa-eye-slash"
              class="text-warning me-1"
            />

            <font-awesome-icon icon="fa-solid fa-solid fa-paperclip" />

            {{ pjName }}<small
              v-if="fileToUpload.length > 1"
              class="text-muted"
            > ({{ fileToUpload.length }} Fichiers)</small>
          </div>

          <div
            v-show="fileToUpload.length > 0"
            v-if="!readOnly"
            class="me-2"
          >
            <input
              :id="'fileUpload-' + name"
              type="file"
              accept="image/png, image/jpeg, application/pdf"
              hidden
              multiple
              @change="addPj"
            >
            
            <label
              :id="'add-field-' + name"
              :for="'fileUpload-' + name"
              role="button"
              class="btn btn-primary rounded-5 fs-7 py-0 me-1"
            > 
           
              <!--
                <button
                :id="'add-field-' + name"
                type="button"
                class="btn btn-primary rounded-5 fs-7 py-0 me-1"
                @click="clickAddPj"
                > 
              -->
              <font-awesome-icon
                icon="fa-solid fa-file-circle-plus"
                class="me-1"
              />

              <span>
                Ajouter<span class="d-none d-md-block"> un document</span>
              </span>
            <!-- </button> -->
            </label>
          </div>

          <div v-if="!readOnly">
            <button
              :id="'delete-all-pj-' + name"
              type="button"
              class="btn btn-link text-danger hoverable text-decoration-underline p-0"
              @click="deletePj"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
  </amsom-overlay>
</template>

<script>
import PDFMerger from "pdf-merger-js";
import fileUtils from "@/js/fileUtils";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";

export default {
  name: 'UploadFile',
  components: { FontAwesomeIcon, AmsomOverlay },
  props: ['name', 'pjName', 'fileName', 'subText', 'title', 'required', 'label', 'modelValue', 'gedFile', 'readStatus', 'readOnly', 'rejectStatus'],

  /*
   * name: Nom technique de la pj
   * pjName: Nom de la Pj à afficher pour l'utilisateur
   * fileName : Nom du fichier
   * subText: indication complémentaire pour l'utilisateur
   * title : Titre qui s'affiche au dessus de la pj lorsqu'elle est renseignée
   * required: Indique si la pj est obligatoire
   * label: Label du champ
   */
  data() {
    return {
      fileToUpload: [],
      fileLoading: false,
      finalFile: null,
      fileDragged: false,
    };
  },

  computed: {},

  watch: {
    'gedFile' : function (newValue) {
      this.handleGedFile();
    },

    'fileName': function (newValue) {
      if (this.finalFile !== null) {
        this.finalFile['name'] = newValue;
        this.emitFile();
      }
    },
  },

  mounted() {
    if (this.modelValue?.file) {
      this.finalFile = this.modelValue;
    }else if (this.gedFile) {
      this.handleGedFile();
    }
  },

  //todo a voir avec antoine
  // unmounted() {
  //   this.deletePj()
  // },
  methods: {
    clickAddPj(){
      document.getElementById('fileUpload-' + this.name).click();
    },

    handleGedFile() {
      this.fileToUpload = [this.gedFile];
      this.uploadImage();
    },

    async addPj(e) {
      this.fileDragged = false;

      const files = e.dataTransfer
        ? [...e.dataTransfer.files]
        : [...e.target.files];
      let isValidFile = true;
      for (let file of files) {
        if ((!(await fileUtils.isImage(file)) && !(await fileUtils.isPdf(file)))) {
          isValidFile = false;
          break;
        }
      }
      if (!isValidFile) {
        alert('Les documents doivent être de type PDF et/ou des images');
        document.getElementById('input-pj-' + this.name).value = null; //clear input
        return;
      }

      this.fileToUpload.push(...files);
      this.uploadImage();
    },

    openFile(file) {
      if (!file) {
        alert('Aucun fichier');
        return;
      }
      fileUtils.openFileBase64(file);
      // let blob = fileUtils.base64ToBlob(file)
      // let url = URL.createObjectURL(blob)
      // this.$emit("openFile", [url]);
    },

    async uploadImage() {
      this.$emit('fileLoading', true);
      this.fileLoading = true;
      let merger = new PDFMerger();
      let files = this.fileToUpload;
      for (let file of files) {
        // Merge
        if (await fileUtils.isImage(file)) {
          // Redimensionnement des images
          let resized = await fileUtils.resizeImage({
            file: file,
            maxWidth: 795,
            maxHeigth: 1125
          });

          // Conversion en PDF
          let image = fileUtils.imageToPDF(resized);
          await merger.add(image);
        }
        else {
          await merger.add(file);
        }
      }
      // Sauvegarde du fichier
      let mergedFileBlob = await merger.saveAsBlob();
      let mergedFilePDF = new window.File_native([mergedFileBlob], "doc.pdf", {
        type: "application/pdf",
      });
      let data = await fileUtils.handleFile(mergedFilePDF, 'pdf');
      data['name'] = this.fileName;
      this.finalFile = data;
      this.emitFile();
      this.$emit('fileLoading', false);
      this.fileLoading = false;
    },

    emitFile() {
      this.$emit('update:modelValue', this.finalFile);
    },

    deletePj() {
      this.finalFile = null;
      this.emitFile();
      this.$emit('deleteFile');
      this.fileToUpload = [];
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0  ;
  z-index: -1;
}

.pj-label {
  border-style: dashed;
}

.pj-label:focus-within{
  border-style: solid !important;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25  ) !important;

}
</style>
