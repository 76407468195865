<template>
  <div
    class="container-fluid bg-light"
  >
    <div class="row vh-100 text-center align-items-center">
      <div class="position-absolute top-0 start-0 p-3 text-start">
        <font-awesome-icon
          :icon="['fas', 'fa-arrow-left']"
          class="text-primary"
          role="button"
          size="2x"
          @click="$router.push('/')"
        />
      </div>

      <div class="col-12 col-md-6 px-2 px-sm-2 px-md-3 px-lg-4 px-xl-5">
        <img
          :src="logo"
          class="w-25 mb-3 d-md-none"
          alt="Logo AMSOM&Moi"
        >

        <div class="card text-center rounded-4 mb-3">
          <div
            class="card-body"
            style="padding-top: 0.5rem !important;"
          >
            <div class="card-text">
              <h4 class="text-primary my-3">
                {{ $t("registerPage.cardHeader") }}
              </h4>
              
              <workflow-process-horizontal-block
                class="d-none d-md-flex"
                :process="workflowRegister"
                @go-to="processChange"
              />

              <workflow-process-horizontal-block
                class="d-flex d-md-none"
                :process="workflowRegister"
                hide-title
                @go-to="processChange"
              />
              
              <register-form-block class="mt-4" />
            </div>
          </div>
        </div>
      </div>

      <div class="d-none d-md-block col-6">
        <img
          :src="loginImg"
          class="vh-100 mw-100 px-2"
          alt="Logo AMSOM&Moi"
        >
      </div>
    </div>
  </div>
</template>

<script>
import RegisterFormBlock from "@/components/RegisterPage/FormBlock.vue";
import WorkflowProcessHorizontalBlock from "@/components/WorkflowProcessHorizontalBlock.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {mapState} from "vuex";
import logoImg from "@/assets/images/loginPage/login.svg"
import logo from "@/assets/logo.svg";

export default {
  name: "RegisterPageBrowser",
  components: { RegisterFormBlock, WorkflowProcessHorizontalBlock, FontAwesomeIcon },

  data() {
    return {
      loginImg: logoImg,
      logo: logo,
    }
  },

  computed:{
    ...mapState(['registerPage']),

    registerStep: {
      get() {
        return this.registerPage.registerStep
      },

      set(value) {
        this.$store.commit('registerPage/setRegisterStep', value)
      }
    },
    
    workflowRegister(){
      return {
        currentStep: this.registerStep,
        steps: [
          {
            numero: 1,
            texte: "Contrat",
          },
          {
            numero: 2,
            texte: "Adresse mail",
          },
          {
            numero: 3,
            texte: "Mot de passe",
          },
          {
            numero: 4,
            texte: "Validation",
          },
        ],
      }
    },
  },

  methods: {
    processChange(step) {
      this.registerStep = step;
    },
  },
}
</script>

<style scoped>
</style>
