<template>
  <div
    class="container-fluid bg-light"
  >
    <div class="row vh-100 text-center align-items-center">
      <div class="col-12 col-md-6 px-2 px-sm-2 px-md-3 px-lg-4 px-xl-5">
        <img
          :src="logo"
          class="w-25 mb-4 d-md-none"
          alt="Logo AMSOM&Moi"
        >

        <div class="card text-center rounded-4">
          <div class="card-body">
            <div class="card-text">
              <h3 class="text-primary">
                {{ $t("loginPage.cardHeader") }}
              </h3>

              <login-form-block class="mt-4 px-2 px-lg-5" />
            </div>
          </div>
        </div>

        <div class="card text-center rounded-4 mt-2">
          <div class="card-body">
            <div class="card-text">
              <router-link
                :to="{ name: 'register' }"
                role="link"
                class="nav-link hover-animate m-0 p-0 fs-5"
              >
                {{ $t('loginPage.registerButton') }} 
                <font-awesome-icon
                  icon="fa-solid fa-arrow-right"
                  class="ms-2"
                />
              </router-link>
            </div>
          </div> 
        </div>
      </div>

      <div class="d-none d-md-block col-6">
        <img
          alt="Logo AMSOM&Moi"
          :src="loginImg"
          class="vh-100 mw-100 px-2"
        >
      </div>
    </div>
  </div>
</template>

<script>

import LoginFormBlock from "@/components/LoginPage/FormBlock.vue";
import loginImg from "@/assets/images/loginPage/login.svg";
import logo from "@/assets/logo.svg";

export default {
  name: "LoginPageBrowser",
  components: {LoginFormBlock},

  data() {
    return {
      loginImg: loginImg ,
      logo: logo ,
    }
  },
}
</script>
