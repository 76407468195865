import apiErrorParser from "@/js/apiErrorParser"
import axiosOverlay from "@/services/axiosOverlay"
import {unixToDateString, howMuchTimeAgo} from "@/js/text.js";
import {unixIsBefore} from "@/js/dateUtils.js";
import {sortArray} from "@/js/array.js";
import moment from "moment-timezone";

const getDefaultState = () => {
    return {
        loading: 0,
        sollicitations: [],
        suivis: [],
        contratLoaded: [],
    }
}

const state = getDefaultState()

const getters = {
    getSollicitationById: (state) => (id) => {
        return state.sollicitations.find(sollicitation => sollicitation.id.toString() === id)
    },
    getSuivisBySollicitationId: (state) => (id) => {
        return state.suivis.filter(suivi => suivi.idSollicitation.toString() === id)
    }
}
const mutations = {
    setLoading: (state, val) => {
        if (val === '+')
            state.loading++

        if (val === '-')
            state.loading--
    },
    setSollicitations: (state, sollicitations) => {
        // On supprime les objets ayant un id commun à la liste transmise en paramètre
        state.sollicitations = state.sollicitations.filter(sollicitationLoaded => !sollicitations.find(sollicitation => sollicitation.id === sollicitationLoaded.id));
        // On ajoute les objets de la liste
        state.sollicitations = state.sollicitations.concat(sollicitations);

        state.sollicitations = state.sollicitations.map(sollicitation => {
            sollicitation.date = unixToDateString(sollicitation.dateCreation),
                sollicitation.lastMessage = howMuchTimeAgo(sollicitation.dateMaj ?? sollicitation.dateCreation),
                sollicitation.actif = ['E', 'A'].includes(sollicitation.etat)
                // Désactivation suite à une décision de l'atelier DAP de la possibilité de relancer une sollicitation
                //,sollicitation.canBeRelance = ['A'].includes(sollicitation.etat) && unixIsBefore(sollicitation.dateMaj, moment().add(-7, 'd'))
            return sollicitation;
        })

        state.sollicitations = sortArray(state.sollicitations, 'dateCreation')
    },
    setSuivis: (state, suivis) => {
        // // On supprime les objets ayant un id commun à la liste transmise en paramètre
        // state.suivis = state.suivis.filter(suiviLoaded => !suivis.find(suivi => suivi.id === suiviLoaded.id));
        // On ajoute les objets de la liste
        state.suivis = sortArray(suivis, 'dateSuivi', true)
    },
    deleteSuivisBySollicitationId: (state, id) => {
        state.suivis = state.suivis.filter(suivi => suivi.idSollicitation.toString() !== id.toString())
    },
    resetState: (state) => {
        Object.assign(state, getDefaultState())
    },
    addContratLoaded: (contrat) => {
        state.contratLoaded.push(contrat)
    }
}
const actions = {
    getSollicitationById: ({commit, state}, id) => {
        // Si la sollicitation est déjà chargée, on ne fait rien
        if (state.sollicitations.find(sollicitation => sollicitation.id.toString() === id.toString()))
            return Promise.resolve()

        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/sollicitations/' + id
            const config = {
                url: url,
                method: 'GET'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setSollicitations', [response.data]);
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    reloadSollicitationById({commit}, id) {
        commit('deleteSuivisBySollicitationId', id);
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/sollicitations/' + id
            const config = {
                url: url,
                method: 'GET'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setSollicitations', [response.data]);
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    getSollicitationsByContratId: ({commit, state}, contratId) => {
        // Si les sollicitations de ce contrat sont déjà chargées, on ne fait rien
        if (state.contratLoaded.includes(contratId) && state.sollicitations.filter(sollicitation => sollicitation.idContrat.toString() === contratId.toString()).length > 0)
            return Promise.resolve()

        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/sollicitations?idContrat=' + contratId
            const config = {
                url: url,
                method: 'GET'
            }

            commit('setLoading', '+');
            // ^^ le pb vient du loading ^^
            axiosOverlay(config)
                .then(function (response) {
                    commit('setSollicitations', response.data);
                    commit('addContratLoaded', contratId)
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    getSuivisBySollicitationId: ({commit, state}, sollicitationId) => {
        // Si les suivis de cette sollicitation sont déjà chargés, on ne fait rien
        if (state.suivis.filter(suivi => suivi.idSollicitation.toString() === sollicitationId.toString()).length > 0)
            return Promise.resolve()

        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/sollicitation_suivis?idSollicitation=' + sollicitationId
            const config = {
                url: url,
                method: 'GET'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setSuivis', response.data);
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    createSuivi: ({commit, dispatch}, suivi) => {
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/sollicitation/addSuivi'
            const config = {
                url: url,
                method: 'POST',
                data: suivi
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function () {
                    dispatch('reloadSollicitationById', suivi.idSollicitation).then(() => {
                        dispatch('getSuivisBySollicitationId', suivi.idSollicitation).then(() => {
                                resolve()
                        })
                    })
                })
                .catch(function (error) {
                    console.log(error)
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    createSollicitation: ({commit}, data) => {
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/sollicitation/create_sollicitation'
            const config = {
                url: url,
                method: 'POST',
                data: data
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setSollicitations', [response.data.data]);
                    resolve(response.data.data.id)
                })
                .catch(function (error) {
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    cancelMeeting: ({commit, dispatch}, suivi) => {
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/sollicitation/cancel_meeting'
            const config = {
                url: url,
                method: 'DELETE',
                data: {idSuivi: suivi.id}
            }
            
            commit('setLoading', '+');
            axiosOverlay(config)
                .then(() => {
                    dispatch('reloadSollicitationById', suivi.idSollicitation).then(() => {
                        dispatch('getSuivisBySollicitationId', suivi.idSollicitation).then(() => {
                                resolve()
                        })
                    })
                })
                .catch(function (error) {
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        }
        )
    },
    // relanceSollicitation: ({commit, dispatch}, idSollicitation) => {
    //     return new Promise((resolve, reject) => {
    //         let url = process.env.VUE_APP_API_URL + '/sollicitation/relance'
    //         const config = {
    //             url: url,
    //             method: 'PATCH',
    //             data: {idSollicitation: idSollicitation}
    //         }

    //         commit('setLoading', '+');
    //         axiosOverlay(config)
    //             .then(() => {
    //                 dispatch('reloadSollicitationById', idSollicitation).then(() => {
    //                     dispatch('getSuivisBySollicitationId', idSollicitation).then(() => {
    //                             resolve()
    //                     })
    //                 })
    //             })
    //             .catch(function (error) {
    //                 reject(apiErrorParser(error))
    //             })
    //             .finally(() => {
    //                 commit('setLoading', '-');
    //             })
    //     }
    //     )
    // }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
