<template>
  <widget-block :bg-image="bgImage">
    <template #title>
      <h5>
        {{ $t('assurance.titre') }}
      </h5>
    </template>

    <template #content>
      <template v-if="isLoading">
        <small class="card-text bg-light rounded-3 bg-opacity-25 fw-bold">
          Chargement...
        </small>
      </template>

      <template v-else-if="assurance.assurance && unixIsAfter(assurance.assurance?.dateDebut)">
        <span class="card-text text-secondary rounded-3">{{ $t('assurance.comming') }} {{ unixToDateString(assurance.assurance?.dateDebut, 'DD/MM') }}</span>
      </template>

      <template v-else-if="assurance.assurance?.valide">
        <span class="card-text text-primary rounded-3">
          {{ $t('assurance.aJour') }}
        </span>
      </template>

      <template v-else>
        <span class="card-text text-danger rounded-3 fw-bold">
          <span v-if="assurance.assurance === null">{{ $t('assurance.notAssured') }}</span>

          <span v-else>{{ $t('assurance.expired') }}</span>
        </span>
      </template>
    </template>

    <template
      #btn-action
    >
      <template v-if="assurance.assurance?.valide">
        <router-link
          class="btn btn-amsom-green rounded-5 mt-3 mb-2 me-1"
          :to="{ name: 'assurance' }"
        >
          {{ $t('assurance.header') }}
          <font-awesome-icon
            icon="fa-solid fa-arrow-right"
          />
        </router-link>
      </template>

      <template v-else-if="!isLoading">
        <router-link
          class="btn btn-danger rounded-5 mt-3 mb-2 me-1"
          :to="{ name: 'assurer' }"
        >
          {{ $t('common.update') }}
        </router-link>
      </template>
    </template>
  </widget-block>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import WidgetBlock from '../WidgetBlock.vue';
import { unixIsAfter } from '@/js/dateUtils';
import { unixToDateString } from '@/js/text';
import bgImage from '@/assets/images/homePage/assurance-widget.jpg';
export default {

  name: 'AssuranceWidget',
  components: {WidgetBlock},

  data() {
    return {
      bgImage: bgImage,
    }
  },

  computed: {
    ...mapState(['assurance']),
    ...mapGetters({'selectedContratId': 'user/getSelectedContrat'}),

    isLoading() {
      return this.assurance.loading || this.assurance.assurance === undefined;
    }
  },

  watch: {
    'selectedContratId': function () {
      this.loadDataOfContrat()
    },
  },

  mounted() {
    this.loadDataOfContrat()
  },

  methods: {
    unixIsAfter,
    unixToDateString,

    loadDataOfContrat(){
      if (this.selectedContratId)
        this.$store.dispatch('assurance/getAssuranceByContratId', {'idContrat' : this.selectedContratId});
    }
  }}
</script>
