<template>
  <div
    class="container-fluid bg-light"
  >
    <div class="row vh-100 text-center">
      <div class="position-absolute top-0 start-0 p-3 text-start">
        <font-awesome-icon
          :icon="['fas', 'fa-arrow-left']"
          class="text-primary"
          role="button"
          size="2x"
          @click="$router.push('/')"
        />
      </div>

      <div class="col-12 col-lg-7 px-3 px-sm-4 py-5 mx-auto text-center">
        <amsom-overlay
          :loading="loading"
        >
          <img
            :src="logo"
            class="w-25 mb-3 mx-auto"
            alt="Logo AMSOM&Moi"
          >

          <div class="card text-center rounded-4 mb-3">
            <div
              class="card-body"
              style="padding-top: 0.5rem !important;"
            >
              <div class="card-text">
                <div
                  class="row"
                  align-h="center"
                >
                  <div class="col-12 col-lg-9 mx-auto">
                    <h5 class="mt-2 mb-3 text-center">
                      {{ $t('loginPage.reinitMyPassword') }}
                    </h5>

                    <div
                      v-if="token"
                      class="text-start"
                    >
                      <div
                        v-if="resultRequest.message !== null"
                        class="w-100 text-center mb-3"
                      >
                        <span
                          :class="[
                            {'text-success' : resultRequest.state === 'success',
                             'text-danger' : resultRequest.state === 'error'}
                          ]"
                        >{{
                          resultRequest.message
                        }}</span>

                        <div
                          v-if="confirmRedirectCooldown > 0"
                          class="fw-bold"
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                          />
                          {{ confirmRedirectCooldown / 1000 }}
                        </div>
                      </div>

                      <form
                        v-if="confirmRedirectCooldown <= 0"
                        id="resetPasswordConfirmForm"
                        @submit.prevent="resetPasswordConfirm()"
                      >
                        <label
                          for="input-mail"
                          class="form-label"
                        >{{ $t('common.mailAccount') }}</label>

                        <input
                          id="input-mail"
                          v-model.trim="email"
                          v-amsom-formater-lower-case
                          :pattern="regexMail"
                          type="email"
                          inputmode="email"
                          class="form-control rounded-3"
                          :placeholder="$t('loginPage.usernamePlaceholder')"
                          required
                        >

                        <label
                          for="input-password"
                          class="form-label mt-4"
                        >
                          {{ $t('registerPage.askForPassword') }}  
                        </label>
                        
                        <div class="position-relative">
                          <input 
                            id="input-password"
                            v-model="password"
                            :type="showPassword ? 'text' : 'password'"
                            :placeholder="$t('registerPage.password')"
                            autocomplete="new-password"
                            class="form-control rounded-3 "
                            minlength="8"
                            required
                          >

                          <div
                            class="position-absolute end-0 top-0 h-100 d-flex align-items-center pe-2 "
                            role="button"
                          >
                            <font-awesome-icon
                              v-if="!showPassword"
                              icon="fa-solid fa-eye"
                              title="Afficher mot de passe en clair"
                              @click="showPassword = !showPassword"
                            />

                            <font-awesome-icon
                              v-else-if="showPassword"
                              icon="fa-solid fa-eye-slash"
                              title="Cacher mot de passe"
                              @click="showPassword = !showPassword"
                            />
                          </div>
                        </div>

                        <div class="position-relative">
                          <input
                            id="input-confirm-password"
                            v-model="confirmPassword"
                            :type="showPassword ? 'text' : 'password'"
                            :placeholder="$t('registerPage.confirmPassword')"
                            autocomplete="new-password"
                            class="form-control rounded-3 my-2"
                            minlength="8"
                            required
                          >

                          <div
                            class="position-absolute end-0 top-0 h-100 d-flex align-items-center pe-2"
                            role="button"
                          >
                            <font-awesome-icon
                              v-if="!showPassword"
                              icon="fa-solid fa-eye"
                              title="Afficher mot de passe en clair"
                              @click="showPassword = !showPassword"
                            />

                            <font-awesome-icon
                              v-else-if="showPassword"
                              icon="fa-solid fa-eye-slash"
                              title="Cacher mot de passe"
                              @click="showPassword = !showPassword"
                            />
                          </div>
                        </div>

                        <small
                          v-if="!validationPassword"
                          class="text-danger d-block mb-3"
                        >
                          {{ $t('registerPage.passwordsMustBeIdentical') }}
                        </small>
                        <!--
                          <small
                          class="text-secondary d-block"
                          style="font-size: smaller;"
                          >
                          {{ $t('registerPage.passwordRules') }}
                          </small> 
                        -->

                        <button 
                          type="submit"
                          class="btn btn-amsom-green rounded-5 py-2 mt-3 mx-auto d-block"
                        >
                          {{ $t('common.validate') }}
                        </button>
                      </form>
                    </div>


                    <div
                      v-else
                      class="text-start"
                    >
                      <form @submit.prevent="requestResetPassword">
                        <label
                          for="input-mail"
                          class="form-label"
                        >{{ $t('common.mailAccount') }}</label>

                        <input
                          id="input-mail"
                          v-model.trim="email"
                          v-amsom-formater-lower-case
                          :pattern="regexMail"
                          type="email"
                          inputmode="email"
                          class="form-control"
                          :placeholder="$t('loginPage.usernamePlaceholder')"
                          required
                        >

                        <div
                          v-if="resultRequest.message !== null"
                          class="w-100 text-center mb-3"
                        >
                          <span
                            :class="[
                              {'text-amsom-green': resultRequest.state === 'success',
                               'text-danger' : resultRequest.state === 'error'}
                            ]"
                          >{{
                            resultRequest.message
                          }}</span>
                        </div>
                        
                        <div
                          v-if="resetPassCooldown > 0"
                          class="fw-bold text-center"
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                          />
                          {{ $t('loginPage.canResendMailIn') }} {{ resetPassCooldown / 1000 }}s
                        </div>

                        <button 
                          type="submit"
                          class="btn btn-amsom-green rounded-5 py-2 mt-3 mx-auto d-block"
                          :disabled="resetPassCooldown > 0"
                        >
                          {{ $t('loginPage.reinitPassword') }}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </amsom-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/logo.svg'
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";

export default {
  name: 'ResetPasswordPage',
  components: {AmsomOverlay},

  data() {
    return {
      logo: logo,
      regexPassword: process.env.VUE_APP_REGEX_PASSWORD,
      regexMail: process.env.VUE_APP_REGEX_EMAIL,

      token: null,

      showPassword: false,
      email: null,

      password: null,
      confirmPassword: null,

      resultRequest:
        {
          state: null,
          message: null
        },

      resetPassCooldown: 0,
      confirmRedirectCooldown: 0,

      loading: false
    }
  },

  computed: {
    validationPassword() {
      return this.password === this.confirmPassword
    }
  },

  watch: {
    'resetPassCooldown': function (newvalue) {
      // if (newvalue <= 0) {
      //   this.resultRequest =
      //       {
      //         state: null,
      //         message: null
      //       }
      //
      //   return
      // }

      if (newvalue >= 1000) {
        setTimeout(() => {
          this.resetPassCooldown = newvalue - 1000
        }, 1000)
        return;
      }

      if (newvalue < 1000) {
        setTimeout(() => {
          this.resetPassCooldown = newvalue - newvalue
        }, newvalue)
        return;
      }

      return;
    },

    'confirmRedirectCooldown': function (newvalue) {
      if (newvalue <= 0) {
        this.resultRequest =
          {
            state: null,
            message: null
          }

        return
      }

      if (newvalue >= 1000) {
        setTimeout(() => {
          this.confirmRedirectCooldown = newvalue - 1000
        }, 1000)
        return;
      }

      if (newvalue < 1000) {
        setTimeout(() => {
          this.confirmRedirectCooldown = newvalue - newvalue
        }, newvalue)
        return;
      }

      return;
    }
  },

  mounted() {
    this.token = this.$route.query["token"] ?? null
  },

  methods: {
    resetPasswordConfirm() {
      if (this.confirmPassword !== this.password) {
        this.resultRequest.state = "error"
        this.resultRequest.message = "Les mots de passes doivent être identiques"
        return
      }

      this.loading = true

      this.$store.dispatch("user/resetPasswordConfirm", {token: this.token, password: this.password, mail: this.email})
        .then(() => {
          this.$store.dispatch('user/login', {
            email: this.email,
            password: this.password
          })
            .then(() => {
              this.resultRequest.state = "amsom-green"
              this.resultRequest.message = "Votre mot de passe a bien été réinitialisé, vous allez être redirigé"
              this.confirmRedirectCooldown = 5000

              setTimeout(() => {
                this.$router.push({name: 'home'})
              }, 5000)
            })
            .catch(() => {
              this.resultRequest.state = "error"
              this.resultRequest.message = "Votre mot de passe a bien été réinitialisé mais nous n'arrivons pas à vous connecter, veuillez essayer vous meme, vous allez être redirigé sur la page de connexion"
              this.confirmRedirectCooldown = 5000

              setTimeout(() => {
                this.$router.push({name: 'login'})
              }, 5000)
            })
            .finally(() => {
              this.loading = false
            })
        })
        .catch((error) => {
          this.resultRequest.state = "error"
          this.resultRequest.message = error
          this.loading = false
        })
    },

    requestResetPassword() {
      if (!this.email || this.resetPassCooldown > 0)
        return

      this.loading = true

      this.$store.dispatch('user/resetPasswordAsking', {mail: this.email})
        .then(() => {
          this.email = null
          this.resultRequest.state = "success"
          this.resultRequest.message = "Un lien vous a été envoyé par mail, ce lien sera valable 4H"
          this.resetPassCooldown = 5000
          this.loading = false
        })
        .catch((error) => {
          this.resultRequest.state = "error"
          this.resultRequest.message = error
          this.loading = false
        })
    }
  }}
</script>
