<template>
  <div
    v-if="!(hideIfEmpty && !loading && documentsToDisplay.length === 0)"
    id="cp-list-document"
    class="no-scroll-bar overflow-auto"
    :class="[{'card': !noCard}]"
  >
    <amsom-overlay :loading="isDownloading">
      <div :class="{'card-body': !noCard}">
        <h5>{{ titre }}</h5>

        <div
          v-if="loading"
          class="document-item pt-2"
        >
          <div class="row justify-content-between">
            <div
              class="col-3"
            >
              <amsom-skeleton
                :config="[12]"
              />
            </div>

            <div
              class="col-3"
            >
              <amsom-skeleton
                :config="[12]"
              />
            </div>
          </div>

          <amsom-skeleton :config="[12]" />
        </div>

        <template v-else>
          <div v-if="documentsToDisplay.length === 0">
            <p>Aucun document</p>
          </div>

          <div
            v-for="(document, index) in documentsToDisplay"
            v-else
            :key="index"
            class="document-item pt-2"
          >
            <div class="d-flex justify-content-between text-primary">
              <p class="mb-0">
                {{ document.categorie }}
              </p>

              <p class="mb-0">
                {{ unixToDateString(document.dateFichier) }}
              </p>
            </div>

            <div
              class="bg-gray-300 mt-0 rounded-3"
              @click="downloadPdf(document, index)"
            >
              <div class="d-flex justify-content-between align-items-center p-2 px-3 hoverable text-secondary">
                <strong v-if="!anonym">{{ document.titre }}</strong>

                <strong v-else>Document {{ index + 1 }}</strong>

                <font-awesome-icon
                  icon="fa-solid fa-cloud-arrow-down"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </amsom-overlay>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {unixToDateString} from "../js/text.js";
import fileUtils from "@/js/fileUtils.js";
import {sortArray} from "@/js/array.js";
import AmsomOverlay from './UI/AmsomOverlay.vue';
import AmsomSkeleton from './UI/AmsomSkeleton.vue';


export default {
  name: 'DocumentList',

  components: {
    AmsomOverlay,
    AmsomSkeleton
  },

  props: {
    /*
     * @param {String} titre
     * Titre de la liste de documents
     * */
    titre: {
      type: String,
      default: "Mes derniers documents"
    },

    /*
     * @param {Number} length
     * Nombre de documents à afficher
     * */
    length: {
      type: Number,
      default: 5
    },

    /*
     * @param {Array} categories
     * Liste des catégories de documents à afficher
     * */
    categories: {
      type: Array,
      default: () => []
    },

    /*
     * @param {Boolean} noCard
     * Si true, n'affiche pas dans dans une class card
     * */
    noCard: {
      type: Boolean,
      default: false
    },

    /*
     * @param {Boolean} hideIfEmpty
     * Si true, n'affiche pas la liste si elle est vide
     * */
    hideIfEmpty: {
      type: Boolean,
      default: false
    },

    /*
     * @param {string} moduleSearched
     * Si un module est sélectionné, on filtre les documents par module
     * */
    moduleSearched: {
      type: String,
      default: null
    },

    /*
     * @param {string} contratSearched
     * Si un contrat est sélectionné, on filtre les documents pour ce contrat
     * */
    contratSearched: {
      type: Number,
      default: null
    },

    /*
     * @param {string} sollicitationSearched
     * Si une sollicitation est sélectionnée, on filtre les documents pour cette sollicitation
     * */
    sollicitationSearched: {
      type: Number,
      default: null
    },

    /*
     * @param {Boolean} anonymisation
     * Si true, on affiche les documents anonymisés
     * */
    anonym: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      isDownloading: false
    }
  },

  computed: {
    ...mapState(['ged']),
    ...mapGetters({'selectedContratId': "user/getSelectedContrat"}),

    loading() {
      return this.ged.loading > 0
    },

    documentList() {
      let ged = this.ged.ged.concat(this.ged.gedSollicitations.map((ged) => ({ ...ged, categorie: 'Sollicitation'})))

      return sortArray(ged, 'dateFichier', false)
    },

    documentsToDisplay() {
      let documents = this.documentList
      // Si aucune catégorie n'est sélectionnée, on affiche tous les documents
      if (this.categories.length > 0)
        // sinon on filtre les documents par la/les catégorie(s) sélectionnée(s)
        documents = documents.filter(document => this.categories.includes(document.categorie))

      if(this.sollicitationSearched){
        documents = documents.filter(document => document.idSollicitation === this.sollicitationSearched)
      }

      // Si un module est sélectionné, on filtre les documents par module
      if (this.moduleSearched) {
        documents = documents.filter(document => document.idModule === this.moduleSearched)
      }

      // Si un contrat est sélectionné, on filtre les documents par contrat
      if (this.contratSearched) {
        documents = documents.filter(document => document.idContrat === this.contratSearched)
      }

      // On retourne les length premiers documents
      if(this.length > 0){
        return documents.slice(0, this.length)
      }

      return documents
    },
  },

  watch: {
    'selectedContratId': function () {
      this.loadDataOfContrat()
    },
  },

  mounted() {
    this.loadDataOfContrat()
  },

  methods: {
    unixToDateString,

    downloadPdf(document, index) {
      this.isDownloading = true
      let title = document?.titre ?? 'Document'
      if (this.anonym){
        title = 'Document ' + (index + 1)
      }
      if(document.categorie === 'Sollicitation'){
        this.$store.dispatch('ged/loadPdfSollicitationById', document.id)
          .then((data) => {
            console.log(data)
            // let title = this.documentsToDisplay.find((doc) => doc.id === document.id)?.titre ?? 'Document'
            let fileURL = URL.createObjectURL(data);
            fileUtils.downloadFile(data, title)
            // URL.revokeObjectURL(data);

            this.isDownloading = false
          })
          .finally(() => this.isDownloading = false)
      }else{
        this.$store.dispatch('ged/loadPdfById', document.id)
          .then((data) => {
            fileUtils.downloadFile(data, title)
            // URL.revokeObjectURL(data);

            this.isDownloading = false
          })
          .finally(() => this.isDownloading = false)
      }
    },

    loadDataOfContrat(){
      if(this.selectedContratId) {
        this.$store.dispatch('ged/loadGedByContratId', this.selectedContratId)
        this.$store.dispatch('ged/loadGedSollicitationByContratId', this.selectedContratId)
      }
    }
  }}
</script>
