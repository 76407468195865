<template>
  <div
    v-if="askMotifDepart"
    id="input-group-motif"
    class="col-12 col-md-6"
    role="group"
  >
    <label
      for="input-motif"
      class="form-label d-block"
    >
      Motif du départ <span class="text-danger">*</span></label>

    <select
      id="input-motif"
      v-model="individu.motifDepart"
      class="form-control"
      required
    >
      <option value="separation">
        Séparation
      </option>

      <option value="deces">
        Décès
      </option>

      <option value="autre">
        Autre
      </option>
    </select>
  </div>

  <div
    v-if="askDateSortie"
    id="input-group-date-depart"
    class="col-12 col-md-6"
    role="group"
  >
    <label
      for="input-date-depart"
      class="form-label d-block"
    >
      Date {{ individu.decede ? "du décès" : "de sortie" }}
      <span class="text-danger">*</span></label>

    <input
      id="input-date-depart"
      v-model="dateDepart"
      class="form-control"
      type="date"
      name="dateDepart"
      required
      :max="maxDateSortie"
      :min="minDateSortie"
    >
  </div>

  <div
    v-if="needPjSortie || individu.pjDepart"
    class="col-12"
  >
    <amsom-overlay :loading="loadingPjSortie">
      <upload-file
        v-model="individu.pjDepart"
        name="depart"
        :ged-file="gedPjSortie"
        :pj-name="
          'Justificatif de ' +
            (individu.decede ? 'décès' : 'départ') +
            ' obligatoire *'
        "
        class="fw-bold"
        :sub-text="pjSubText"
        :file-name="departFileName"
        title="Pièce(s) justificative(s)"
        required
        @file-loading="updateFileLoading"
        @delete-file="delete individu.pjDepart"
      />
    </amsom-overlay>
  </div>
</template>

<script>
import UploadFile from "@/components/UploadFile.vue";
import { mapGetters } from "vuex";
import store from "@/store";
import moment from "moment-timezone";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";

export default {
  name: "LocataireSortieBlock",

  components: {
    UploadFile,
    AmsomOverlay,
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },

    askDateSortie: {
      type: Boolean,
      default: false,
    },

    askMotifDepart: {
      type: Boolean,
      default: false,
    },

    needPjSortie: {
      type: Boolean,
      default: false,
    },

    //minDateSortie props, default to the mapGetter getMinDateSortie
    minDateSortie: {
      type: String,

      default: () => {
        return store.getters["locataireFeeding/getMinDateSortie"];
      },
    },

    maxDateSortie: {
      type: String,

      default: () => {
        return store.getters["locataireFeeding/getMaxDateSortie"];
      },
    },
  },

  emits: ["update:modelValue", "fileLoading"],

  data() {
    return {
      individu: this.modelValue,
      loadingPjSortie: false,
      gedPjSortie: null,
    };
  },

  computed: {
    ...mapGetters({ getNomPrenom: "contrat/getNomPrenom" }),

    departFileName() {
      return this.getNomPrenom(this.individu) + " - Justificatif départ";
    },

    pjSubText() {
      return this.individu.decede
        ? "Copie de l'acte de décès."
        : "Copie du préavis de départ signée par " +
          this.getNomPrenom(this.individu);
    },

    dateDepart: {
      get() {
        let date = this.individu?.dateDepart;
        return date ? moment.unix(date).format("YYYY-MM-DD") : null;
      },

      set(value) {
        let data = value ? moment(value, "YYYY-MM-DD").unix().toString() : null;
        this.individu.dateDepart = data;
      },
    },
  },

  watch: {
    individu: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },

      deep: true,
    },

    "individu.motifDepart": {
      handler(newValue) {
        let isDeces = newValue === "deces";

        this.individu.decede = isDeces;
        if (!this.askDateSortie) {
          this.individu.dateDepart = moment().unix()
        }
      },
    },

    loadingPjSortie(value) {
      this.$emit("fileLoading", value);
    },

    // modelValue: {
    //   handler(value) {
    //     this.individu = value;
    //   },

    //   deep: true,
    // },
  },

  mounted() {
    this.$store.dispatch("utilities/loadPays");

    if (this.individu.justificatifs) {
      let pj = this.individu.justificatifs.find(
        (pj) => pj.typeFichier === "changement_situation"
      );
      if (pj) {
        this.loadingPjSortie = true;
        this.$store.dispatch("ged/loadPdfById", pj.id).then((data) => {
          this.gedPjSortie = new window.File_native([data], pj.libelle, {
            type: data.type,
          });
          this.loadingPjSortie = false;
        });
      }
    }
  },

  methods: {
    updateFileLoading(value) {
      this.$emit("fileLoading", value);
    },
  },
};
</script>
