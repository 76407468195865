import {createStore} from "vuex";
import user from "./user"
import enquete from "./enquete"
import utilities from "./utilities"
import registerPage from "@/store/component/registerPage"
import contact from "@/store/component/contact"
import compte from "@/store/component/compte"
import ged from "./ged.js"
import assurance from "@/store/component/assuranceContrat.js"
import moduleContrat from "@/store/component/moduleContrat";
import sollicitation from "@/store/component/sollicitation";
import locatairePanel from "@/store/component/locatairePanel";
import sidebar from "@/store/component/sidebar.js";
import contrat from "@/store/contrat.js";
import topbar from "@/store/component/topbar.js";
import communicationCiblee from "@/store/component/communicationCiblee.js";
import notification from "@/store/component/notification.js";
import locataireFeeding from "@/store/component/locataireFeeding.js";

export default createStore({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    getters: {
    },
    modules: {
        user,
        registerPage,
        enquete,
        utilities,
        contact,
        compte,
        ged,
        assurance,
        contrat,
        moduleContrat,
        sollicitation,
        sidebar,
        topbar,
        locatairePanel,
        communicationCiblee,
        notification,
        locataireFeeding
    }
})
