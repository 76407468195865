<template>
  <div>
    <amsom-overlay
      :loading="user.loading > 0"
      class="w-100"
    >
      <login-page-browser />
    </amsom-overlay>
    
    <!-- Modal de demande d'accepter les notifications -->
    <modal-notification v-if="$isAppCordova" />
  </div>
</template>

<script>
import LoginPageBrowser from "@/views/Browser/Login.vue";
import ModalNotification from "@/components/LoginPage/ModalNotification.vue"
import {mapState} from "vuex";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";

export default {
  name: "LoginPage",
  components: {LoginPageBrowser, ModalNotification, AmsomOverlay},

  data() {
    return {
      login: {
        identifiant: null,
        password: null
      }
    }
  },

  computed: {
    ...mapState(['user']),
  }
}
</script>
