import moment from "moment-timezone"
import store from "@/store/index.js";

const getDefaultState = () => {
    return {
        loading: 0,
        lignesCompte: null,
        encaissements: undefined,
    }
}

const state = getDefaultState()

const getters = {
    isNeededPjEtatCivil: () => (oldValue, newValue) => {
        return (
            !oldValue || !oldValue?.idIndividu ||
            oldValue.nom?.toUpperCase() !==
            newValue.nom?.toUpperCase() || // Si nom de naissance = nom d'usage, alors pas de pièce justificative requise
            (oldValue.nom?.toUpperCase() !==
                newValue.nomNaissance?.toUpperCase() &&
                oldValue.nomNaissance?.toUpperCase() !==
                newValue.nomNaissance?.toUpperCase()) ||
            oldValue.prenom?.toUpperCase() !==
            newValue.prenom?.toUpperCase() ||
            oldValue.dateNaissance !== newValue.dateNaissance ||
            (oldValue.lieuNaissance && // Si pas de lieu de naissance, alors pas de pièce justificative requise
                oldValue.lieuNaissance?.toUpperCase() !==
                newValue.lieuNaissance?.toUpperCase()) ||
            oldValue.titre?.toUpperCase() !==
            newValue.titre?.toUpperCase()
        );
    },

    isNeededPjLienParente: () => (locataire) => {
        return store.getters["contrat/getTypeLocataire"](locataire) === "occupant" && locataire.lienParente === "M";
    },

    isNeededPjSortie: () => (locataire) => {
        return store.getters["contrat/getTypeLocataire"](locataire) !== "occupant";
    },

    getMaxDateSortie: () => {
        let contrat = store.state.contrat.currentContratInfos
        let dateSortie = moment.unix(contrat.dateSortie)
        let maxDate = moment().add(2, 'years')
        if (contrat.dateSortie && dateSortie.isBefore(maxDate))
            maxDate = dateSortie

        return maxDate.format('YYYY-MM-DD')
    },

    getMinDateSortie: () => {
        // return moment().format('YYYY-MM-DD')

        let contrat = store.state.contrat.currentContratInfos
        // let dateDebut = moment.unix(this.locataire.debut)
        let minDate = moment.unix(contrat.dateEntree)

        // if (dateDebut && dateDebut.isAfter(minDate))
        //   minDate = dateDebut

        return minDate.format('YYYY-MM-DD')
    },
}
const mutations = {
}
const actions = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
