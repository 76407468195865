<template>
  <amsom-modal
    v-if="showModal && enquete.loading === 0"
    close-option
    @close="showModal = false"
  >
    <div v-if="rejets">
      <h4>
        Nous avons rejeté votre enquête
      </h4>

      <span>Nous vous invitons à relire votre enquête et à corriger {{ ((rejets.contrat?.length??0) + (Object.keys(rejets.individu??{}).length )) >1?'les problèmes listés':"le problème" }} ci-dessous : </span>

      <div
        v-for="(individuRejete, index) in getRejetsGrouped['individu']"
        :key="index"
      >
        <div class="mt-3">
          <span class="h5">{{ getNomPrenom(getIndividuByIdIndividuEnquete(index)) }}</span>

          <div
            v-for="rejet in individuRejete"
            :key="rejet.id"
            role="button"
            class="ms-2"
            @click="goToIndividu(getIndividuByIdIndividuEnquete(index))"
          >
            <font-awesome-icon
              icon="exclamation-triangle"
              class="text-danger me-2"
            />

            {{ rejet.libelleJustificatif }} : <span> {{ rejet.libelleMotif }}</span>

            (<u>voir</u>)
          </div>
        </div>
      </div>

      <hr v-if="getRejetsGrouped['individu']?.length > 0">

      <div class="mt-3">
        <div>
          <span
            v-if="getRejetsGrouped['contrat']"
            class="h5"
          >
            Fiscalité
          </span>

          <div
            v-for="rejet in getRejetsGrouped['contrat']"
            :key="rejet.id"
            class="ms-2"
            role="button"
            @click="goToFiscalite()"
          >
            <font-awesome-icon
              icon="exclamation-triangle"
              class="text-danger me-2"
            />

            {{ rejet.libelleJustificatif }} : {{ rejet.libelleMotif }} (<u>voir</u>)
          </div>
        </div>
      </div>
    </div>
  </amsom-modal>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import AmsomModal from "../UI/AmsomModal.vue";

export default {
  name: "RejetModal",

  components: {
    AmsomModal,
  },

  props: {
    rejets: {
      type: Object,
      required: true,
    },

    show: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: ["setCurrentStep", "goToIndividu"],

  data() {
    return {
      showModal: this.show,
    };
  },

  computed: {
    ...mapState(["enquete"]),

    ...mapGetters({
      getIndividuByIdIndividuEnquete: "enquete/getIndividuByIdIndividuEnquete",
      getRejetsGrouped: "enquete/getRejetsGrouped",
      getNomPrenom: "enquete/getNomPrenom",
    })
  },

  methods:{
    goToFiscalite() {
      this.$emit("setCurrentStep", 2)
      this.showModal = false
    },

    goToIndividu(individu){
      this.$emit("setCurrentStep", 1)

      this.$emit("goToIndividu", individu)
      this.showModal = false
    }
  }
};
</script>
