<template>
  <module-panel-item
    v-for="(module) in modules"
    :key="module.id"
    :module="module"
  />

  <document-list
    titre="Mes documents"
    :categories="['Contrat logement', 'Contrat stationnement']"
    hide-if-empty
    style="max-height: 40vh;"
    anonym
  />
</template>

<script>
import ModulePanelItem from '@/components/ContratPage/ModulePanelItem.vue';
import {mapGetters, mapState} from 'vuex';
import moment from "moment-timezone";
import DocumentList from '@/components/DocumentList.vue';
import {sortArray} from "@/js/array.js";
import fileUtils from "@/js/fileUtils.js";

export default {
  name: 'ModulePanel',

  components: {
    ModulePanelItem,
    DocumentList
  },

  computed: {
    ...mapState(['moduleContrat', 'contrat']),
    ...mapGetters({'selectedContratId': "user/getSelectedContrat"}),

    loading() {
      return !this.moduleContrat.modules || this.moduleContrat.loading > 0;
    },

    modules() {
      if (!this.contrat.currentContratInfos)
        return null

      return this.moduleContrat.modules?.filter((module) => {
        let dateComparatif = this.contrat.currentContratInfos.dateSortie ? moment.unix(this.contrat.currentContratInfos.dateSortie) : moment()

        return !module.dateFin || (module.dateFin > dateComparatif.subtract(1, 'month').unix())
      })
    },
  },

  watch: {
    'selectedContratId': function () {
      this.loadDataOfContrat()
    },
  },

  mounted() {
    this.loadDataOfContrat()
  },

  methods: {
    loadDataOfContrat() {
      if (this.selectedContratId) {
        this.$store.dispatch('moduleContrat/getModuleByContratId', this.selectedContratId)
      }
    },
  }
}
</script>

<style scoped>
:deep(.accordion-button) {
  background-color: var(--bs-primary) !important;
  color: var(--bs-gray-300) !important;
}

.accordion {
  border-radius: 15px;
  overflow: hidden;
}
</style>
