<template>
  <div
    id="sidebar"
    class="d-flex flex-column bg-body"
    :class="sidebar.isExtended ? 'showed' : 'hidden'"
  >
    <router-link
      class="sidebarLogoContainer text-center mb-5 mt-2"
      role="button"
      :to="{ name: 'home' }"
    >
      <img
        id="sidebarLogo"
        :src="sidebarLogo"
        alt="Icon de la sidebar de AMSOM&Moi"
        class="w-75"
      >
    </router-link>

    <div class="d-flex flex-column flex-grow-1 justify-content-between h-100">
      <ul
        class="nav nav-pills flex-column p-2"
      >
        <li
          v-for="item in menuItems"
          :key="item.id"
        >
          <router-link
            :to="{name: item.name[0]}"
            class="nav-link px-0 text-truncate"
            :class="[sidebar.isExtended ? 'text-start' : 'text-center', {'active' : item.name.includes(currentRoute)}]"
          >
            <font-awesome-icon
              class="fa fa-xl mx-1 text-center"
              style="width: 25px"
              :icon="item.icon"
            />

            <span
              class="sidebar-link-title ms-2"
            >{{ item.label }} </span>
          </router-link>
        </li>
      </ul>

      <span
        id="toggleSideBar"
        class="text-center fs-4 mb-3"
        href="#"
        role="button"
        @mousedown="switchSideBar"
      >
        <font-awesome-icon
          v-if="!sidebar.isExtended"
          icon="fa-solid fa-align-left"
        />

        <font-awesome-icon
          v-else
          icon="fa-solid fa-xmark"
        />
      </span>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import sidebarLogo from '@/assets/logo.svg'
export default {
  name: 'SideBar',
  props: ['menuItems'],

  data() {
    return {
      activeItem: 'accueil',
      sidebarLogo: sidebarLogo,
    };
  },

  computed: {
    ...mapState(["sidebar"]),

    currentRoute() {
      return this.$route.name
    }
  },

  methods: {

    switchSideBar() {
      this.$store.commit("sidebar/setIsExtended", !this.sidebar.isExtended)
    },
  }}
</script>