import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
import LanguageDetector from 'i18next-browser-languagedetector'

function loadLocaleRessoureces() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = {translation: locales(key)}
    }
  })
  console.log(messages)
  return messages
}

function loadLocal(){
  let locale = window.localStorage.getItem("locale")
  if(!locale){
    locale = process.env.VUE_APP_I18N_LOCALE
    window.localStorage.setItem("locale", locale)
  }

  document.getElementsByTagName("html")[0].lang = locale

  return locale
}

i18next
    // .use(LanguageDetector)
    .init({
      debug: true,
      fallbackLng: 'fr',
      resources: loadLocaleRessoureces()
    });

export default function (app) {
  app.use(I18NextVue, { i18next })
  return app
}
