<template>
  <div class="py-4">
    <p>{{ message }}</p>

    <span
      class="spinner-border spinner-border-lg"
      role="status"
    />
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "RegisterStep4Block",

  data() {
    return {
      message: this.$t('registerPage.mailVerification'),
    }
  },

  computed: {
    ...mapState(['registerPage'])
  },

  mounted() {
    const self = this
    if (this.registerPage.registerStep === 4) {
      setTimeout(() => {
        self.loginWhileFail()
      }, 5000)
    }
  },

  methods: {
    loginWhileFail() {
      if (this.$route.name === 'register' && this.registerPage.registerStep === 4) {
        const self = this

        this.$store.dispatch('user/tryLogin', {
          email: this.registerPage.mail.toLowerCase(),
          password: this.registerPage.password
        })
          .then(() => {
            self.message = this.$t('registerPage.mailVerificationSuccess')
            self.error = null

            setTimeout(() => {
              self.$router.push({name: 'home'})
            }, 3000)
          })
          .catch(() => {
            setTimeout(() => {
              if (this.$route.name === 'register' && this.registerPage.registerStep === 4) {
                self.loginWhileFail()
              }
            }, 5000)
          })
      }
    }
  }
}
</script>
