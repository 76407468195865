import apiErrorParser from "@/js/apiErrorParser"
import axiosOverlay from "@/services/axiosOverlay"
import {sortArray} from "@/js/array.js";

const getDefaultState = () => {
    return {
        loading: 0,
        ged: [],
        gedSollicitations: [],
        gedMensuel: null
    }
}

const state = getDefaultState()
const getters = {}
const mutations = {

    setLoading: (state, val) => {
        if (val === '+')
            state.loading++

        if (val === '-')
            state.loading--
    },
    setGed: (state, ged) => {
        // trier par date décroissante
        ged = sortArray(ged, 'dateFichier', false)
        state.ged = ged
    },

    setGedSollicitation: (state, ged) => {
        // trier par date décroissante
        ged = sortArray(ged, 'terme', false)
        state.gedSollicitations = ged
    },

    setGedMensuel: (state, ged) => {
        state.gedMensuel = ged
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState())
    }
}
const actions = {
    loadGedByContratId: ({commit}, contratId) => {
        // si les lignes du contrat contratId sont déjà chargées, on ne recharge pas
        if (state.ged.filter(g => g.idContrat === contratId).length>0) return Promise.resolve()
        // sinon on charge les lignes de compte du contrat contratId
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/ged_locataires?idContrat=' + contratId
            const config = {
                url: url,
                method: 'GET'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setGed', response.data);
                    commit('setLoading', '-');
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    commit('setLoading', '-');
                    reject(apiErrorParser(error))
                })
        })
    },
    generateQuittance: ({commit}, data) => {
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/quittance_pdf'
            const config = {
                url: url,
                method: 'POST',
                responseType: 'blob',
                data: data
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setLoading', '-');
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    commit('setLoading', '-');
                    reject(apiErrorParser(error))
                })
        })
    },
    loadPdfById: ({commit}, gedId) => {
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/gedLocataire/' + gedId
            const config = {
                url: url,
                method: 'GET',
                responseType: 'blob'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setLoading', '-');
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    commit('setLoading', '-');
                    reject(apiErrorParser(error))
                })
        })
    },
    loadGedSollicitationByContratId: ({commit}, contratId) => {
        // sinon on charge les lignes de compte du contrat contratId
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/ged_sollicitations?idContrat=' + contratId
            const config = {
                url: url,
                method: 'GET'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setGedSollicitation', response.data);
                    commit('setLoading', '-');
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    commit('setLoading', '-');
                    reject(apiErrorParser(error))
                })
        })
    },
    loadPdfSollicitationById: ({commit}, gedId) => {
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/gedSollicitation/' + gedId
            const config = {
                url: url,
                method: 'GET',
                responseType: 'blob'
            }

            // commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    // commit('setLoading', '-');
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    commit('setLoading', '-');
                    reject(apiErrorParser(error))
                })
        })
    },
    loadGedMensuelByContratId: ({commit}, contratId) => {
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/ged_locataire_mensuels?idContrat=' + contratId
            const config = {
                url: url,
                method: 'GET'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setGedMensuel', response.data);
                    commit('setLoading', '-');
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    commit('setLoading', '-');
                    reject(apiErrorParser(error))
                })
        })
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
