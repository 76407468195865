<template>
  <div
    :id="'input-group-commune-'+inputIdentifier"
    class="col-12 col-md-6"
    role="group"
  >
    <label
      for="input-commune"
      class="form-label d-block"
    >
      {{ cityLabel }} <span
        v-if="required"
        class="text-danger"
      >*</span>
    </label>

    <v-select
      v-if="enFrance"
      :id="'input-commune-'+inputIdentifier"
      v-model="city"
      :disabled="readOnly"
      :options="items"
      label="nom"
      :loading="loading"
      :select-on-tab="true"
      :placeholder="placeholder"
      :input-id="inputIdentifier"
      :filterable="false"
      :clearable="false"
      :required="required"
      @search="timeOutToGetItems"
      @open="getItems"
    >
      <template #search="{ attributes, events }">
        <input
          class="vs__search"
          :required="required && !city?.nom"
          v-bind="attributes"
          v-on="events"
        >
      </template>

      <template #option="{ nom, departement }">
        <h6 style="margin: 0">
          {{ nom }}
        </h6>

        <small>{{ departement?.code }} - {{ departement?.nom }}</small>
      </template>

      <template #no-options="{ search }">
        <small
          class="hoverable text-decoration-underline text-danger"
          @click="switchHorsFrance(search)"
        >{{ noResultString }}</small>
      </template>
    </v-select>

    <input
      v-else
      :id="'input-commune-'+inputIdentifier"
      v-model.trim="city.nom"
      type="text"
      class="form-control"
      required
      :placeholder="placeholder + ' (hors France)'"
      :maxlength="maxlength"
    >

    <div
      v-if="askEnFrance"
      class="form-check"
    >
      <input
        :id="'enFrance-'+inputIdentifier"
        class="form-check-input"
        type="checkbox"
        :checked="enFrance"
        @click="switchFranceState"
      >

      <label
        class="form-check-label"
        :for="'enFrance-'+inputIdentifier"
      >Commune située en France</label>
    </div>
  </div>

  <!-- ZipCode -->
  <div
    v-if="cpOptions || !enFrance || forceShowZipCode"
    :id="'input-group-zip-'+inputIdentifier"
    class="col-12 col-md-6"
    role="group"
  >
    <label
      for="input-cp"
      class="form-label d-block"
    >
      Code Postal
      <span
        v-if="
          (zipCodeRequired && (cpOptions || forceShowZipCode)) ||
            (abroardZipCodeRequired && !enFrance)
        "
        class="text-danger"
      >*</span></label>

    <select
      v-if="cpOptions"
      :id="'input-cp-'+inputIdentifier"
      v-model="cp"
      class="form-select"
      :required="zipCodeRequired"
    >
      <option
        v-for="jour in cpOptions"
        :key="jour.value"
        :value="jour.value"
      >
        {{ jour.text }}
      </option>
    </select>

    <input
      v-else-if="forceShowZipCode && enFrance"
      :id="'input-cp-'+inputIdentifier"
      v-model="cp"
      v-amsom-formater-upper-case
      type="text"
      class="form-control"
      placeholder="Code postal"
      minlength="1"
      maxlength="10"
      :required="zipCodeRequired"
    >

    <input
      v-if="!enFrance"
      :id="'input-cp-'+inputIdentifier"
      v-model="cp"
      v-amsom-formater-upper-case
      type="text"
      class="form-control"
      placeholder="Code postal"
      minlength="1"
      maxlength="10"
      :required="abroardZipCodeRequired"
    >
  </div>

  <!-- Pays -->
  <div
    v-if="!enFrance"
    :id="'input-group-pays-'+inputIdentifier"
    class="col-12 col-md-6"
    role="group"
  >
    <label
      :for="'input-pays-'+inputIdentifier"
      class="form-label d-block"
    >Pays <span class="text-danger">*</span></label>

    <select
      :id="'input-pays-'+inputIdentifier"
      v-model="city.pays"
      class="form-control"
      required
    >
      <option
        v-if="!paysOptions"
        disabled
      >
        Chargement des Pays en cours ...
      </option>

      <option
        v-for="p in paysOptions"
        :key="p.id"
        :value="p.id"
      >
        {{ p.nom }}
      </option>
    </select>
  </div>

  <slot />
</template>

<script>
import "vue-select/dist/vue-select.css";

export default {
  name: "AutocompleteCity",

  props: {
    forceShowZipCode:{
      type: Boolean,
      default: false,
    },

    askEnFrance: {
      type: Boolean,
      default: true,
    },

    required: {
      type: Boolean,
      default: false,
    },

    zipCodeRequired: {
      type: Boolean,
      default: false,
    },

    abroardZipCodeRequired: {
      type: Boolean,
      default: false,
    },

    modelValue: {
      type: Object || Number,
      default: null,
    },

    noResultString: {
      type: String,
      default: "Je ne suis pas né en France",
    },

    placeholder: {
      type: String,
      default: "Entrez la ville de naissance",
    },

    maxlength: {
      type: String,
      default: "50",
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    paysOptions: {
      type: Array,

      default: () => {
        return [];
      },
    },

    inputIdentifier: {
      type: String,
      default: "0",
    },

    // forceShowZipCode: {
    //   type: Boolean,
    //   default: false,
    // },

    cityLabel: {
      type: String,
      default: "Commune",
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      items: [],
      limite: 7,
      loading: false,
      city: {},
      cp: null,

      controller: null,
      timeOutSearch: null,
    };
  },

  computed: {
    enFrance() {
      return this.city?.pays === "FRA";
    },

    cpOptions() {
      if (this.city?.codesPostaux?.length > 1) {
        return this.city.codesPostaux.map((cp) => {
          return { value: cp, text: cp };
        });
      }

      return null;
    },
  },

  watch: {
    cp(newValue, oldValue) {
      this.emitCity();
    },

    "city.pays"(newValue, oldValue) {
      this.emitCity();
    },

    "city.nom": function (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (typeof newValue === "string") {
          this.city.nom = newValue.substring(0, this.maxlength);
        }
        if (this.city.codesPostaux?.length === 1) {
          this.cp = this.city.codesPostaux[0];
        }
        this.emitCity();
      }
    },

    modelValue(){
      this.reloadCity()
    }
  },

  mounted() {
    this.reloadCity();
  },

  methods: {
    switchFranceState() {
      this.reinitCity(!this.enFrance);
    },

    reloadCity() {
      if (this.modelValue) {
        this.city = {
          nom: this.modelValue?.city?.nom,
          code: this.modelValue?.city?.nom ?? "",
          departement: this.modelValue?.city?.departement ?? { code: "", nom: "" },
          pays: this.modelValue?.city?.pays ?? "FRA",
          codesPostaux: this.modelValue?.city?.codesPostaux ?? this.city.codesPostaux ?? [],
        };
        this.cp = this.modelValue?.cp ?? null;
      }else{
        let enFrance = this.enFrance
        if(!this.city?.pays)
          enFrance = true

        this.reinitCity(enFrance)
      }
    },

    reinitCity(enFrance = true) {
      if (enFrance) {
        this.city = {
          nom: "",
          code: "",
          departement: { code: "", nom: "" },
          pays: "FRA",
          codesPostaux: [],
        };
      } else {
        this.city = {
          nom: "",
          code: "",
          departement: { code: "", nom: "Hors France" },
          pays: "",
          codesPostaux: [],
        };
      }
      this.cp = null;
    },

    emitCity() {
      let res = {
        city: this.city,
        cp: this.cp,
      };

      this.$emit("update:modelValue", res);
    },

    // async getItemByCode(code) {
    //   let url = "https://geo.api.gouv.fr/communes?code=" + code
    //   let res = await fetch(url).then(response => {
    //     this.loading = false;
    //     return response.json();
    //   });

    //   return res[0]
    // },
    timeOutToGetItems(searchString) {
      if (this.timeOutSearch) clearTimeout(this.timeOutSearch);

      this.timeOutSearch = setTimeout(() => {
        this.getItems(searchString);
      }, 500);
    },

    async getItems(searchString) {
      this.loading = true;
      let url;

      if (searchString)
        // Si il existe une recherche
        url =
          "https://geo.api.gouv.fr/communes?nom=" +
          searchString +
          "&fields=departement,codesPostaux&boost=population&limit=" +
          this.limite;
      // Si aucune recherche, on affiche les villes les plus peuplées du département
      else
        url =
          "https://geo.api.gouv.fr/departements/80/communes?boost=population&fields=departement,codesPostaux&limit=" +
          this.limite;

      if (this.controller) {
        this.controller.abort();

        this.controller = null;
      }

      let controller = new AbortController();
      this.controller = controller;

      try {
        let res = await fetch(url, {
          signal: controller.signal,
        }).then((response) => {
          this.loading = false;
          return response.json();
        });

        this.items = res.map((city) => {
          city.nom = city.nom.toUpperCase(); //Uppercase pour Laurent
          city.pays = "FRA";

          return city;
        });
      } catch (err) {
        if (err.name == "AbortError") {
          // handle abort()
          console.log("Aborted!");
        } else {
          throw err;
        }
      }
    },

    switchHorsFrance(search) {
      this.switchFranceState();
      this.city.nom = search;
    },
  },
};
</script>

<style>
.vs__search::placeholder {
  opacity: 0.55;
  font-size: 0.9em;
  color: var(--bs-primary);
}

.vs__dropdown-toggle {
  background-color: white;
}
</style>
