import axiosOverlay from "@/services/axiosOverlay";
import apiErrorParser from "@/js/apiErrorParser";

const state = {
    registerStep: 1,
    availableCommunication: {
        mail: true,
        telephone: true
    },
    loading: 0,

    contrat: null,
    mail: null,
    password: null
}

const getters = {
}

const mutations = {
    setLoading: (state, val) => {
        if(val === '+')
            state.loading++

        if(val === '-')
            state.loading--
    },
    setRegisterStep: (state, registerStep) => {
        state.registerStep = registerStep
    },
    setAvailableCommunication: (state, data) => {
        state.availableCommunication.mail = data.mail
        state.availableCommunication.telephone = data.telephone
    },
    setContrat: (state, contrat) => {
        state.contrat = contrat
    },
    setMail: (state, mail) => {
        state.mail = mail
    },
    setPassword: (state, password) => {
        state.password = password
    }
}

const actions = {
    getAvailableCommunicationForContrat: ({commit}, numContrat) => {
        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/contrat_available_communications/' + numContrat
            const config = {
                url: url,
                method: 'GET'
            }

            axiosOverlay(config, false, true, false)
                .then(function (response) {
                    commit('setAvailableCommunication', response.data)
                    commit('setContrat', numContrat)
                    resolve()
                })
                .catch(function (error) {
                    console.log(error)
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    checkMailForContrat: ({commit}, data) => {
        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/register/checkMailContrat'
            const config = {
                url: url,
                params: data,
                method: 'GET'
            }

            axiosOverlay(config, false)
                .then(function () {
                    resolve()
                })
                .catch(function (error) {
                    console.log(error)
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    checkTelForContrat: ({commit}, data) => {
        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/register/checkTelContrat'
            const config = {
                url: url,
                params: data,
                method: 'GET'
            }

            axiosOverlay(config, false)
                .then(function () {
                    resolve()
                })
                .catch(function (error) {
                    console.log(error)
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    checkCodeTel: ({commit}, data) => {
        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/register/isValidTelCode'
            const config = {
                url: url,
                params: data,
                method: 'GET'
            }

            axiosOverlay(config, false)
                .then(function () {
                    resolve()
                })
                .catch(function (error) {
                    console.log(error)
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    updateMail: ({commit}, data) => {
        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/register/majMailIndividuByTelCode'
            const config = {
                url: url,
                data: data,
                method: 'PATCH'
            }

            axiosOverlay(config, false)
                .then(function () {
                    resolve()
                })
                .catch(function (error) {
                    console.log(error)
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    createAccount: ({commit}, data) => {
        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/register/createAccount'
            const config = {
                url: url,
                data: data,
                method: 'POST'
            }

            axiosOverlay(config, false)
                .then(function () {
                    resolve()
                })
                .catch(function (error) {
                    console.log(error)
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    validateAccount: ({commit}, data) => {
        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/register/validateAccount'
            const config = {
                url: url,
                data: data,
                method: 'POST'
            }

            axiosOverlay(config, false)
                .then(function () {
                    resolve()
                })
                .catch(function (error) {
                    console.log(error)
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
