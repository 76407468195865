<template>
  <div class="col-12 col-lg-8">
    <form
      id="step3FormRegister"
      @submit.prevent="validateStep()"
    >
      <p class="p-0 m-0">
        {{ $t('registerPage.askForPassword') }}
      </p>

      <div class="position-relative">
        <input 
          id="input-password"
          v-model.trim="password"
          :type="showPassword ? 'text' : 'password'"
          autocomplete="new-password"
          :placeholder="$t('registerPage.password')"
          class="form-control rounded-3 mt-3"
          minlength="8"
          required
        >

        <div
          class="position-absolute end-0 top-0 h-100 d-flex align-items-center pe-2 "
          role="button"
        >
          <font-awesome-icon
            v-if="!showPassword"
            icon="fa-solid fa-eye"
            title="Afficher mot de passe en clair"
            @click="showPassword = !showPassword"
          />

          <font-awesome-icon
            v-else-if="showPassword"
            icon="fa-solid fa-eye-slash"
            title="Cacher mot de passe"
            @click="showPassword = !showPassword"
          />
        </div>
      </div>

      <div class="position-relative">
        <input
          id="input-confirm-password"
          v-model.lazy="confirmPassword"
          :type="showPassword ? 'text' : 'password'"
          :placeholder="$t('registerPage.confirmPassword')"
          autocomplete="new-password"
          class="form-control rounded-3 my-2"
          minlength="8"
          required
        >

        <div
          class="position-absolute end-0 top-0 h-100 d-flex align-items-center pe-2"
          role="button"
        >
          <font-awesome-icon
            v-if="!showPassword"
            icon="fa-solid fa-eye"
            title="Afficher mot de passe en clair"
            @click="showPassword = !showPassword"
          />

          <font-awesome-icon
            v-else-if="showPassword"
            icon="fa-solid fa-eye-slash"
            title="Cacher mot de passe"
            @click="showPassword = !showPassword"
          />
        </div>
      </div>

      <small
        v-if="!validationPassword"
        class="text-danger d-block mb-3"
      >
        {{ $t('registerPage.passwordsMustBeIdentical') }}
      </small>
      
      <button
        type="button"
        class="btn btn-primary rounded-5 mt-3 mb-2"
        role="button"
        @click="seeTos"
      >
        {{ $t('registerPage.TOS') }}
      </button>

      <div class="form-check mt-1 text-center">
        <input
          id="input-TOS"
          type="checkbox"
          class="form-check-input"
          required
        >

        <label
          for="input-TOS"
          class="form-check-label"
        >
          {{ $t('registerPage.acceptTos') }}
        </label>
      </div>

      <button 
        type="submit"
        class="btn btn-amsom-green rounded-5 py-2 mt-3"
      >
        {{ $t('common.nextStep') }}
      </button>
    </form>

    <div
      v-if="error"
      class="text-center text-danger my-2"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "RegisterStep3Block",

  data() {
    return {
      password: null,
      confirmPassword: null,
      showPassword: false,

      error: null
    }
  },

  computed: {
    ...mapState(['registerPage']),

    registerStep: {
      get() {
        return this.registerPage.registerStep
      },

      set(value) {
        this.$store.commit('registerPage/setRegisterStep', value)
      }
    },

    validationPassword() {
      if (!this.confirmPassword) {
        return true
      }
      return this.password === this.confirmPassword
    }
  },

  methods: {
    validateStep() {
      if (this.validationPassword) {
        const self = this

        this.$store.dispatch('registerPage/createAccount', {
          mail: self.registerPage.mail,
          contrat: self.registerPage.contrat,
          password: this.password
        })
          .then(() => {
            self.error = null
            self.$store.commit('registerPage/setPassword', this.password)
            self.registerStep++;
          })
          .catch((error) => {
            console.log(error)
            self.error = error
          })

      }
    },

    seeTos(){
      const url = 'https://client.amsom-habitat.fr/cgu';
      if (window.cordova) {
        cordova.InAppBrowser.open(
          url,
          "_system",
          "location=yes"
        );
      } else {
        window.open(url, "_blank");
      }
    },
  },


}
</script>
