const state = {
    isExtended: false
}

const getters = {
}

const mutations = {
    setIsExtended: (state, isExtended) => {
        state.isExtended = isExtended
    }
}

const actions = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
