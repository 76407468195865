<template>
  <amsom-overlay :loading="registerPage.loading > 0">
    <register-page-browser class="mb-2" />
  </amsom-overlay>
</template>

<script>

import RegisterPageBrowser from "@/views/Browser/Register.vue";
import {mapState} from "vuex";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";

export default {
  name: "RegisterPage",
  components: {RegisterPageBrowser, AmsomOverlay},

  computed: {
    ...mapState(['registerPage']),
  },

  mounted() {
    const self = this;
    this.$store.commit('registerPage/setRegisterStep', 1);

    if (this.$route.query.mail && this.$route.query.contrat)
      this.$store.dispatch('registerPage/checkMailForContrat', {
        contrat: this.$route.query.contrat,
        mail: this.$route.query.mail
      })
        .then(() => {
          /// En cas de succès, invitation pour l'utilisateur à poursuivre son inscription
          this.$store.dispatch('registerPage/getAvailableCommunicationForContrat', self.$route.query.contrat)
          self.$store.commit('registerPage/setMail', self.$route.query.mail)
          this.$store.commit('registerPage/setRegisterStep', 3);
        })
        .catch((error) => {
          /// En cas d'erreur, invitation pour l'utilisateur à se rapprocher du bailleur
          console.log(error);
          this.$store.commit('registerPage/setRegisterStep', 0);
        })
    console.log(this.$route.query.mail);
    console.log(this.$route.query.contrat);
  }
}
</script>
