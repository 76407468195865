<template>
  <form
    @submit.prevent="updateIndividu"
  >
    <div class="w-100 text-center fs-5">
      <template v-if="locataire.idIndividu">
        <span
          v-if="!depart"
          class="text-primary"
        >
          {{ locataire.prenom }} ne fait plus parti du foyer?
          <span
            class="text-decoration-underline fw-bold"
            role="button"
            style="white-space: nowrap;"
            @click="depart = true"
          >
            Déclarez son départ
          </span>
        </span>

        <span
          v-else
          class="text-primary"
        >
          <span
            class="text-decoration-underline fw-bold"
            role="button"
            @click="depart = false"
          >
            Annuler le départ de {{ locataire.prenom }}
          </span>
        </span>
      </template>

      <template v-else-if="locataire.id">
        <span
          role="button"
          class="text-decoration-underline fw-bold"
          @click="deleteIndividu"
        >
          Annuler la création du nouvel occupant
        </span>
      </template>
    </div>

    <div
      class="card card-body card-text position-relative mt-4"
      :class="[{'border border-danger': currentRejets?.length > 0}]"
    >
      <div class="position-absolute start-0 top-0  translate-middle-y text-center w-100">
        <span
          v-if="currentRejets?.length > 0"

          class="fs-7 text-danger bg-danger-light rounded rounded-pill px-2 py-1 border border-1 border-danger"
        >
          {{ currentRejets?.length }} Erreur{{ currentRejets?.length>1?'s':'' }} détectée{{ currentRejets?.length > 1? 's': '' }}
        </span>
      </div>

      <div class="position-absolute w-100 top-0 start-0 text-muted text-primary p-2 d-flex justify-content-between align-items-center">
        <div class="py-1 rounded fs-5 text-primary ">
          <template
            v-if="progression"
          >
            <font-awesome-icon
              :icon="['fas', 'user']"
              class="me-1"
            />
            {{ progression }}
          </template>
        </div>

        <div
          class="text-uppercase py-1"
          :class="[{ 'mt-2': currentRejets?.length > 0 }]"
        >
          <span class="p-2">
            {{ editedLocataire.idIndividu ? '' : 'Nouvel ' }}{{ getTypeLocataire(editedLocataire) }}
          </span>
        </div>
      </div>

      <div
        class="row gy-3 gx-3 mt-1"
      >
        <template v-if="depart">
          <span
            v-if="isOccupant"
            class="mt-2"
          >Confirmation du départ de
            <span class="h5">{{ getNomPrenom(editedLocataire) }}</span>
          </span>

          <locataire-sortie-block
            v-model="editedLocataire"
            :ask-date-sortie="true"
            :need-pj-sortie="isNeededPjSortie(editedLocataire)"
            :ask-motif-depart="!isOccupant"
            @file-loading="updateFileLoading"
          />

          <locataire-contact-block
            v-if="editedLocataire.motifDepart !== 'deces' && !isOccupant"
            v-model="editedLocataire"
            required-mail
            :required-mobile="!editedLocataire.fixe"
          />

          <locataire-adresse-block
            v-if="editedLocataire.motifDepart !== 'deces' && !isOccupant"
            v-model="editedLocataire"
          />
        </template>

        <template v-else>
          <locataire-etat-civil-block
            v-model="editedLocataire"
            :is-conflict-etat-civil="isConflictEtatCivil"
            :show-lieu-naissance="
              !locataire?.lieuNaissance || !locataire.idIndividu
            "
            :need-pj-etat-civil="
              isNeededPjEtatCivil(locataire, editedLocataire)
            "
            @file-loading="updateFileLoading"
          />

          <template v-if="isMajeur(editedLocataire)">
            <locataire-contact-block
              v-model="editedLocataire"
            />

            <locataire-situation-socio-pro-block v-model="editedLocataire" />
          </template>

          <locataire-situation-familiale-block
            v-model="editedLocataire"
            :need-pj-lien-parente="isNeededPjLienParente(editedLocataire)"
            @file-loading="updateFileLoading"
            @conflict-lien-parente-situation="
              updateConflictLienParenteSituation
            "
          />

          <locataire-cmi-block
            v-model="editedLocataire"
            :need-pj-cmi="editedLocataire.carteInvalidite"
            @file-loading="updateFileLoading"
          />

          <template v-if="getTypeLocataire(editedLocataire) === 'occupant'">
            <locataire-responsabilite-block v-model="editedLocataire" />
          </template>
        </template>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6 text-start">
        <button
          v-if="canPrev && locataire.id"
          class="btn btn-primary rounded-5"
          type="button"
          :disabled="fileLoadingCount > 0"
          @click="previousLocataire"
        >
          Précédent
        </button>

        <button
          v-if="!locataire.id"
          class="btn btn-primary rounded-5"
          type="button"
          :disabled="fileLoadingCount > 0"
          @click="cancelCreation"
        >
          Annuler
        </button>
      </div>

      <div class="col-6 text-end">
        <button
          class="btn btn-amsom-green rounded-5"
          type="submit"
          :disabled="fileLoadingCount > 0 || ((isConflictLienParenteSituation || isConflictEtatCivil) && !depart)"
        >
          Valider
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import LocataireContactBlock from "@/components/Feat/IndividuFeeding/LocataireContactBlock.vue";
import LocataireEtatCivilBlock from "@/components/Feat/IndividuFeeding/LocataireEtatCivilBlock.vue";
import LocataireSituationSocioProBlock from "@/components/Feat/IndividuFeeding/LocataireSituationSocioProBlock.vue";
import LocataireSituationFamilialeBlock from "@/components/Feat/IndividuFeeding/LocataireSituationFamilialeBlock.vue";
import LocataireCmiBlock from "@/components/Feat/IndividuFeeding/LocataireCmiBlock.vue";
import LocataireResponsabiliteBlock from "@/components/Feat/IndividuFeeding/LocataireResponsabiliteBlock.vue";
import LocataireSortieBlock from "@/components/Feat/IndividuFeeding/LocataireSortieBlock.vue";
import LocataireAdresseBlock from "@/components/Feat/IndividuFeeding/LocataireAdresseBlock.vue";
import { mapState, mapGetters } from "vuex";
import { toast } from "vue3-toastify";

export default {
  name: "IndividuEnqueteFeeding",

  components: {
    LocataireSortieBlock,
    LocataireContactBlock,
    LocataireEtatCivilBlock,
    LocataireSituationSocioProBlock,
    LocataireSituationFamilialeBlock,
    LocataireCmiBlock,
    LocataireResponsabiliteBlock,
    LocataireAdresseBlock,
  },

  props: {
    locataire: {
      type: Object,
      required: true,
    },

    canPrev: {
      type: Boolean,
      default: true,
    },

    progression: {
      type: String,
      required: false,
      default: null
    }
  },

  emits: ["next", "prev", "cancelCreation"],

  data() {
    return {
      editedLocataire: Object.assign({}, this.locataire),
      depart: this.locataire.dateDepart ? true : false,

      fileLoadingCount: 0,
      isConflictLienParenteSituation: false,
    };
  },

  computed: {
    ...mapState(["enquete"]),

    ...mapGetters({
      isMajeur: "contrat/isMajeur",
      getTypeLocataire: "contrat/getTypeLocataire",
      isNeededPjSortie: "locataireFeeding/isNeededPjSortie",
      isNeededPjEtatCivil: "locataireFeeding/isNeededPjEtatCivil",
      isNeededPjLienParente: "locataireFeeding/isNeededPjLienParente",
      getNomPrenom: "enquete/getNomPrenom",
      getRejetsGrouped: "enquete/getRejetsGrouped",
    }),

    isConflictEtatCivil(){
      //Si on a modifié le nom de naissance ET le prenom ET la date de naissance
      return this.locataire.idIndividu && (this.editedLocataire.nomNaissance !== this.locataire.nomNaissance && this.editedLocataire.prenom !== this.locataire.prenom && parseInt(this.editedLocataire.dateNaissance) !== parseInt(this.locataire.dateNaissance))
    },

    currentRejets() {
      if(!this.getRejetsGrouped || !this.getRejetsGrouped['individu'] || !this.getRejetsGrouped['individu'][this.locataire.id]) return null

      return this.getRejetsGrouped['individu'][this.locataire.id];
    },

    enqueteContrat() {
      return this.enquete.enqueteContrat;
    },

    isOccupant() {
      return this.getTypeLocataire(this.locataire) === "occupant";
    },
  },

  watch: {
    depart: function (val) {
      this.editedLocataire = Object.assign({}, this.locataire);

      if (!val) {
        this.editedLocataire.dateDepart = null;
        this.editedLocataire.motifDepart = null;
        this.editedLocataire.decede = null;

        this.editedLocataire.appt = null;
        this.editedLocataire.numRue = null;
        this.editedLocataire.adr1 = null;
        this.editedLocataire.adr2 = null;
        this.editedLocataire.adr3 = null;
        this.editedLocataire.lieuDit = null;
        this.editedLocataire.commune = null;
        this.editedLocataire.pays = null;
        this.editedLocataire.cp = null;
      }
    },
  },

  mounted() {
    if(!this.locataire.hasOwnProperty('idLocataire') && this.isOccupant){
      this.editedLocataire.aCharge = true
    }
  },

  methods: {
    updateIndividu() {
      if(this.fileLoadingCount > 0) return

      if (this.editedLocataire.decede) {
        let temp = Object.assign({}, this.locataire);
        temp.dateDepart = this.editedLocataire.dateDepart;
        temp.motifDepart = this.editedLocataire.motifDepart;
        temp.decede = this.editedLocataire.decede;
        temp.pjDepart = this.editedLocataire.pjDepart;
        this.editedLocataire = temp;
      }

      let modification = Object.assign({}, this.editedLocataire);

      for (let key in modification) {
        if (this.locataire[key] === modification[key]) {
          delete modification[key];
        }
      }


      this.$store
        .dispatch("enquete/submitIndividu", {
          modification: modification,
          idContrat: this.enqueteContrat.idContrat,
          idEnquete: this.enqueteContrat.idEnquete,
          idLocataire: this.locataire.id,
        })
        .then((response) => {
          if (!this.editedLocataire.id) {
            this.editedLocataire.id = response.data;
          }

          this.$store
            .dispatch("enquete/reLoadEnqueteIndividus", {
              id: this.editedLocataire.id,
              idEnquete: this.enqueteContrat.idEnquete,
            })
            .then(() => {
              this.$emit("next");
            })
            .catch((error) => {
              toast.error(error);
            });
        })
        .catch((error) => {
          toast.error(error);
        });
    },

    deleteIndividu() {
      if(this.fileLoadingCount > 0) return

      this.$store
        .dispatch("enquete/deleteIndividu", {
          idEnqueteIndividu: this.locataire.id,
        })
        .then(() => {
        })
        .catch((error) => {
          toast.error(error);
        });
    },

    cancelCreation(){
      this.$emit("cancelCreation")
    },

    updateFileLoading(value) {
      if (value) this.fileLoadingCount++;
      else this.fileLoadingCount--;
    },

    updateConflictLienParenteSituation(value) {
      this.isConflictLienParenteSituation = value;
    },

    previousLocataire() {
      if(this.fileLoadingCount > 0) return
      this.$emit("prev");
    },
  },
};
</script>
