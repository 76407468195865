import axiosOverlay from "@/services/axiosOverlay";
import apiErrorParser from "@/js/apiErrorParser";
import axios from "axios";
import {sortArray} from "@/js/array.js";

const state = {
    contratTravail: null,
    situationFamiliale: null,
    lienParente: null,
    situationSociopro: null,
    assureurs: [],
    nomenclature: [],
    pays: [],
    banques: null,
    associationUnionLienSituation: {
        "C": "M,V",
        "M": "M",
        "Z": "U",
        "Y": "U",
        "W": "P",
        "V": "P"
    },

    loading: 0,
    versionInfos: null
}

const getters = {
    getContratTravailById: (state) => (id) => {
        if (!state.contratTravail)
            return null

        return state.contratTravail.find((contrat) => contrat.id === id)
    },
    getSituationFamilialeById: (state) => (id) => {
        if (!state.situationFamiliale)
            return null

        return state.situationFamiliale.find((situation) => situation.id === id)
    },
    getSituationSocioproById: (state) => (id) => {
        if (!state.situationSociopro)
            return null

        return state.situationSociopro.find((situation) => situation.id === id)
    },
    getLienParenteById: (state) => (id) => {
        if (!state.lienParente)
            return null

        return state.lienParente.find((lien) => lien.id === id)
    },
    getBanqueById: (state) => (id) => {
        if (!state.banques)
            return null

        return state.banques.find((banque) => banque.id === id)
    },
    getPaysById: (state) => (id) => {
        if (!state.pays)
            return null

        return state.pays.find((pays) => pays.id === id)
    },
}

const mutations = {
    setContratTravail: (state, contratTravail) => {
        state.contratTravail = sortArray(contratTravail, 'libelle', true)
    },
    setSituationSociopro: (state, situationSociopro) => {
        state.situationSociopro = sortArray(situationSociopro, 'libelle', true)
    },
    setSituationFamiliale: (state, situationFamiliale) => {
        state.situationFamiliale = sortArray(situationFamiliale, 'libelle', true)
    },
    setLienParente: (state, lienParente) => {
        state.lienParente = sortArray(lienParente, 'libelle', true)
    },
    setAssureurs: (state, assureurs) => {
        state.assureurs = sortArray(assureurs, 'nom', true)
    },
    setBanques: (state, banques) => {
        state.banques = sortArray(banques, 'libelle', true)
    },
    setNomenclature: (state, nomenclature) => {
        state.nomenclature = nomenclature
    },
    setPays: (state, data) => {
        state.pays = data.map((pays) => {
            return {
                id: pays.fields.iso3,
                nom: pays.fields.name_fr,
            }
        })

        state.pays = sortArray(state.pays, 'nom', true)
    },
    setVersionInfos: (state, infos)=>{
        state.versionInfos = infos
    },
    setLoading: (state, val) => {
        if (val === '+')
            state.loading++

        if (val === '-')
            state.loading--
    },
}

const actions = {
    getContratTravail: ({commit, state}) => {
        if (!state.contratTravail) {
            commit('setLoading', '+');
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_URL + '/contrat_travails'
                const config = {
                    url: url,
                    method: 'GET'
                }

                axiosOverlay(config, false)
                    .then(function (response) {
                        commit('setContratTravail', response.data)
                        resolve()
                    })
                    .catch(function (error) {
                        console.log(apiErrorParser(error))
                        reject(apiErrorParser(error))
                    })
                    .finally(() => {
                        commit('setLoading', '-');
                    })
            })
        }
    },
    getSituationSociopro: ({commit, state}) => {
        if (!state.situationSociopro) {
            commit('setLoading', '+');
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_URL + '/situation_sociopros'
                const config = {
                    url: url,
                    method: 'GET'
                }

                axiosOverlay(config, false)
                    .then(function (response) {
                        commit('setSituationSociopro', response.data)
                        resolve()
                    })
                    .catch(function (error) {
                        console.log(apiErrorParser(error))
                        reject(apiErrorParser(error))
                    })
                    .finally(() => {
                        commit('setLoading', '-');
                    })
            })
        }
    },
    getSituationFamiliale: ({commit, state}) => {
        if (!state.contratTravail) {
            commit('setLoading', '+');
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_URL + '/situation_familiales'
                const config = {
                    url: url,
                    method: 'GET'
                }

                axiosOverlay(config, false)
                    .then(function (response) {
                        commit('setSituationFamiliale', response.data)
                        resolve()
                    })
                    .catch(function (error) {
                        console.log(apiErrorParser(error))
                        reject(apiErrorParser(error))
                    })
                    .finally(() => {
                        commit('setLoading', '-');
                    })
            })
        }
    },
    getLienParente: ({commit, state}) => {
        if (!state.lienParente) {
            commit('setLoading', '+');
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_URL + '/lien_parentes'
                const config = {
                    url: url,
                    method: 'GET'
                }

                axiosOverlay(config, false)
                    .then(function (response) {
                        commit('setLienParente', response.data)
                        resolve()
                    })
                    .catch(function (error) {
                        console.log(apiErrorParser(error))
                        reject(apiErrorParser(error))
                    })
                    .finally(() => {
                        commit('setLoading', '-');
                    })
            })
        }
    },
    getAssureurs: ({commit, state}) => {
        if (state.assureurs.length === 0) {
            commit('setLoading', '+');
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_URL + '/assureurs'
                const config = {
                    url: url,
                    method: 'GET'
                }

                axiosOverlay(config, false)
                    .then(function (response) {
                        commit('setAssureurs', response.data)
                        resolve()
                    })
                    .catch(function (error) {
                        console.log(apiErrorParser(error))
                        reject(apiErrorParser(error))
                    })
                    .finally(() => {
                        commit('setLoading', '-');
                    })
            })
        }
    },
    loadNomenclature: ({commit, state}) => {
        // Si la nomenclature est déjà chargée, on ne fait rien
        if (state.nomenclature.length > 0)
            return Promise.resolve()
        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/sollicitation_nomenclatures'
            const config = {
                url: url,
                method: 'GET'
            }

            axiosOverlay(config, false)
                .then(function (response) {
                    commit('setNomenclature', response.data)
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    loadBanques: ({commit, state}) => {
        // Si la nomenclature est déjà chargée, on ne fait rien
        if (state.banques?.length > 0)
            return Promise.resolve()

        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/banques'
            const config = {
                url: url,
                method: 'GET'
            }

            axiosOverlay(config, false)
                .then(function (response) {
                    commit('setBanques', response.data)
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    loadVersionInfos: ({commit}) => {
        commit('setLoading', '+');
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/espace_client_version'
            const config = {
                url: url,
                method: 'GET'
            }

            axiosOverlay(config, false)
                .then(function (response) {
                    commit('setVersionInfos', response.data)
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    },
    loadPays: ({commit, state}) => {
        // Si la nomenclature est déjà chargée, on ne fait rien
        if (state.pays.length > 0)
            return Promise.resolve()

        return new Promise((resolve, reject) => {
            let url = 'https://data.enseignementsup-recherche.gouv.fr/api/records/1.0/search/?dataset=curiexplore-pays&q=&rows=500'
            const config = {
                url: url,
                method: 'GET'
            }

            axios(config)
                .then(function (response) {
                    commit('setPays', response.data.records)
                    resolve()
                })
                .catch(function (error) {
                    console.log(error)
                    console.log(apiErrorParser(error))
                    reject(apiErrorParser(error))
                })
                .finally(() => {
                    commit('setLoading', '-');
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
