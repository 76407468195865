import apiErrorParser from "@/js/apiErrorParser"
import axiosOverlay from "@/services/axiosOverlay"
import moment from "moment-timezone"

const getDefaultState = () => {
    return {
        loading: 0,
        assurance: undefined,
    }
}

const state = getDefaultState()

const getters = {}
const mutations = {
    setLoading: (state, val) => {
        if (val === '+')
            state.loading++

        if (val === '-')
            state.loading--
    },
    setAssurance: (state, assurance) => {
        if(!assurance){
            state.assurance = assurance
            return
        }


        if (assurance.dateFin > moment().unix())
            assurance.valide = true
        else
            assurance.valide = false

        state.assurance = assurance
    },
    resetState: (state) => {
        Object.assign(state, getDefaultState())
    }
}
const actions = {
    getAssuranceByContratId: ({state, commit}, payload) => {
        // si l'assurance du contrat contratId est déjà chargée, on ne recharge pas
        if (!payload?.force && state.assurance?.id === payload.idContrat) return Promise.resolve()

        // sinon on charge l'assurance du contrat contratId
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/assurance_contrats?id=' + payload.idContrat
            const config = {
                url: url,
                method: 'GET'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    commit('setAssurance', response.data[0] ?? null);

                    commit('setLoading', '-');
                    resolve()
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    commit('setLoading', '-');
                    reject(apiErrorParser(error))
                })
        })
    },
    updateAssurance: ({state, commit, dispatch}, data) => {
        return new Promise((resolve, reject) => {
            let url = process.env.VUE_APP_API_URL + '/assuranceContrat/updateAssurance'
            const config = {
                url: url,
                data: data,
                method: 'POST'
            }

            commit('setLoading', '+');
            axiosOverlay(config)
                .then(function (response) {
                    dispatch('getAssuranceByContratId', {idContrat: data.idContrat, force: true})
                        .then(() => {
                            commit('setLoading', '-');
                            resolve()
                        });
                })
                .catch(function (error) {
                    console.log(apiErrorParser(error))
                    commit('setLoading', '-');
                    reject(apiErrorParser(error))
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
