<template>
  <div>
    <div class="position-relative">
      <div
        v-if="loading"
        class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center z-index-50 rounded"
        style="
          background-color: rgba(193, 193, 193, 0.592);
          box-shadow: inset 0 0 0 10px rgba(193, 193, 193, 0.5);
        "
      >
        <div
          class="spinner-border text-primary position-sticky sticky-top"
          role="status"
        >
          <span class="sr-only">Chargement...</span>
        </div>
      </div>

      <div>
        <slot
          name="default"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AmsomOverlay",

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
