// import normalize from "./text";

import moment from "moment-timezone";

// sort array of object by key
export function sortArray(array, key, asc = false, nullBigest = false) {
    if(!array)
        return array
    return (array.sort((a, b) => {

        //regex versioning
        const regex = new RegExp("(0|[1-9]\\d*)\\.(0|[1-9]\\d*)\\.(0|[1-9]\\d*)?$");

        if (regex.test(a[key]) && regex.test(b[key])) {
            let temp = a[key].replace(/\d+/g, n => +n + 100000)
                .localeCompare(b[key].replace(/\d+/g, n => +n + 100000))
            switch (temp) {
                case 1 :
                    return (asc ? 1 : -1)
                case -1 :
                    return (asc ? -1 : 1)
                case 0 :
                    return 0
                default :
                    return 0
            }
        }

        //Verification de si on a à faire à du numérique
        if (!isNaN(a[key]) && !isNaN(b[key])) {
            return asc ? parseFloat(a[key]) - parseFloat(b[key]) : parseFloat(b[key]) - parseFloat(a[key])
        }

        //verification de si un des champs est null
        if (!a[key] && b[key])
            return (asc ? (nullBigest ? 1 : -1) : (nullBigest ? -1 : 1))

        if (a[key] && !b[key])
            return (asc ? (nullBigest ? -1 : 1) : (nullBigest ? 1 : -1))

        if (!a[key] && !b[key]) {
            return 0
        }

        //Verification de si on a à faire à de la date
        if (moment(a[key], 'DD/MM/YYYY').isValid() || moment(b[key], 'DD/MM/YYYY').isValid()) {
            return asc ? moment(a[key], 'DD/MM/YYYY').unix() - moment(b[key], 'DD/MM/YYYY').unix() : moment(b[key], 'DD/MM/YYYY').unix() - moment(a[key], 'DD/MM/YYYY').unix()
        }

        //On vérifie qu'on compare du string et que l'on peut bien normaliser le champs, sinon on le laisse tel quel
        let current = (typeof a[key] === 'string' || typeof b[key] === 'string') && a[key] ? a[key].toString().toLowerCase() : a[key]
        let compare = (typeof a[key] === 'string' || typeof b[key] === 'string') && b[key] ? b[key].toString().toLowerCase() : b[key]

        return (current > compare) ? (asc ? 1 : -1) : (compare > current) ? (asc ? -1 : 1) : 0
    }))
}

