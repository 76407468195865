<template>
  <div
    v-if="currentLocataire"
  >
    <individu-enquete-feeding
      :key="currentLocataire.type + '-' + keyIndividu"
      :locataire="currentLocataire"
      :can-prev="!!locataireList[currentLocataireIndex - 1]"
      :progression="progression"
      @next="currentLocataireIndex++"
      @prev="currentLocataireIndex--"
      @cancel-creation="cancelCreation"
    />
  </div>

  <div v-else-if="enquete.enqueteIndividus">
    <div class="card">
      <div class="card-body card-text">
        <h3 class="text-center text-primary">
          Voulez-vous ajouter un autre occupant?
        </h3>

        <div class="row justify-content-arround">
          <div class="col text-end">
            <button
              type="button"
              class="btn btn-amsom-green"
              @click="addIndividu"
            >
              Oui
            </button>
          </div>

          <div class="col text-start">
            <button 
              type="button"
              class="btn btn-secondary"
              @click="next"
            >
              Non
            </button>
          </div>
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-primary mt-3 rounded-5"
      @click="currentLocataireIndex--"
    >
      Précédent
    </button>
  </div>
</template>

<script>
import IndividuEnqueteFeeding from "@/components/EnqueteEntryPage/IndividuEnqueteFeeding.vue";
import pageUtils from "@/js/pageUtils";
import { mapState } from "vuex";

export default {
  name: "IndividuEnqueteBlock",

  components: {
    IndividuEnqueteFeeding,
  },

  props: {
    locataireList: {
      type: Array,
      required: true,
    },
  },

  emits: ["next"],

  data() {
    return {
      currentLocataireIndex: 0,
    };
  },

  computed: {
    ...mapState(["enquete"]),

    currentLocataire() {
      return this.locataireList[this.currentLocataireIndex];
    },

    progression() {
      if(this.locataireList?.length > 1)
        return (this.currentLocataireIndex +1) + '/' + this.locataireList?.length

      return null
    },

    keyIndividu() {
      let catIndividus = this.enquete.enqueteIndividus.filter((enqueteIndividu) => this.currentLocataire.type === enqueteIndividu.type)

      if(!this.currentLocataire.id)
        return catIndividus.length-1

      let index = catIndividus.findIndex((catIndividus) => catIndividus.id === this.currentLocataire.id)

      return index === -1 ? catIndividus.length-1 : index
    },
  },

  watch: {
    currentLocataireIndex() {
      pageUtils.autoScroll();
    },
  },

  methods: {
    cancelCreation() {
      this.$store.commit("enquete/removeLastEnqueteIndividu");
    },

    addIndividu() {
      this.$store.commit("enquete/addEnqueteIndividus", {type: "occupant"});
    },

    selectIndividu(individu) {
      this.currentLocataireIndex = this.locataireList.findIndex((locataire) => locataire.id === individu.id)
    },

    next() {
      this.$emit("next");
    }
  },
};
</script>
