<template>
  <widget-block :bg-image="bgImage">
    <template
      v-if="!loading"
      #title
    >
      <template v-if="rib">
        <h5>{{ $t('ribWidget.manage.cardHeader') }}</h5>
      </template>

      <template v-else>
        <h5>{{ $t('ribWidget.subscribe.cardHeader') }}</h5>
      </template>
    </template>

    <template
      #content
    >
      <template 
        v-if="loading"
      >
        <amsom-skeleton :config="[8, 6, 8]" />
      </template>

      <template v-else-if="rib">
        <div>
          <span class="text-primary">{{ $t('ribWidget.manage.directDebitDateLabel') }}</span>

          <strong
            class="ms-3 text-secondary fw-bold fs-5"
          >{{ rib.jourPrelevement }}</strong>
        </div>

        <div>
          <span
            v-if="rib.libelleBanque"
            class="text-primary"
          >{{ $t('ribWidget.manage.banqueNameLabel') }}</span>

          <strong
            class="ms-3 text-secondary fs-6"
          >{{ rib.libelleBanque }}</strong>
        </div>
      </template>

      <template v-else>
        <small
          id="subscribe-prelev-description"
          class="card-text text-primary"
        >
          {{ $t('ribWidget.subscribe.cardDescription') }}
        </small>
      </template>
    </template>


    <template
      v-if="!loading"
      #btn-action
    >
      <template v-if="rib">
        <button
          id="manage-prelev-btn"
          class="btn btn-amsom-green rounded-5 mt-3 mb-2 me-1"
          @click="goToPrelevementPage"
        >
          {{ $t('ribWidget.manage.cardBtn') }}          
          <font-awesome-icon
            icon="fa-solid fa-arrow-right"
            class="ms-1"
          />
        </button>
      </template>

      <template v-else>
        <button
          id="subscribe-prelev-btn"
          class="btn btn-amsom-green rounded-5 mt-3 mb-2 me-1"
          @click="goToPrelevementPage"
        >
          {{ $t('ribWidget.subscribe.cardBtn') }}
          <font-awesome-icon
            icon="fa-solid fa-arrow-right"
            class="ms-2"
          />
        </button>
      </template>
    </template>
  </widget-block>
</template>

<script>
import {mapState} from "vuex";
import {unixToDateString} from "../../js/text.js";
import WidgetBlock from "../WidgetBlock.vue";
import bgImage from '@/assets/images/homePage/rib-widget.jpeg';
import AmsomSkeleton from "../UI/AmsomSkeleton.vue";

export default {
  name: 'RibWidget',

  components: {
    WidgetBlock,
    AmsomSkeleton
  },

  data() {
    return {
      bgImage: bgImage,
    }
  },

  methods: {
    unixToDateString,

    goToPrelevementPage() {
      this.$router.push({name: 'prelevement'})
    }
  },

  computed: {
    ...mapState(['contrat']),

    ibanSplitted() {
      return this.rib.iban.split(" - ");
    },

    rib() {
      if(!this.contrat.prelevementInfos)
        return null
      return this.contrat.prelevementInfos[0]
    },

    loading() {
      return this.contrat.loading > 0 || this.rib === null
    }
  }
}
</script>
