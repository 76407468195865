<template>
  <div
    class="amsom-modal-block bg-black bg-opacity-25 position-fixed top-50 start-50 translate-middle min-vw-100 min-vh-100 d-flex justify-content-center align-items-center"
    style="margin: 0!important;"
    @click.self="closeOption? $emit('close'):''"
  >
    <div class="container bg-body rounded-4 p-3 m-3 position-relative">
      <font-awesome-icon
        v-if="closeOption"
        icon="fa-solid fa-times"
        role="button"
        class="position-absolute top-0 end-0 m-3 translate-middle-y"
        size="lg"
        @click="$emit('close')"
      />

      <div class="amsom-modal-body">
        <div class="mt-2" />

        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AmsomModal",

  props: {
    closeOption: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["close"],
};
</script>

<style>
.amsom-modal-body {
  max-height: 80vh;
  overflow-y: auto;
}

.amsom-modal-block {
  z-index: 1000;
  overflow-y: auto;
}
</style>